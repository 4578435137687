import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getNotifications, getNotificationsCount } from './api';


export const getNotificationsWeb = createAsyncThunk('notiiiii', async (data, { rejectWithValue }) => {
    try {
      const response = await getNotifications(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });


  export const getNotiCounts = createAsyncThunk('noti count', async (data, { rejectWithValue }) => {
    try {
      const response = await getNotificationsCount(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });



const NotificationSlice = createSlice({
    name: 'notifi',
    initialState: {
      data: null,
      loading: false,
      error: null,
      count : 0
     
    },
    reducers: {},
    extraReducers: builder => {
      builder
      .addCase(getNotificationsWeb.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNotificationsWeb.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getNotificationsWeb.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getNotiCounts.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNotiCounts.fulfilled, (state, action) => {
        state.count = action.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(getNotiCounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
    
    },
  });

export default NotificationSlice.reducer;