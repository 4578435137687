import React, { useContext, useState } from "react";
import { Formik } from "formik";
import { Button } from "antd";
import { Input } from "formik-antd";
import MultiStepFormContext from "./MultiStepFormContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import {
  useGoogleLogin,
  GoogleOAuthProvider,
  GoogleLogin,
} from "@react-oauth/google";

const Details = () => {
  const { details, setDetails, next } = useContext(MultiStepFormContext);

  const [file, setFile] = useState(null);
  const [fileData, setData] = useState(null);
  const [code, setCountryCode] = useState("1");

  function handleChange(e) {
    console.log(e.target.files);
    setData(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  function calculateAge(dateOfBirth) {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    const age = today.getFullYear() - birthDate.getFullYear();

    // Adjust age if the birthday hasn't occurred yet this year
    if (
      today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() &&
        today.getDate() < birthDate.getDate())
    ) {
      return age - 1;
    }

    return age;
  }

  const handlePhoneChange = (value, country) => {
    setCountryCode(country.dialCode);
    console.log("country", country.dialCode);
    console.log("value", value);
  };

  const handleLogin = (setFieldValue) => {
    try {
      window.FB.login(
        function (response) {
          if (response.authResponse) {
            getFbUserDetails(response.authResponse, setFieldValue);
          }
        },
        {
          scope: "email,public_profile",
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getFbUserDetails = async (response, setFieldValue) => {
    try {
      console.log("response", response);

      let urlGraphFacebook = `https://graph.facebook.com/v20.0/${response.userID}?fields=id,name,email&access_token=${response.accessToken}`;

      let result = await axios.get(urlGraphFacebook);
      // console.log('resulttttt', result);
      if (result.data) {
        const [first_name, last_name] = result.data.name.split(" ");

        // Set values in the form using setFieldValue
        setFieldValue("first_name", first_name);
        setFieldValue("last_name", last_name || ""); // Handle case where last name might be empty
        setFieldValue("email", result.data.email);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoginSuccess = (credentialResponse, setFieldValue) => {
    // console.log('credentialResponse', credentialResponse, setFieldValue);
    const token = credentialResponse.credential;
    const userInfo = jwtDecode(token);

    console.log("User Info:", userInfo);
    setFieldValue("first_name", userInfo.given_name);
    setFieldValue("last_name", userInfo.family_name);
    setFieldValue("email", userInfo.email);
  };

  return (
    <Formik
      initialValues={details}
      onSubmit={(values) => {
        values.image = fileData;
        values.country_code = code;
        console.log("values", values);
        setDetails(values);
        next();
      }}
      validate={(values) => {
        const errors = {};
        const age = calculateAge(values.age);
        if (age < 18) {
          errors.age = "You must be at least 18 years old.";
        }

        if (!values.first_name) errors.first_name = "First name is required";
        if (!values.last_name) errors.last_name = "Last name is required";
        if (!values.age) errors.age = "Age is required";
        if (!values.phone) errors.phone = "Phone is required";
        if (!values.email) errors.email = "Email is required";
        if (values.age > 90) errors.age = "Are you sure you're human?";
        return errors;
      }}
    >
      {({ handleSubmit, setFieldValue, errors }) => {
        return (
          <div class="row">
            <form>
              <div class="form-bg">
                <div class="imagefile">
                  <span>
                    <i class="bi bi-pencil-fill"></i>
                    <input type="file" onChange={handleChange} />
                  </span>
                  {file ? (
                    <img class="w-100" id="blah" src={file} alt="img" />
                  ) : (
                    <img
                      class="w-100"
                      id="blah"
                      src="images/placeholder.png"
                      alt="your image"
                    />
                  )}
                </div>

                <h2>Personal Details</h2>
                <label for="Service" class="form-label">
                  First Name
                </label>
                <Input
                  name={"first_name"}
                  className="service-input"
                  type="text"
                  placeholder="Enter First name"
                />
                <p className={"error__feedback"}>{errors.first_name}</p>
                <label for="Service" class="form-label">
                  Last Name
                </label>
                <Input
                  name={"last_name"}
                  className="service-input"
                  type="text"
                  placeholder="Enter Last name"
                />
                <p className={"error__feedback"}>{errors.last_name}</p>
                <label for="Service" class="form-label">
                  Phone Number
                </label>

                <div className="flag_bar">
                  <Input
                    name={"phone"}
                    min={0}
                    className="service-input"
                    type="tel"
                    placeholder="Enter phone number"
                  />
                  <div className="boxx">
                    <PhoneInput
                      inputClass="form-control"
                      country={"ca"}
                      onlyCountries={["in", "ca"]}
                      onChange={handlePhoneChange}
                      value={code}
                    />
                  </div>
                </div>

                <p className={"error__feedback"}>{errors.phone}</p>
                <label for="Service" class="form-label">
                  Email Id
                </label>
                <Input
                  name={"email"}
                  className="service-input"
                  type="email"
                  placeholder="Enter email id"
                />
                <p className={"error__feedback"}>{errors.email}</p>
                <label for="Service" class="form-label">
                  Age
                </label>
                <Input
                  name={"age"}
                  className="service-input"
                  type="date"
                  max="9999-12-31"
                />
                <p className={"error__feedback"}>{errors.age}</p>
                <div class="text-center">
                  <p
                    class="pop_gill"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Broomy selects a single service for you to complete during sign up. Once approved, you can deactivate any services you no longer wish to offer or add additional services at any time.,
                    Once you’ve completed the required signup steps, your profile will be auto submitted and reviewed to ensure accuracy and quality. You will receive an email from us within 24-48 hours that you’ve been approved or that you need to complete additional steps and re-submit in order to be approved.,
                   For faster approval, make sure you upload high quality photos, write a descriptive description of yourself and your services, and be sure to proofread everything!"
                  >
                    How does approval work?
                  </p>

                  <Button type={"primary"} onClick={handleSubmit}>
                    Next
                  </Button>
                </div>
                <div class="continue">
              <a
                class="btn social_btn"
                onClick={() => handleLogin(setFieldValue)}
              >
                {" "}
                <span>
                  {" "}
                  <img src="images/facebook-2.png" alt="" />{" "}
                </span>
                <span>Continue with Facebook</span>{" "}
              </a>

              <div class="continue sign_up_box sign_up_box1">

              <a className='google_btn google_btn1'>
              <span><img src="images/google.png" alt="" />Sign up with Google</span>

              <GoogleOAuthProvider clientId="369007482216-e3eobqko03ufpi8nklj5c38uvdge8ird.apps.googleusercontent.com">
                <GoogleLogin
                  className="btn social_btn"
                  onSuccess={(credentialResponse) => handleLoginSuccess(credentialResponse, setFieldValue)} // Pass setFieldValue here
                  onError={() => console.log("Login Failed")}
                />
              </GoogleOAuthProvider>

              </a>
              </div>

              {/* <a class="btn social_btn">
                {" "}
                <span>
                  {" "}
                  <img src="images/google.png" alt="" />{" "}
                </span>
                <span>Sign in with google</span>{" "}
              </a> */}
            </div>
              </div>
            </form>

           
          </div>
        );
      }}
    </Formik>
  );
};
export default Details;
