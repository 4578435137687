import React, { useState } from "react";
import { Steps } from "antd";
import { Provider } from "./MultiStepFormContext";
import Details from "./Details";
import Address from "./Address";
import Review from "./Review";
import "./style.css";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { vendorRegisterForm } from "../../../Reducer/authSlice";
import { useNavigate } from "react-router-dom";
import { clearStatus } from "../../../Reducer/authSlice";
import Loader from "../Loader";
import { clearAllData } from "../../../Reducer/providerForms";
const { Step } = Steps;

const detailsInitialState = {
  first_name: "",
  last_name: "",
  age: "",
  phone: "",
  email: "",
  // profession: ""
};

const addressInitialState = {
  address1: "",
  address2: "",
  state: "",
  city: "",
  country: "",
  postal_code: "",
};

const cleaniningState = {
  price: "",
  rates: "",
  optradio1: "",
  optradio2: "",
  optradio3: "",
  optradio4: "",
  optradio5: ""
};
const testiEmails = {
  firstEmail: "",
  secondEmail: "",
  thiredEmail: "",
}
const experiences = {
  experience: "",
  headline: "",
  cleaning_experience: "",
  schedule: "",
  environment: "",
}
const files = [];

const final_detail = {
  card_name: "",
  card_number: "",
  expiery: "",
  cvc: "",
  country: "",
  address1: "",
  address2: "",
  state: "",
  pincode: ""
}



const renderStep = (step) => {
  switch (step) {
    case 0:
      return <Details />;
    case 1:
      return <Address />;
    case 2:
      return <Review />;
    default:
      return null;
  }
};

const Providers = () => {
  const [details, setDetails] = useState(detailsInitialState);
  const [address, setAddress] = useState(addressInitialState);
  const [clean, setCleaning] = useState(cleaniningState);
  const [emails, setEmails] = useState(testiEmails);
  const [experience, setExperience] = useState(experiences);
  const [gallery, setFiles] = useState([]);
  const [final, setFinal] = useState(final_detail);
  const login = useSelector(state => state.auth.auth);
  const error = useSelector(state => state.auth.error);
  const success = useSelector(state => state.auth.success);
  const loading = useSelector(state => state.auth.submitting);
  const navigate =useNavigate();
  const dispatch = useDispatch();
  // if(success && login!={})
  // {
  //   Swal.fire({  
  //       title: 'Congratulation!',  
  //       text: 'Your account created successfully',
  //       icon: 'success'
  //   }); 
  //   dispatch(clearStatus());
  //   setTimeout(() => {
  //     // dispatch(clearStatus());
  //       navigate('/Login');
  //   }, 2000);
  // }

  if(error && error.status)
  {
    switch (error.status) {
        case 403:
            Swal.fire({
                title: 'Error!',
                text: error.data.message,
                icon: 'error'
            });

            dispatch(clearStatus());
       
        break;
        default:
            Swal.fire({
                title: 'Error!',
                text:'Something went wrong',
                icon: 'error'
            });

            dispatch(clearStatus());
       
        break;
    }

   
  }

  let stepPoint = 0;
  if (renderStep) {
    let usr = localStorage.getItem('uid');
    if (usr && usr !== null) {
      stepPoint = 2;
    }
  }
  const [currentStep, setCurrentStep] = useState(stepPoint);

  // const { data, loading, error } = useSelector((state) => state.form);

  const skip = async () => {
    if (currentStep === 2 && validate() == true) {
      console.log('details', details);
      console.log('address', address);
      console.log('clean', clean);
      console.log('emails', emails);
      console.log('experience', experience);
      console.log('gallery', gallery);
      console.log('final', final);

      let formData = new FormData();
      formData.append('image', details.image);
      formData.append('first_name', details.first_name);
      formData.append('last_name', details.last_name);
      formData.append('age', details.age);
      formData.append('number', details.phone);
      formData.append('email', details.email);
      formData.append('country_code', details.country_code);
      formData.append('address1', address.address1);
      formData.append('latitude', address.lat);
      formData.append('longitude', address.long);
      formData.append('address2', address.address2);
      formData.append('city', address.city);
      formData.append('country', address.country);
      formData.append('postal_code', address.postal_code);
      formData.append('state', address.state);
      formData.append('state', address.state);
      formData.append('testiEmails', emails)

      // formData.append('firstEmail', emails.firstEmail);
      // formData.append('secondEmail', emails.secondEmail);
      // formData.append('thiredEmail', emails.thiredEmail);
      formData.append('cleaning_experience', experience.cleaning_experience);
      formData.append('safety', experience.environment);
      formData.append('experience', experience.experience);
      formData.append('headline', experience.headline);
      formData.append('schedule', experience.schedule);
      formData.append('availability', clean.availability);
      formData.append('rates', JSON.stringify(clean.rates));
      formData.append('optradio1',clean.optradio1);
      formData.append('optradio2',clean.optradio2);
      formData.append('optradio3',clean.optradio3);
      formData.append('optradio4',clean.optradio4);
      formData.append('optradio5',clean.optradio5);
      formData.append('price',clean.price);
      formData.append('verification_report',final.background_verification);
      formData.append('finalAddress1',final.address1);
      formData.append('finalAddress2',final.address2);
      formData.append('card_name',final.card_name);
      formData.append('card_number',final.card_number);
      formData.append('finalcountry',final.country);
      formData.append('cvc',final.cvc);
      formData.append('expiery',final.expiery);
      formData.append('finalpincode',final.pincode);
      formData.append('finalstate',final.state);

      if(gallery.length > 0){
        for(let i =0; i < gallery.length; i++){
          formData.append('images[]', gallery[i] );
        }
      }
      try {
        let res = await dispatch(vendorRegisterForm(formData));

        console.log('response signup', res);
        if (res.payload.data.status == "True") {
          Swal.fire({
              title: 'Congratulation!',
              text: res.payload.data.message,
              icon: 'success'
          });
          dispatch(clearStatus());
          dispatch(clearAllData());
               setTimeout(() => {

          navigate('/Login');
      }, 2000);
      }else
      {
        Swal.fire({
          title: 'Error!',
          text: res.payload.data.message,
          icon: 'error'
      });
      }
  
      } catch (e) {
        Swal.fire({
          title: 'Error!',
          text: error.message,
          icon: 'error'
        });
      }
      dispatch(clearStatus());
      return;
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Please select setps and fill one by one form',
        icon: 'error'
      });
      dispatch(clearStatus());
    }

 
    //setCurrentStep(currentStep + 1);
  };

  const validate = () => {
    let xyz = false;
    xyz = Object.values(details).some(v => v);
    xyz = Object.values(address).some(v => v);
    xyz = Object.values(clean).some(v => v);
    xyz = Object.values(emails).some(v => v);
    xyz = Object.values(experience).some(v => v);
    xyz = Object.values(final).some(v => v);
    xyz = gallery.length > 0 ? true : false;
    return xyz;
  }
  const next = async () => {

    // switch (currentStep) {

    //   case 0:
    //   setCurrentStep(currentStep + 1);
    //   break;

    //   case 1:
    //     setCurrentStep(currentStep + 1);
    //   break;

    //   case 2 && validate()==true:
    //   skip();
    //   break;

    //   case 2 :
    //   Swal.fire({  
    //     title: 'Error!',  
    //     text: 'Please select setps and fill one by one form',
    //     icon: 'error'
    //   });
    //   break;
    // }
    if (currentStep === 2) {
      console.log('clean', clean);
      let respo = await skip();
    } else if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    }

  };

  const prev = () => setCurrentStep(currentStep - 1);
  return (
    <div className="container">
      <Provider value={{ details, setDetails, next, prev, address, emails, final, setFinal, setEmails, setAddress, experience, setExperience, clean, gallery, setFiles, setCleaning }}>
        <Steps current={currentStep}>
          <Step title={"Fill in your details"} />
          <Step title={"Address details"} />
          <Step title={"Services"} />
        </Steps>
        <main>{renderStep(currentStep)}</main>
      </Provider>

      <Loader status={loading}></Loader>
    </div>
  );
};
export default Providers;
