import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCleanerForBoooking, sendBookingRequest, sendCheckApi} from './api';


export const CleanerDetail = createAsyncThunk('CleanerDetails', async (data, { rejectWithValue }) => {
    try {
      const response = await getCleanerForBoooking(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });
export const BookingRequest = createAsyncThunk('BookingRequest', async (data, { rejectWithValue }) => {
    try {
      const response = await sendBookingRequest(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  
  export const testPayment = createAsyncThunk('BookingRequest', async (data, { rejectWithValue }) => {
    try {
      const response = await sendCheckApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });



const bookingSlice = createSlice({
    name: 'booking',
    initialState: {
      data: null,
      loading: false,
      error: null,
      commission: null
    },
    reducers: {},
    extraReducers: builder => {
      builder
      .addCase(CleanerDetail.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(CleanerDetail.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.commission = action.payload.commission;
        state.loading = false;
        state.error = null;
      })
      .addCase(CleanerDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(BookingRequest.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(BookingRequest.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(BookingRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // .addCase(testPayment.pending, state => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(testPayment.fulfilled, (state, action) => {
      //   state.payment = action.payload.data;
      //   state.loading = false;
      //   state.error = null;
      // })
      // .addCase(testPayment.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // });
      
      
    },
  });

export default bookingSlice.reducer;