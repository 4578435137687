import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-google-places-autocomplete';
import { ResidentialCat } from "../../Data/content";
import { RentalCat } from "../../Data/content";
import img from "../../Data/home-banner.png"; 
import { saveSearchData } from "../../../../Reducer/searchSlice";

export default function SearchForm() {
    const today = new Date().toISOString().slice(0, 10);
    // const [today, setToday] = useState("");    
    const [search, setSearched] = useState({
        name: "",
        type: "",
        date: "",
        place : "",
        latitude: localStorage.getItem('latitude'),
        longitude: localStorage.getItem('longitude')

    });
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const dispatch = useDispatch();
    const [types, setTypes] = useState([]);
    const navigate = useNavigate();
    const [loc, setLoc] = useState(null);

    async function addType(e) {

        if (e.target.checked === true) {
            setSearched({
                ...search,
                [e.target.name]: e.target.value
            });

            if (e.target.value === "Residential Property") {
                setTypes(ResidentialCat);
            }
            if (e.target.value === "Rental Property") {
                setTypes(RentalCat);
            }
        }
    }

    useEffect(() => {
        setLoc({
            value: "",
            label: "Type address"
        });
        // const currentDate = new Date();
        // const formattedDate = currentDate.toISOString().split('T')[0];
        // setToday(formattedDate);
        // const script = document.createElement('script');
        // script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyA4mFVw62S7LCfcIGBHrqeG6k9Ty_NCuJw&libraries=places&language=encallback=initMap';
        // script.async = true;

        // script.onload = () => {
        //     // The script is loaded, you can now render your component
        //     setScriptLoaded(true);
        // };


        // document.head.appendChild(script);

        // return () => {
        //     // document.head.removeChild(script);
        // };
    }, []);

    const handleSelect = async (value) => {
        try {
            const results = await geocodeByAddress(value.label);
            const latLng = await getLatLng(results[0]);

            setSearched({
                ...search,
                ["latitude"]: latLng.lat,
                ["longitude"]: latLng.lng, 
                ["place"]: value.label, 

            })

            setLoc({
                value: value.label,
                label: value.label
            });


            console.log('Selected Place: ', value);
            console.log('Latitude and Longitude: ', latLng);
        } catch (error) {
            console.error('Error selecting place: ', error);
        }
    };

    function select(e) {
        setSearched({
            ...search,
            [e.target.name]: e.target.value
        });
    }

    async function searchCleaning() {

        if (search.name !== "" ) {
            if(search.date !== ''){
                const isValidDate = search.date >= today && search.date <= '9999-12-31';
                if (isValidDate) {
                  dispatch(saveSearchData(search));
                    navigate(
                        "/Service",
                        { state: { search } },
                    );
                  } else {
                    Swal.fire({
                        title: 'Error!',
                        text: "Date invalid",
                        icon: 'error'
                    });
                  }
            }else{
                navigate(
                    "/Service",
                    { state: { search } },
                );
            }
       

        } else {
            Swal.fire({
                title: 'Error!',
                text: "Please select property type",
                icon: 'error'
            });
        }

    }



    return (
        <>
            <section style={{ backgroundImage:`url(${img})`}} id="home-banner">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h1>Looking for your Property Cleaning Services?</h1>
                            <p>This platform allows you to connect with trusted and verified, independent cleaners in your
                                neighbourhood</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* {scriptLoaded == true && */}

                <section>
                    <div class="container">
                        <div id="home-form">
                            <form>
                                <div class="row">
                                    <div class="col-xxl-12">
                                        <h4>I’m looking for cleaning services for:</h4>
                                        <div class="row">
                                            <div class="col-xl-6 col-sm-6">

                                                <div class="form-check form-check-inline home mb-2">
                                                    <input class="form-check-input" type="radio" name="name" id="exampleRadios1" value="Residential Property" onChange={addType} />
                                                    <label class="form-check-label" for="exampleRadios1">
                                                        Residential Property
                                                    </label>
                                                </div>

                                            </div>

                                            <div class="col-xl-6 col-sm-6">

                                                <div class="form-check form-check-inline home">
                                                    <input class="form-check-input" type="radio" name="name" id="exampleRadios2" onChange={addType} value="Rental Property" />
                                                    <label class="form-check-label" for="exampleRadios2">
                                                        Rental Property
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">


                                            <div class="col-md-6 mt-2">

                                                <div class="row bg-row">

                                                    {types && types.length > 0 &&

                                                        <div class="col-lg-3 col-md-4 col-4">
                                                            <select name="type" onChange={select}>
                                                                <option value="" selected disabled>Select</option>
                                                                {types.map((data, i) => (
                                                                    <option value={data.name}>{data.name}</option>
                                                                ))}

                                                            </select>
                                                        </div>
                                                    }
                                                    {/* <div class="col-lg-3 col-md-4 col-4">
                                                <input type="text" name="" id="" placeholder="Postal Code" />
                                            </div> */}

                                                    <div class="col-lg-9 col-md-8 col-8">
                                                        <div class="position-relative">
                                                            <PlacesAutocomplete
                                                                selectProps={{
                                                                   value : loc,
                                                                    onChange: handleSelect,
                                                                }}

                                                                query={{ key: "AIzaSyA4mFVw62S7LCfcIGBHrqeG6k9Ty_NCuJw" }}
                                                                fetchDetails={true}
                                                                onFail={error => console.log(error)}
                                                                // placeholder="Search"
                                                            />
                                                            {/* <input class="enter-padding" type="text"
                                                        placeholder="enter complete address" /> */}
                                                            <i class="bi bi-geo-alt map"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* max="9999-12-31" */}
                                            <div class="col-md-6 mt-2">
                                                <input class="date-piker" type="date" value={search.date} name="date" onChange={select} min={today} max="9999-12-31"/>
                                            </div>
                                        </div>

                                        <div class="text-center">
                                            <button class="search-btn-form" onClick={() => searchCleaning()} type="button">Search Now</button>

                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>

            {/* } */}

        </>
    )

}