import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetAllVendors, getSearched , getProfileDetail} from './api';

  export const AllVendors = createAsyncThunk('allvend', async (data, { rejectWithValue }) => {
    try {
      const response = await GetAllVendors();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  
  export const Search = createAsyncThunk('serahhhh', async (data, { rejectWithValue }) => {
    try {
      const response = await getSearched(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });
  
  export const ProfileDetail = createAsyncThunk('profileeee', async (data, { rejectWithValue }) => {
    try {
      const response = await getProfileDetail(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });
  

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    data: null,
    locations : null,
    sidebar : null,
    loading: false,
    error: null,
    localSearchData : {}
  },
  reducers: {
    saveSearchData : (state, action) => {
      state.localSearchData = action.payload;
      localStorage.setItem('searchData', JSON.stringify(action.payload));
    },
    clearSearchData: state => {
      state.localSearchData = {};
    }, 
  },
  extraReducers: builder => {
    builder
      .addCase(AllVendors.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AllVendors.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.locations = action.payload.locations;
        state.sidebar = action.payload.SideData;
        state.loading = false;
        state.error = null;
      })
      .addCase(AllVendors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(Search.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(Search.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.locations = action.payload.locations;
        state.sidebar = action.payload.SideData;
        state.loading = false;
        state.error = null;
      })
      .addCase(Search.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(ProfileDetail.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ProfileDetail.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(ProfileDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      ;
  },
});
export const {saveSearchData, clearSearchData} = searchSlice.actions;
export default searchSlice.reducer;
