import React, { Component, useEffect, useState } from "react";
import Banner from "./Banner";
import Steps from "./Steps";

export default function GetApp() {

    return(
        <>
        <Banner />
        <Steps />
        </>
    )

}