import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { AllBlogs } from "../../../../Reducer/PagesSlice.js";
import { Link } from "react-router-dom";


export default function Blogs() {
    const dispatch = useDispatch();
    const data = useSelector(state => state.pages.data);


    useEffect(() => {

        getBlogs();

    }, []);

    async function getBlogs() {
        dispatch(AllBlogs());

    }


    return (

        <section id="blog">
            <div class="container">
                <div class="row">

                    {data && data.length > 0 && data.map((a, i) => (

                        <div class="col-sm-12 col-md-6 col-lg-4">
                            <div class="blog-item">
                                <a>
                                    <div class="blog-img">
                                        <img class="w-100" src={"https://admin.broomyy.com/public/storage/page_image/" + a.image} alt="Blog" />
                                    </div>
                                </a>
                                <div class="blog-bottom">
                                    <h3>

                                        <a>{a.title}</a>
                                    </h3>
                                    {/* <ul>
                            <li>
                                <span>By:</span>
                                <a href="#">Admin</a>
                            </li>
                            <li>
                                <span>20</span>
                                <a href="#">Comments</a>
                            </li>
                            <li>
                                <span>11</span>
                                <a href="#">Views</a>
                            </li>
                        </ul> */}
                                    {a && a.description && <div dangerouslySetInnerHTML={{ __html: a.description.substring(0, 100) }}></div>
                                    }
                                    <Link to={`/BlogDetail/${a.id}`}>
                                        Read More
                                        <i class="bi bi-arrow-right-short"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))

                    }

                    {/* <div class="col-sm-12 col-md-6 col-lg-4">
                        <div class="blog-item">
                            <a href="blog-details.html">
                                <div class="blog-img">
                                    <img class="w-100" src="images/blog-2.jpg" alt="Blog" />
                                </div>
                            </a>
                            <div class="blog-bottom">
                                <h3>
                                    <a href="blog-details.html">Know More About Our Clean Furniture And Tools</a>
                                </h3>
                                <ul>
                                    <li>
                                        <span>By:</span>
                                        <a href="#">Admin</a>
                                    </li>
                                    <li>
                                        <span>20</span>
                                        <a href="#">Comments</a>
                                    </li>
                                    <li>
                                <span>11</span>
                                <a href="#">Views</a>
                            </li>
                                </ul>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. It has
                                    survived not only five centuries</p>
                                <a href="blog-details.html">
                                    Read More
                                    <i class="bi bi-arrow-right-short"></i>
                                </a>
                            </div>
                        </div>
                    </div>


                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <div class="blog-item">
                            <a href="blog-details.html">
                                <div class="blog-img">
                                    <img class="w-100" src="images/blog-3.jpg" alt="Blog" />
                                </div>
                            </a>
                            <div class="blog-bottom">
                                <h3>
                                    <a href="blog-details.html">Know More About Our Clean Furniture And Tools</a>
                                </h3>
                                <ul>
                                    <li>
                                        <span>By:</span>
                                        <a href="#">Admin</a>
                                    </li>
                                    <li>
                                        <span>20</span>
                                        <a href="#">Comments</a>
                                    </li>
                                    <li>
                                <span>11</span>
                                <a href="#">Views</a>
                            </li>
                                </ul>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. It has
                                    survived not only five centuries</p>
                                <a href="blog-details.html">
                                    Read More
                                    <i class="bi bi-arrow-right-short"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <div class="blog-item">
                            <a href="blog-details.html">
                                <div class="blog-img">
                                    <img class="w-100" src="images/blog-1.jpg" alt="Blog" />
                                </div>
                            </a>
                            <div class="blog-bottom">
                                <h3>
                                    <a href="blog-details.html">Know More About Our Clean Furniture And Tools</a>
                                </h3>
                                <ul>
                                    <li>
                                        <span>By:</span>
                                        <a href="#">Admin</a>
                                    </li>
                                    <li>
                                        <span>20</span>
                                        <a href="#">Comments</a>
                                    </li>
                                    <li>
                                <span>11</span>
                                <a href="#">Views</a>
                            </li>
                                </ul>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. It has
                                    survived not only five centuries</p>
                                <a href="blog-details.html">
                                    Read More
                                    <i class="bi bi-arrow-right-short"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <div class="blog-item">
                            <a href="blog-details.html">
                                <div class="blog-img">
                                    <img class="w-100" src="images/blog-2.jpg" alt="Blog" />
                                </div>
                            </a>
                            <div class="blog-bottom">
                                <h3>
                                    <a href="blog-details.html">Know More About Our Clean Furniture And Tools</a>
                                </h3>
                                <ul>
                                    <li>
                                        <span>By:</span>
                                        <a href="#">Admin</a>
                                    </li>
                                    <li>
                                        <span>20</span>
                                        <a href="#">Comments</a>
                                    </li>
                                    <li>
                                <span>11</span>
                                <a href="#">Views</a>
                            </li>
                                </ul>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. It has
                                    survived not only five centuries</p>
                                <a href="blog-details.html">
                                    Read More
                                    <i class="bi bi-arrow-right-short"></i>
                                </a>
                            </div>
                        </div>
                    </div>


                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <div class="blog-item">
                            <a href="blog-details.html">
                                <div class="blog-img">
                                    <img class="w-100" src="images/blog-3.jpg" alt="Blog" />
                                </div>
                            </a>
                            <div class="blog-bottom">
                                <h3>
                                    <a href="blog-details.html">Know More About Our Clean Furniture And Tools</a>
                                </h3>
                                <ul>
                                    <li>
                                        <span>By:</span>
                                        <a href="#">Admin</a>
                                    </li>
                                    <li>
                                        <span>20</span>
                                        <a href="#">Comments</a>
                                    </li>
                                    <li>
                                <span>11</span>
                                <a href="#">Views</a>
                            </li>
                                </ul>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. It has
                                    survived not only five centuries</p>
                                <a href="blog-details.html">
                                    Read More
                                    <i class="bi bi-arrow-right-short"></i>
                                </a>
                            </div>
                        </div>
                    </div> */}

                </div>
            </div>
        </section>


    )
}