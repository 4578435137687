import { createSlice } from '@reduxjs/toolkit';

const providerForms = createSlice({
    name: 'forms',
    initialState: {
        cleaning: {},
        testi: {},
        experience: {},
        gallry: [],
        details: {}
      
    }, 

    reducers: {
        saveCleaning: (state, action) => {
            state.cleaning = action.payload;
          },
          saveTesti: (state, action) => {
            state.testi = action.payload;
          },
          saveEx: (state, action) => {
            state.experience = action.payload;
          },
          saveGall: (state, action) => {
            state.gallry = action.payload;
          },
          savePdf : (state, action) => {
            state.details = action.payload;
          },
          clearAllData : state => {
            state.details = {};
            state.gallry = [];
            state.experience = {};
            state.testi = {};
            state.cleaning  = {};
          }

        
      }
});

export const { saveCleaning, saveTesti, saveEx, saveGall, savePdf, clearAllData} = providerForms.actions;
export default providerForms.reducer;