import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useParams } from "react-router-dom";
import { SearchBlog, SingleBlog, SingleBlogSearchPage } from "../../../../Reducer/PagesSlice";
import { useLocation } from 'react-router-dom';

export default function Search() {
    const dispatch = useDispatch();
    const data = useSelector(state => state.pages.blogs);
    const params = useParams();
    let location = useLocation();

    useEffect(() => {

        getSingle();

    }, [location.pathname]);

    async function getSingle() {
        let data = { id: params.id };
        dispatch(SingleBlogSearchPage(data));

    }

    function handleChange(e){
        console.log('serach', e.target .value);
        let data = {serach : e.target.value, id : params.id};
         dispatch(SearchBlog(data));
    }

    return (
        <div class="widget-area">
            <div class="search widget-item">
                <form>
                    <input type="text" class="form-control" name="search" onChange={handleChange}  placeholder="Search..." />
                    <button  class="btn">
                        <i class="bi bi-search"></i>
                    </button>
                </form>
            </div>

            {data && data.length > 0 &&

                <div class="related widget-item">



                    {data.map((dat, i) => (

                        <div class="related-inner">
                            <ul class="align-items-center">
                                <li>
                                    <img src={"https://admin.broomyy.com/public/storage/page_image/" + dat.image} alt="Details" />
                                </li>
                                <li>
                                    <Link to={`/BlogDetail/${dat.id}`}>
                                        <a>{dat.title}</a>
                                    </Link>
                                    <span>{dat.created_at}</span>
                                </li>
                            </ul>
                        </div>

                    ))}

               
                </div>
            }
            {/* <div class="newsletter widget-item">
                <h3>Newsletter</h3>
                <form>
                    <input type="email" class="form-control" placeholder="Enter your email" />
                    <button type="submit" class="btn cmn-btn">Subscribe</button>
                </form>
            </div> */}
        </div>
    )


}