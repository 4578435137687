import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { PagesData } from "../../../../Reducer/PagesSlice";

export default function Content() {

    const dispatch = useDispatch();
    const data = useSelector(state => state.pages.data);

    useEffect(() => {

        getPageData();

    }, []);

    async function getPageData() {
        let data = { type: "Help Center" };
        dispatch(PagesData(data));

    }
    return (
        <section id="help_me">
            <div class="container">
                <div class="row">

                    {data && data.Getting_Started &&

                        <div class="col-lg-6">

                            <div class="accordion" id="accordionExample">

                                <h1>Getting Started</h1>

                                {data.Getting_Started.questions && data.Getting_Started.questions.map((a, i) => (
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id={`headingTwo${i}`}>
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target={`#collapseTwo${i}`} aria-expanded="false" aria-controls={`#collapseTwo${i}`} >
                                                {a}
                                            </button>
                                        </h2>
                                        <div id={`collapseTwo${i}`} class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                {data.Getting_Started.answers[i]}
                                            </div>
                                        </div>
                                    </div>
                                ))}



                            </div>

                        </div>

                    }

                    {data && data.Billing_Payments &&

                        <div class="col-lg-6">

                            <div class="accordion" id="accordionExample2">

                                <h1>Billing & Payments</h1>

                                {data.Billing_Payments.questions && data.Billing_Payments.questions.map((a, i) => (

                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id={`headingTwo2${i}`}>
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target={`#collapseTwo2${i}`} aria-expanded="false" aria-controls={`#collapseTwo2${i}`}  >
                                                {a}
                                            </button>
                                        </h2>

                                        <div id={`collapseTwo2${i}`} class="accordion-collapse collapse" aria-labelledby="headingTwo2"
                                            data-bs-parent="#accordionExample2">
                                            <div class="accordion-body">
                                                {data.Billing_Payments.answers[i]}
                                            </div>
                                        </div>
                                    </div>
                                ))

                                }



                            </div>

                        </div>

                    }

                    {data && data.Services_Policies &&

                        <div class="col-lg-6">

                            <div class="accordion" id="accordionExample3">

                                <h1>Services & Policies</h1>

                                {data.Services_Policies.questions && data.Services_Policies.questions.map((a, i) => (
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id={`headingTwo3${i}`}>
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target={`#collapseTwo3${i}`} aria-expanded="false" aria-controls={`#collapseTwo3${i}`}>
                                                {a}
                                            </button>
                                        </h2>
                                        <div id={`collapseTwo3${i}`} class="accordion-collapse collapse" aria-labelledby="headingTwo3"
                                            data-bs-parent="#accordionExample3">
                                            <div class="accordion-body">
                                                {data.Services_Policies.answers[i]}
                                            </div>
                                        </div>
                                    </div>

                                ))}



                            </div>

                        </div>

                    }

                    {data && data.Trust_Safety &&
                        <div class="col-lg-6">

                            <div class="accordion" id="accordionExample4">

                                <h1>Trust and Safety</h1>
                                {data.Trust_Safety.questions && data.Trust_Safety.questions.map((a, i) => (
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id={`headingTwo4${i}`}>
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target={`#collapseTwo4${i}`} aria-expanded="false" aria-controls={`#collapseTwo4${i}`}>
                                                {a}
                                            </button>
                                        </h2>
                                        <div id={`collapseTwo4${i}`} class="accordion-collapse collapse" aria-labelledby="headingTwo4"
                                            data-bs-parent="#accordionExample4">
                                            <div class="accordion-body">
                                                {data.Trust_Safety.answers[i]}
                                            </div>
                                        </div>
                                    </div>

                                ))}




                            </div>

                        </div>

                    }

                </div>
            </div>
        </section>
    )
}