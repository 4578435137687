import React, { Component, useEffect, useState } from "react";
import Banner from "./Banner";
import MapSection from "./MapSection";
import Form from "./Form";

export default function ContactUs() {

    return (

        <>
           <Banner />
           {/* <MapSection /> */}
           <Form />
        </>
    )



}
