import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { AddReview, CheckRatings, GetBookings } from '../../../../../Reducer/userSlice';
import { Rating } from 'react-simple-star-rating'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';



export default function Completed(props) {

    const dispatch = useDispatch();
    const login = useSelector(state => state.auth.auth);
    // const [complete, setCompleted] = useState([]);
    // const [open, setopen] = useState(false);
    const [open, setOpen] = useState(false);
    const [rating, setRating] = useState(0);
    const [cid, setCid] = useState({});
    const [sid, setSid] = useState({});
    const [date, setBookingDate] = useState({});
    const [rev, setReview] = useState("");
    const handleRating = (rate) => {
        setRating(rate)

        console.log('cunt ', rating);

    }

    const onPointerEnter = () => console.log('Enter')
    const onPointerLeave = () => console.log('Leave')




    useEffect(() => {

        // getCompletedBookings();
    }, [login.user_id]);


    // async function getCompletedBookings() {
    //     let uid = { user_id: login.user_id, type: "completed" };

    //     let response = await dispatch(GetBookings(uid));

    //     console.log('resssps', response);
    //     if (response.payload.status === 200) {
    //         setCompleted(response.payload.data.data);
    //     }
    // }

    const columns = [
        {
            name: 'User Name',
            selector: 'username',
            sortable: true,
        },
        {
            name: 'Service',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Address',
            selector: 'address_1',
            sortable: true,
        },
        {
            name: 'Number',
            selector: 'number',
        },
        {
            name: 'Price',
            selector: 'finalPrice',
            cell: (row) => `$${row.finalPrice}`, 
        },
        {
            name: 'Booking Date',
            selector: 'booking_date',
        },
        {
            name: 'Review',
            button: true,
            cell: (record, index) => {
                return (<><button className="btn fff" key={index} onClick={() => addRatings(index, record.id, record.service_id, record.booking_date)}>Add Review</button> </>
                )

            }
        },


    ];

    const addRatings = async (index, id, sid, date) => {
        try {
            console.log('addRatings called');
            setCid(id);
            setSid(sid);
            setBookingDate(new Date(date).toISOString().slice(0, 10));

            let data = { user_id: login.user_id, service_id: sid, cleaner_id: id, date: new Date(date).toISOString().slice(0, 10) };

            const checkk = await dispatch(CheckRatings(data));

            if (checkk.payload.status === 200) {
                setReview('');
                setRating(0);
                setOpen(true);

                if (Object.keys(checkk.payload.data.data).length > 0) {
                    setCid(checkk.payload.data.data.cleaner_id);
                    setSid(checkk.payload.data.data.sid);
                    setReview(checkk.payload.data.data.review);
                    setRating(checkk.payload.data.data.star_count);
                } else {
                    console.log('No existing ratings found');
                }
            }
        } catch (error) {
            console.error('Error in addRatings:', error);
            // Handle errors appropriately
        }
    };



    // async function addRatings(id, sid, date) {
    //     const addRatings = async (id, sid, date) => {


    //     console.log('test success');
    //     setCid(id);
    //     setSid(sid);

    //     setBookingDate(new Date(date).toISOString().slice(0, 10));
    //     let data = { user_id:login.id, service_id: sid, cleaner_id: id, date: new Date(date).toISOString().slice(0, 10) };

    //     let checkk = await dispatch(CheckRatings(data));



    //     if (checkk.payload.status == 200) {

    //         setReview("");
    //         setRating(0);


    //         setopen(true);

    //         if (Object.keys(checkk.payload.data.data).length > 0) {

    //             setCid(checkk.payload.data.data.cleaner_id);
    //             setSid(checkk.payload.data.data.sid);
    //             setReview(checkk.payload.data.data.review);
    //             setRating(checkk.payload.data.data.star_count);


    //         } else {


    //             console.log('sdfbsdjhbsfhbfhd');
    //         }




    //     }



    // }

    function closePop() {
        // console.log('sdfdsfgfgfdgfdgdgdgdgdgdgdgd---------------->');
        setOpen(false);
    }



    function getText(e) {
        console.log('event', e.target.value);
        setReview(e.target.value);
    }


    async function addReviw() {
        let data = { user_id: login.user_id, cleaner_id: cid, service_id: sid, review: rev, startCount: rating, date: date };

        let response = await dispatch(AddReview(data));
        console.log('response', response);

        if (response.payload.status == 200) {
            Swal.fire({
                title: 'Congratulation',
                text: response.payload.data.message,
                icon: 'success'
            });
        } else {
            Swal.fire({
                title: 'Error!',
                text: response.payload.data.message,
                icon: 'error'
            });
        }

    }



    return (
        <div class="tab-pane fade show active" id="pills-completed" role="tabpanel"
            aria-labelledby="pills-completed-tab">

            <div class="active_list_inner">

                <div class="table-responsive">
                    {props && props.data && props.data.length > 0 ? (
                        <DataTableExtensions
                            columns={columns}
                            data={props.data}
                            print={false}
                            export={false}
                        >
                            <DataTable
                                columns={columns}
                                data={props.data}
                                fixedHeader
                                pagination
                                highlightOnHover
                            />
                        </DataTableExtensions>
                    ) : (
                        <p>No data available</p>
                    )}

                    {open == true &&
                        <div class="rate">
                            <h5>Add Ratings<i class="bi bi-x-square" onClick={closePop}></i></h5>
                            <Rating
                                onClick={handleRating}
                                onPointerEnter={onPointerEnter}
                                onPointerLeave={onPointerLeave}
                                initialValue={rating}
                            />
                            <label>Add Review</label>
                            <input type="text" name="review" value={rev} onChange={getText} />

                            <a class="btn" onClick={addReviw}>Add</a>

                        </div>

                    }



                </div>

                {/* <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-end">
                        <li class="page-item"><a class="page-link" href="#">Previous</a>
                        </li>
                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item"><a class="page-link" href="#">Next</a>
                        </li>
                    </ul>
                </nav> */}

            </div>

        </div>
    )

}