import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { PagesData } from "../../../../Reducer/PagesSlice";


export default function TermsOfService() {

    const dispatch = useDispatch();
    const data = useSelector(state => state.pages.data);

    useEffect(() => {

        getPageData();

    }, []);

    async function getPageData() {
        let data = { type : "Terms of Service"}; 
        dispatch(PagesData(data));

    }

    return (
        <section id="privacy_statement">
        <div class="container">
            <div class="row">

                <div class="col-12">

            {data && data.content &&
                <p dangerouslySetInnerHTML={{ __html: data.content }}></p>
            }

                    {/* <h3>1. Terms</h3>

                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                        quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                        soluta porro beatae distinctio quos.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                        quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                        soluta porro beatae distinctio quos.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                        quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                        soluta porro beatae distinctio quos.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                        quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                        soluta porro beatae distinctio quos.</p>

                    <h3>2. Use License</h3> */}

                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                        quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                        soluta porro beatae distinctio quos. amet, consectetur adipisicing elit. Reprehenderit, dolorum
                        ullam! Corrupti
                        quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda.</p>

                    <p>You must not:</p>

                    <ul>
                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                    </ul> */}

                    {/* <h3>3. Changes</h3>

                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                        quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                        soluta porro beatae distinctio quos.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                        quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                        soluta porro beatae distinctio quos.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                        quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                        soluta porro beatae distinctio quos.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                        quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                        soluta porro beatae distinctio quos.</p> */}
{/* 
                    <ul>
                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                    </ul> */}


                </div>

            </div>
        </div>
    </section>
)
}