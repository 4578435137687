import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { PagesData } from "../../../../Reducer/PagesSlice";

export default function Choosing() {

    const dispatch = useDispatch();
    const data = useSelector(state => state.pages.data);

    useEffect(() => {

        getPageData();

    }, []);

    async function getPageData() {
        let data = { type: "About Us" };
        dispatch(PagesData(data));

    }

    return (
        <section id="why_choosing">
            <div class="container">
                <div class="row">
                    <h2>Why choosing us</h2>

                    {data && data.extra_content != null &&

                        <div class="row">

                            <div class="col-lg-6 col-md-3">

                                <div class="about_choosing">

                                    <div class="box-icon-3">
                                        <div class="icon">
                                            <i class="bi bi-clock"></i>
                                        </div>
                                        <div class="infobox">
                                            <h5 class="title">Save Time</h5>
                                            <div class="text">{data.extra_content.save_time}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-lg-6 col-md-3">

                                <div class="about_choosing">

                                    <div class="box-icon-3">
                                        <div class="icon">

                                            <i class="bi bi-shield-plus"></i>
                                        </div>
                                        <div class="infobox">
                                            <h5 class="title">
                                            Save Money</h5>
                                            <div class="text">{data.extra_content.safety}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* <div class="col-lg-4 col-md-6">

                                <div class="about_choosing">

                                    <div class="box-icon-3">
                                        <div class="icon">
                                            <i class="bi bi-info-circle"></i>
                                        </div>
                                        <div class="infobox">
                                            <h5 class="title">Easy To Get Help</h5>
                                            <div class="text">{data.extra_content.help}</div>
                                        </div>
                                    </div>

                                </div>
                            </div> */}

                            <div class="col-lg-6 col-md-3">

                                <div class="about_choosing">

                                    <div class="box-icon-3">
                                        <div class="icon">
                                            <i class="bi bi-chat-left-text"></i>
                                        </div>
                                        <div class="infobox">
                                            <h5 class="title">Seamless Communication</h5>
                                            <div class="text">{data.extra_content.communication
                                            }</div>

                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div class="col-lg-6 col-md-3">

                                <div class="about_choosing">

                                    <div class="box-icon-3">
                                        <div class="icon">
                                            <i class="bi bi-star"></i>
                                        </div>
                                        <div class="infobox">
                                            <h5 class="title">Best Quality</h5>
                                            <div class="text">{data.extra_content.quality}</div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* <div class="col-lg-4 col-md-6">

                                <div class="about_choosing">

                                    <div class="box-icon-3">
                                        <div class="icon">
                                            <i class="bi bi-credit-card"></i>
                                        </div>
                                        <div class="infobox">
                                            <h5 class="title">
                                                Cash Free Payment</h5>
                                            <div class="text">{data.extra_content.payment}</div>

                                        </div>
                                    </div>

                                </div>
                            </div> */}
                        </div>
                    }
                </div>
            </div>
        </section>

    )

}