// userSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUser, updateUserProfile, getUserBookings,cancelBooking, addReview, checkRatingss} from './api';

export const GetUserDetail = createAsyncThunk('GetUserDetail', async (data, { rejectWithValue }) => {

  try {
    const response = await getUser(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const UpdateUserProfile = createAsyncThunk('Updateabc', async (data, { rejectWithValue }) => {

  try {
    const response = await updateUserProfile(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const GetBookings = createAsyncThunk('Update', async (data, { rejectWithValue }) => {

  try {
    const response = await getUserBookings(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const CancelBooking = createAsyncThunk('CancelBooking', async (data, { rejectWithValue }) => {

  try {
    const response = await cancelBooking(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


export const AddReview = createAsyncThunk('AddReview', async (data, { rejectWithValue }) => {

  try {
    const response = await addReview(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const CheckRatings = createAsyncThunk('CheckRatings', async (data, { rejectWithValue }) => {

  try {
    const response = await checkRatingss(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


const userSlice = createSlice({
  name: 'user',
  initialState: {
    data: null,
    loading: false,
    error: null,
    dataArry : null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(GetUserDetail.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetUserDetail.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(GetUserDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(UpdateUserProfile.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdateUserProfile.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(UpdateUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(GetBookings.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetBookings.fulfilled, (state, action) => {
        state.dataArry = action.payload.data.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(GetBookings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(CancelBooking.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(CancelBooking.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(CancelBooking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(AddReview.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddReview.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(AddReview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(CheckRatings.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(CheckRatings.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(CheckRatings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export default userSlice.reducer;
