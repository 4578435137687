import { Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthRoute = ({ element,user }) => {
  const isAuthenticated = useSelector(state => state.auth.token);
  const CC = useSelector(state => state.auth.auth);

  return isAuthenticated!="" && CC && CC.role && CC.role==user ? (
    element
  ) : (
    <Navigate to="/login" replace />
  );
};

export default AuthRoute;
