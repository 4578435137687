import React, { useContext, useState } from "react";
import MultiStepFormContext from "./MultiStepFormContext";
import { Input, InputNumber, Field } from "formik-antd";
import { Formik } from "formik";
import { Button, Select } from "antd";
import $ from 'jquery';
import { PriorDays } from "../Data/content";
import { useDispatch, useSelector } from "react-redux";
import { saveCleaning } from "../../../Reducer/providerForms";
const { Option } = Select;
export const CleaningSetting = (props) => {
    const [day, setDay] = useState(['Monday']);
    const { clean, setCleaning } = useContext(MultiStepFormContext);

    const dispatch = useDispatch();
    const cleanData = useSelector(state => state.forms.cleaning);

    console.log('cleanData', cleanData);

    const handleClick = (e, daydata, id) => {
        let array = day;
        if (array.length > 0) {
            let index = array.findIndex((days) => days === daydata);
            if (index !== -1) {
                console.log('under if condition');
                array.splice(index, 1);
                setDay(array);
                $('#' + id).removeClass('active');

            } else {
                console.log('under else condition');
                array.push(daydata);
                setDay(array);
                $('#' + id).addClass('active');
            }
            console.log(day);
        } else {
            array.push(daydata);
            setDay(array);
            $('#' + id).addClass('active');
        }
    }
    return (<Formik
        initialValues={clean}
        onSubmit={(values) => {
            const updatedValues = { ...values, availability: day };
            setCleaning(updatedValues);
            console.log(updatedValues);
            dispatch(saveCleaning(updatedValues));
            props.onClick();
        }}
        validate={(values) => {
            console.log('valuesss under validate', values);
            const errors = {};
            if (!values.price) errors.price = "Price is required";
            // if (!values.rates) errors.rates = "Rate is required";
            // if (!values.optradio1) errors.optradio1 = "Please select yes or not";
            if (!values.optradio2) errors.optradio2 = "Please select yes or not";
            if (!values.optradio3) errors.optradio3 = "Please select yes or not";
            if (!values.optradio4) errors.optradio4 = "Please select for Cancelation policy";
            if (!values.optradio5) errors.optradio5 = "Please select any one";
            return errors;
        }}
    >
        {({ handleSubmit, errors }) => {
            return (<div class="row">
                <form>
                    <div class="form-bg">

                        <div class="gray_box_family">
                            <p>These are some default settings based on what
                                works well for new Cleaners. You can edit now, or
                                at any time in the future.</p>
                        </div>

                        <h2>Rates</h2>

                        <label for="Service" class="form-label">What you want clients to pay per service</label>
                        <Input name={'price'} className="service-input" type="text" placeholder="0" defaultValue={cleanData && cleanData.price} />
                        <p class="mt-2">What you’ll earn per service: $24.00</p>
                        <p className={"error__feedback"}>{errors.price}</p>

                        {/* <div class="form-check">
                            <Input name={'rates'} className="form-check-input" type="checkbox" value="yes" id="flexCheckDefault" />
                          
                            <label class="form-check-label" for="flexCheckDefault">
                                Update my additional rates based on my base rate

                            </label>
                            <p className={"error__feedback"}>{errors.rates}</p>
                        </div> */}

                        <h2>Availability</h2>

                        {/* <p class="pop_gill" data-bs-toggle="modal" data-bs-target="#exampleModal">Why is availability important?</p> */}
                        <p
                            class="pop_gill"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="To get started, we only require that you setup Cleaning. You’ll have the opportunity to setup Home Cleaning, Rental Cleaning, Commercial Cleaning, Window Cleaning, Steam Carpet once you complete the Sign-up process.\n \nIf you wish to add, remove, or modify the services you provide, you may do so at any time after completing the sign-up process."
                        >
                            Why is availability important?
                        </p>

                        {/* <div class="col-12">
                            <label for="Service" class="form-label">Are you home full time during the week?</label>
                            <br />

                            <div class="form-check-inline">
                                <label class="form-check-label">
                                    <Input name={'optradio1'} type="radio" className="form-check-input" value="yes" defaultChecked={cleanData && cleanData.optradio1 == "yes"} />Yes
                                </label>
                            </div>

                            <div class="form-check-inline">
                                <label class="form-check-label">
                                    <Input name={'optradio1'} type="radio" className="form-check-input" value="no" defaultChecked={cleanData && cleanData.optradio1 == "no"} />No
                                </label>
                            </div>
                            <p className={"error__feedback"}>{errors.optradio1}</p>
                        </div> */}

                        <div class="col-12">
                            <label for="Service" className="form-label">What days of the week will you typically be available for Cleaning?</label>
                            <ul class="sunday_week">
                                <li onClick={event => handleClick(event, 'Sunday', 'day1')} id="day1" className={cleanData && cleanData.availability && cleanData.availability.length > 0 && cleanData.availability.includes("Sunday") ? "active" : null}>Sun</li>

                                <li onClick={event => handleClick(event, 'Monday', 'day2')} id="day2" className={cleanData && cleanData.availability && cleanData.availability.length > 0 && cleanData.availability.includes("Monday") ? "active" : null}> Mon</li>

                                <li onClick={event => handleClick(event, 'Tuesday', 'day3')} id="day3" className={cleanData && cleanData.availability && cleanData.availability.length > 0 && cleanData.availability.includes("Tuesday") ? "active" : null}>Tue </li>

                                <li onClick={event => handleClick(event, 'Wednesday', 'day4')} id="day4" className={cleanData && cleanData.availability && cleanData.availability.length > 0 && cleanData.availability.includes("Wednesday") ? "active" : null}> Wed</li>

                                <li onClick={event => handleClick(event, 'Thursday', 'day5')} id="day5" className={cleanData && cleanData.availability && cleanData.availability.length > 0 && cleanData.availability.includes("Thursday") ? "active" : null}>Thu </li>

                                <li onClick={event => handleClick(event, 'Friday', 'day6')} id="day6" className={cleanData && cleanData.availability && cleanData.availability.length > 0 && cleanData.availability.includes("Friday") ? "active" : null}>Fri </li>

                                <li onClick={event => handleClick(event, 'Saturday', 'day7')} id="day7" className={cleanData && cleanData.availability && cleanData.availability.length > 0 && cleanData.availability.includes("Saturday") ? "active" : null}> Sat</li>
                            </ul>
                        </div>



                        <div class="col-12">
                            <label for="Service" class="form-label">How far in advance do you need new clients to reach out to you before a booking?</label>
                            <br />
                            <Field as="select" className='form-control' name={'optradio5'}>
                                <option disabled selected value="">Select the time you need</option>
                                {PriorDays.length > 0 && PriorDays.map((a, i) => (
                                    <option selected={cleanData && cleanData.optradio5 == a.name} value={a.name}>{a.name}</option>
                                ))}
                            </Field>


                            <p className={"error__feedback"}>{errors.optradio5}</p>
                        </div>

                        <div class="col-12">
                            <label for="Service" class="form-label">Are you able to provide your own cleaning supplies:</label>
                            <br />

                            <div class="form-check-inline">
                                <label class="form-check-label">
                                    <Input name={'optradio2'} type="radio" className="form-check-input" value="yes" defaultChecked={cleanData && cleanData.optradio2 == "yes"} />Yes
                                </label>
                            </div>

                            <div class="form-check-inline">
                                <label class="form-check-label">
                                    <Input name={'optradio2'} type="radio" className="form-check-input" value="no" defaultChecked={cleanData && cleanData.optradio2 == "no"} />No
                                </label>
                            </div>
                            <p className={"error__feedback"}>{errors.optradio2}</p>
                        </div>

                        <div class="col-12">
                            <label for="Service" class="form-label">Do you have your own car:</label>
                            <br />

                            <div class="form-check-inline">
                                <label class="form-check-label">
                                    <Input name={'optradio3'} type="radio" value="yes" className="form-check-input" defaultChecked={cleanData && cleanData.optradio3 == "yes"} />Yes
                                </label>
                            </div>

                            <div class="form-check-inline">
                                <label class="form-check-label">
                                    <Input name={'optradio3'} type="radio" value="no" className="form-check-input" defaultChecked={cleanData && cleanData.optradio3 == "no"} />No
                                </label>
                            </div>
                            <p className={"error__feedback"}>{errors.optradio3}</p>
                        </div>

                        <h2>Cancellation Policy</h2>

                        <p class="pop_gill" data-bs-toggle="modal" data-bs-target="#exampleModal1">What do these mean?</p>

                        <label for="Service" class="form-label">What is your cancellation policy for Cleaning?</label>

                        <div class="form-check">
                            <label class="form-check-label">
                                <Input name={'optradio4'} type="radio" value="same day" className="form-check-input" defaultChecked={cleanData && cleanData.optradio4 == "same day"} />Same Day
                            </label>
                        </div>

                        <div class="form-check">
                            <label class="form-check-label">
                                <Input name={'optradio4'} type="radio" value="one day" className="form-check-input" defaultChecked={cleanData && cleanData.optradio4 == "one day"} />One Day
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <Input name={'optradio4'} type="radio" value="three day" className="form-check-input" defaultChecked={cleanData && cleanData.optradio4 == "three day"} />Three Day
                            </label>
                        </div>

                        <div class="form-check">
                            <label class="form-check-label">
                                <Input name={'optradio4'} type="radio" value="seven day" className="form-check-input" defaultChecked={cleanData && cleanData.optradio4 == "seven day"} />Seven Day
                            </label>
                        </div>

                        <p className={"error__feedback"}>{errors.optradio4}</p>

                        <div class="row">

                            <div class="col-12">
                                <div class="text-center">
                                    <Button type={"primary"} className="btn profilee" onClick={handleSubmit}>
                                        Save
                                    </Button>
                                </div>

                            </div>

                        </div>

                    </div>
                </form>
            </div>
            );
        }}
    </Formik>
    );
};