import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { AdditionalServices } from "../../../../Reducer/PagesSlice";
import { NavLink, useNavigate } from "react-router-dom";


export default function Additional() {

    const dispatch = useDispatch();
    const data = useSelector(state => state.pages.addOns);

    useEffect(() => {

        getPageData();

    }, []);

    async function getPageData() {

        dispatch(AdditionalServices());

    }



    return (
        <section id="additional-services">
            <div class="container">
                <div class="bg-service">
                    <h2>Additional Services</h2>
                    {data && data.length > 0 ?
                        <div class="row">
                            {data.map((a, i) => (
                                <div class="col-lg-4 col-sm-6">
                                    <div class="services-sec">

                                        <h6>{a.name}</h6>
                                    </div>
                                </div>

                            ))}

                            <div class="work-btn service">
                                <NavLink className="btn" to="/Service">Get Started Now</NavLink>
                            </div>

                        </div>
                        : <div class="row">No data found</div>}
                </div>
            </div>
        </section>

    )


}