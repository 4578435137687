import React, { Component, useEffect, useState } from "react";
import Banner from "./Banner";
import BlogSection from "./BlogSection";
import Comments from "./Comments";
import CommentForm from "./CommentForm";
import Search from "./Search";

export default function BlogDetail() {

    return (

        <>
            <Banner />

            <section id="blog_details">
                <div class="container">
                    <div class="row">

                        <div class="col-lg-9">
                            <div class="details-item">
                                <BlogSection />
                                <Comments />
                                <CommentForm />

                            </div>

                        </div>

                        <div class="col-lg-3">

                            <Search />

                        </div>

                    </div>
                </div>

            </section>

        </>

    )


}