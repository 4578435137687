import React, { useContext, useState } from "react";
import MultiStepFormContext from "./MultiStepFormContext";
import { Input, TextArea } from "formik-antd";
import { Formik } from "formik";
import { Button, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { saveEx } from "../../../Reducer/providerForms";
const { Option } = Select;
export const Experience = (props) => {
    const { experience, setExperience } = useContext(MultiStepFormContext);
    const dispatch = useDispatch();
    const exData = useSelector(state => state.forms.experience);

    return (
        <Formik initialValues={exData}
            onSubmit={(values) => {
                setExperience(values);
                dispatch(saveEx(values));
                props.onClick();
            }}
            validate={(values) => {
                const errors = {};
                if (!values.experience) errors.experience = "Experience is required";
                if (!values.headline) errors.headline = "Headline is required";
                if (!values.cleaning_experience) errors.cleaning_experience = "Cleaning Experience is required";
                if (!values.schedule) errors.Schedule = "Schedule is required";
                if (!values.environment) errors.environment = "Environment is required";
                return errors;
            }}

        >
            {({ handleSubmit, errors }) => {
                return (<div class="row">
                    <div class="form-bg">
                        <div class="gray_box_family mb-3">
                            <p>Let clients know your about your personal qualities</p>
                        </div>
                        <h2>Quick tips:</h2>
                        <ul class="quick_tips">
                            <li>We recommend keeping personal identifiers like
                                your last name or workplace out of your profile.</li>
                            <li>Please click ‘Save &amp; Continue’ below to prevent any updates from being lost.</li>
                        </ul>
                        <label for="Service" class="form-label">Years of personal or professional experience
                            in cleaning.</label>
                        <Input name={"experience"} class="service-input" type="text" placeholder="enter years of experience" defaultValue={exData && exData.experience}/>
                        <p className={"error__feedback"}>{errors.experience}</p>

                        <label for="Service" class="form-label">Write and eye catching headline</label>
                        <p class="mb-2">Make your headline short, descriptive and genuine.</p>

                        <Input name={"headline"} class="service-input" type="text" placeholder="enter headline" defaultValue={exData && exData.headline}/>
                        <p className={"error__feedback"}>{errors.headline}</p>

                        <label for="Service" class="form-label">Cleaning Experience</label>
                        <p class="mb-2">What sets you apart from other Cleaners? Be sure to
                            Eco friendly products or attention to details.</p>

                        <Input name={"cleaning_experience"} defaultValue={exData && exData.cleaning_experience}  />
                        <p class="mb-0 text-end">25 words minimum</p>
                        <p className={"error__feedback"}>{errors.cleaning_experience}</p>

                        <label for="Service" class="form-label">Schedule</label>
                        <p class="mb-2">How does Cleaning fit into your daily or weekly routine?</p>

                        <Input name={"schedule"} defaultValue={exData && exData.schedule} />
                        <p class="mb-0 text-end">25 words minimum</p>
                        <p className={"error__feedback"}>{errors.schedule}</p>

                        <label for="Service" class="form-label">Safety, trust &amp; environment</label>
                        <p class="mb-2">How do you clean in your home and/or your client’s home? This will vary depending on which service you offer.</p>
                        <Input name={'environment'} defaultValue={exData && exData.environment} />
                        <p class="mb-0 text-end">25 words minimum</p>
                        <p className={"error__feedback"}>{errors.environment}</p>
                        <div class="row">
                            <div class="col-12">
                                <div class="text-center">
                                    <Button type={"primary"} className="btn profilee" onClick={handleSubmit}>
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                );
            }}
        </Formik>
    );
};