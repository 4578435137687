import React, { Component, useEffect, useState } from "react";
import { Kitchen } from "../../Data/content";
import { Bathrooms } from "../../Data/content";
import { Bedrooms } from "../../Data/content";
import { CommonAreas } from "../../Data/content";


export default function Included() {


    return (

        <section id="Included">
            <div class="container">

            <h2>What is Included?</h2>
                <div class="row row_Kitchen_property">
                   
                    <div class="col-xl-3 col-md-6">
                        <div class="Kitchen-property">
                            <div class="Kitchen-img">
                                <img class="w-100" src="images/kitchen.png" alt="" />
                            </div>
                            <h4>Kitchen</h4>
                            <ul>

                                {Kitchen.length > 0 && Kitchen.map((a) => (
                                    <li> <span>{a.name}</span> </li>
                                ))}

                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="Kitchen-property">
                            <div class="Kitchen-img">
                                <img class="w-100" src="images/bathroom.png" alt="" />
                            </div>
                            <h4>Bathrooms</h4>
                            <ul>

                                {Bathrooms.length > 0 && Bathrooms.map((a) => (
                                    <li><span>{a.name}</span> </li>
                                ))}


                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="Kitchen-property">
                            <div class="Kitchen-img">
                                <img class="w-100" src="images/bedroom-2.png" alt="" />
                            </div>
                            <h4>Bedrooms</h4>
                            <ul>

                                {Bedrooms.length > 0 && Bedrooms.map((a) => (
                                    <li> <span>{a.name}</span></li>
                                ))}

                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="Kitchen-property">
                            <div class="Kitchen-img">
                                <img class="w-100" src="images/Common-Areas.png" alt="" />
                            </div>
                            <h4>Common Areas</h4>
                            <ul>
                                {CommonAreas.length > 0 && CommonAreas.map((a) => (
                                    <li><span>{a.name}</span></li>
                                ))}


                            </ul>
                        </div>
                    </div>
                    <div class="col-12">
                        <p><b>Note:</b> all cleaning supplies and equipment are to be provide by the Cleaners. </p>
                    </div>
                </div>
            </div>
        </section>

    )

}