import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { ResidentialCat } from '../../Data/content';
import { RentalCat } from '../../Data/content';
import { GetAdds, ServicesData } from '../../../../Reducer/vendorSlice';
import { AddUserService } from '../../../../Reducer/vendorSlice';
import { DeleteService } from '../../../../Reducer/vendorSlice';
import { GetEditData } from '../../../../Reducer/vendorSlice';
import { EditDataSubmit } from '../../../../Reducer/vendorSlice';
import { useDispatch, useSelector } from 'react-redux';


export default function Services() {

    const login = useSelector(state => state.auth.auth);
    const dispatch = useDispatch();
    const [pop, setPopUp] = useState("");

    const [residential, setResidential] = useState(ResidentialCat);
    const [rental, setRental] = useState(RentalCat);

    const [mainNew, setmainNew] = useState([]);
    const [main, setmain] = useState([]);
    const [details, setDetails] = useState([]);
    const [mid, setMid] = useState({});
    const [t1, sett1] = useState(false);

    const [openPriceDiv, setPriceDiv] = useState({});
    const [adPrice, setPrice] = useState(0);
    const [arry, serArry] = useState([]);
    const [sArry, setSarry] = useState([]);

    const [opneDiv, setDiv] = useState({});
    const [addOnprice, setaddOnPrice] = useState(0);
    const [addedServices, setAdded] = useState([]);


    useEffect(() => {
        getaddOns();

    }, []);

    async function getaddOns() {
        console.log('sesss', login.user_id);
        let uid = { user_id: login.user_id };

        let response = await dispatch(ServicesData(uid));

        if (response.payload.status == 200) {

            let a1 = [];
            let a2 = []
            for (let index = 0; index < response.payload.data.main.length; index++) {
                console.log('indexxxxxx', response.payload.data.main[index]);

                if (response.payload.data.main[index].added == "false") {

                    console.log('false acse');
                    a1.push(response.payload.data.main[index]);
                } else {
                    console.log('true acse');
                    a2.push(response.payload.data.main[index]);
                }


            }
            setmain(a1);
            setmainNew(a2);
        }

    }

    async function onAdd(id, type) {
        if (type === "edit") {
            setPopUp("edit");
            sett1(true);
            setMid(id);
            let data = { sid: id, user_id: login.user_id };
            let res = await dispatch(GetEditData(data));
            if (res.payload.status === 200) {
                console.log('edit data', res.payload.data);
                serArry(res.payload.data.data.typesArry);
                if (res.payload.data.data.added.length > 0) {
                    setAdded(res.payload.data.data.added);
                }
                if (res.payload.data.data.default.length > 0) {
                    setDetails(res.payload.data.data.default);
                }
                if (res.payload.data.data.typesArry.length > 0) {
                    let newArry = res.payload.data.data.typesArry;

                    if (res.payload.data.data.service_id === 1) {
                        setResidential(prevResidential => {
                            const updatedResidential = [...prevResidential];

                            newArry.forEach(item => {
                                const indexCheck = updatedResidential.findIndex(obj => obj.name === item.name);
                                if (indexCheck > -1) {
                                    updatedResidential.splice(indexCheck, 1);
                                }
                            });

                            return updatedResidential;
                        });
                    } else {
                        setRental(prevRental => {
                            const updatedRental = [...prevRental];

                            newArry.forEach(item => {
                                const indexCheck = updatedRental.findIndex(obj => obj.name === item.name);
                                if (indexCheck > -1) {
                                    updatedRental.splice(indexCheck, 1);
                                }
                            });

                            return updatedRental;
                        });
                    }
                }


            }

        }
        if (type === "add") {
            setPopUp("add");
            sett1(true);
            setMid(id);
            let data = { user_id: login.user_id };
            let res = await dispatch(GetAdds(data));

            if (res.payload.status === 200) {

                setDetails(res.payload.data.data);
            }

        }


    }

    function closeDiv() {
        sett1(false);
        setAdded([]);
        setDiv(-1);
        setaddOnPrice(0);
        getaddOns();
        setDetails([]);
        setSarry([]);
        serArry([]);
        setPrice(0);
        setPriceDiv(-1);
        setResidential(ResidentialCat);
        setRental(RentalCat);

    }

    function addEx(i) {
        if (opneDiv == i) {
            setDiv(-1);
        }
        else {
            setDiv(i);
        }


    }

    function addPriceAddOn(e) {
        setaddOnPrice(e.target.value);
    }

    function onAddExtra(i, id, name) {
        if (addOnprice !== 0) {
            let data = {
                id: id,
                name: name,
                price: addOnprice
            }

            // setAdded(addedServices.concat(data));
            setAdded(prevAddedServices => prevAddedServices.concat(data));

            setaddOnPrice(0);
            setDiv(-1);
            // details.splice(i, 1);
            setDetails(prevDetails => {
                const updatedDetails = [...prevDetails];
                updatedDetails.splice(i, 1);
                return updatedDetails;
            });

        } else {
            Swal.fire({
                title: 'Error!',
                text: "Please add price",
                icon: 'error'
            });
        }

    }



    function remove(i) {
        const updatedAddedServices = [...addedServices];
        const removedService = updatedAddedServices.splice(i, 1)[0];
        setDetails([...details, removedService]);
        setAdded(updatedAddedServices);
    }



    async function onALlAdd() {
        if (arry.length > 0) {

            let formData = new FormData();
            formData.append('user_id', login.user_id);
            formData.append('service_id', mid);
            formData.append('typesArry', JSON.stringify(arry));
            formData.append('addedServices', JSON.stringify(addedServices));

            let res = await dispatch(AddUserService(formData));

            console.log('ressssssssss', res);

            if (res.payload.status === 200) {
                Swal.fire({
                    title: 'Congratulation',
                    text: res.payload.data.message,
                    icon: 'success'
                });

            } else {
                Swal.fire({
                    title: 'Error!',
                    text: res.payload.data.message,
                    icon: 'error'
                });
            }

        } else {
            Swal.fire({
                title: 'Error!',
                text: "Please add atleast one type",
                icon: 'error'
            });
        }


    }

    async function onUpdate() {

        if (arry.length > 0) {

            let formData = new FormData();
            formData.append('user_id', login.user_id);
            formData.append('service_id', mid);
            formData.append('typesArry', JSON.stringify(arry));
            formData.append('addedServices', JSON.stringify(addedServices));
            let res = await dispatch(EditDataSubmit(formData));
            if (res.payload.status === 200) {
                Swal.fire({
                    title: 'Congratulation',
                    text: res.payload.data.message,
                    icon: 'success'
                });


            } else {
                Swal.fire({
                    title: 'Error!',
                    text: res.payload.data.message,
                    icon: 'error'
                });
            }
        } else {
            Swal.fire({
                title: 'Error!',
                text: "Please add atleast one type",
                icon: 'error'
            });
        }




        // if (pri > 0) {
        //     let formData = new FormData();
        //     formData.append('user_id', login.user_id);
        //     formData.append('service_id', mid);
        //     formData.append('types', JSON.stringify(sArry));
        //     // formData.append('kitchen', JSON.stringify(kitt));
        //     // formData.append('bathroom', JSON.stringify(bath));
        //     // formData.append('bedroom', JSON.stringify(bed));
        //     // formData.append('common', JSON.stringify(com));
        //     formData.append('price', pri);
        //     formData.append('addedServices', JSON.stringify(addedServices));


        //     let res = await dispatch(EditDataSubmit(formData));
        //     if (res.payload.status == 200) {
        //         Swal.fire({
        //             title: 'Congratulation',
        //             text: res.payload.data.message,
        //             icon: 'success'
        //         });


        //     } else {
        //         Swal.fire({
        //             title: 'Error!',
        //             text: res.payload.data.message,
        //             icon: 'error'
        //         });
        //     }

        // }
        // else {
        //     Swal.fire({
        //         title: 'Error!',
        //         text: "Price cannot be empty",
        //         icon: 'error'
        //     });
        // }


    }


    async function onDelete(id) {
        let data = { service_id: id, user_id: login.user_id };
        let del = await dispatch(DeleteService(data));

        if (del.payload.status == 200) {
            Swal.fire({
                title: 'Congratulation',
                text: del.payload.data.message,
                icon: 'success'
            });

            getaddOns();

        } else {
            Swal.fire({
                title: 'Error!',
                text: del.payload.data.message,
                icon: 'error'
            });
        }
    }

    function addTYpePrice(i) {
        if (opneDiv == i) {
            setPriceDiv(-1);
        }
        else {
            setPriceDiv(i);
        }
    }

    function addPriceForType(e) {
        setPrice(e.target.value);
    }

    function onAddType(i, id, name, type) {

        if (adPrice !== 0) {


            let data = {
                name: name,
                price: adPrice
            }

            serArry(arry.concat(data));
            setSarry(sArry.concat(name));
            setPrice(0);
            setPriceDiv(-1);
            if (type === "ResidentialCat") {
                // Use setResidential to update the state without modifying the original array
                setResidential(prevResidential => {
                    const updatedResidential = [...prevResidential];
                    updatedResidential.splice(i, 1);
                    return updatedResidential;
                });
            } else {
                // Use setRental to update the state without modifying the original array
                setRental(prevRental => {
                    const updatedRental = [...prevRental];
                    updatedRental.splice(i, 1);
                    return updatedRental;
                });
            }
        } else {
            Swal.fire({
                title: 'Error!',
                text: "Please add price",
                icon: 'error'
            });
        }
    }

    function removeType(i, type) {
        const updatedTypes = [...arry];
        const removedService = updatedTypes.splice(i, 1)[0];
        if (type === "Residential") {
            setResidential([...residential, removedService]);

        } else {
            setRental([...rental, removedService]);
        }
        serArry(updatedTypes);
    }



    return (
        <>
            <div class="tab-pane fade show active" id="pills-services" role="tabpanel" aria-labelledby="pills-services-tab">

                <div class="Servicesdiv">

                    {/* <h2>Services <span onClick={handlePreview}><a class="btn" href="#"><i class="bi bi-plus"></i>Add</a></span> </h2> */}


                    <div class="row">

                        <div className='col-lg-6 mb-3'>
                            <h2>All Services </h2>

                            {main && main.length > 0 && main.map((a, i) => (

                                <div class="set mb-3">
                                    <img width="50px" src="images/Common-Areas.png" alt="dashicon1" />
                                    <h4>{a.name}</h4>
                                    <a class="Edit" onClick={() => onAdd(a.id, "add")} > <i class="fa-solid fa-plus"></i> Add</a>
                                </div>

                            ))}

                        </div>

                        <div className='col-lg-6 mb-3'>
                            <h2>User Services </h2>
                            {mainNew && mainNew.length > 0 && mainNew.map((a, i) => (

                                <div class="set mb-3">
                                    <img width="50px" src="images/Common-Areas.png" alt="dashicon1" />
                                    <h4>{a.name}</h4>
                                    <a class="btn addRemov" onClick={() => onAdd(a.id, "edit")} > <i class="fa-solid fa-pen-to-square"></i> Edit</a>

                                    <a class="btn addRemov" onClick={() => onDelete(a.id)} > <i class="fa-solid fa-trash"></i> Remove</a>
                                </div>

                            ))}

                        </div>

                        {t1 === true &&
                            <div class="gelll">

                                <div className='flex_call_close'>
                                    <div class="add_btn_cc">

                                        {pop == "add" ?
                                            <button className="btn" onClick={() => onALlAdd()}>Add</button>
                                            : <button className="btn" onClick={() => onUpdate()}>Update</button>
                                        }

                                    </div>

                                    <div class="dv_close" onClick={closeDiv}>
                                        <span>Close</span>
                                    </div>



                                </div>

                                {mid === 1 && ResidentialCat.length > 0 &&
                                    <div class="Servicesdiv mt-4">
                                        <div class="row">


                                            <div class="col-sm-6">

                                                <h4>Residential Type</h4>

                                                {residential.map((a, i) => (

                                                    <div class="set hilll mb-2">
                                                        <h4>{a.name}</h4>

                                                        <a onClick={() => addTYpePrice(i)} class="Edit two"> <i class="fa-solid fa-pen-to-square"></i> Add</a>

                                                        {openPriceDiv == i &&
                                                            <div>
                                                                <label>Add Price</label>
                                                                <input type="number" onChange={addPriceForType} required />
                                                                <a onClick={() => onAddType(i, a.id, a.name, "ResidentialCat")} class="Edit two"> <i class="fa-solid fa-plus"></i> Save</a>

                                                            </div>
                                                        }
                                                    </div>

                                                ))}
                                            </div>

                                            <div className="col-sm-6">
                                                <h4>Added Types</h4>
                                                {arry && arry.length > 0 && arry.map((key, i) => (

                                                    <div className="set hilll mb-2" >
                                                        <h4>{key.name}<span>${key.price}</span></h4>

                                                        <a onClick={() => removeType(i, "Residential")} className="Edit two"> <i className="fa-solid fa-pen-to-square"></i> Remove</a>
                                                    </div>

                                                ))}


                                            </div>
                                        </div>
                                    </div>
                                }


                                {mid === 2 && RentalCat.length > 0 &&
                                    <div class="Servicesdiv mt-4">
                                        <div class="row">

                                            <div class="col-sm-6">
                                                <h2>Rental Type</h2>
                                                {rental.map((a, i) => (

                                                    <div class="set hilll mb-2">
                                                        <h4>{a.name}</h4>

                                                        <a onClick={() => addTYpePrice(i)} class="Edit two"> <i class="fa-solid fa-pen-to-square"></i> Add</a>

                                                        {openPriceDiv === i &&
                                                            <div>
                                                                <label>Add Price</label>
                                                                <input type="number" onChange={addPriceForType} required />
                                                                <a onClick={() => onAddType(i, a.id, a.name, "RentalCat")} class="Edit two"> <i class="fa-solid fa-plus"></i> Save</a>

                                                            </div>
                                                        }
                                                    </div>
                                                ))}
                                            </div>

                                            <div class="col-sm-6">
                                                <h2>Added Types</h2>
                                                {arry.length > 0 && arry.map((key, i) => (

                                                    <div class="set hilll mb-2" >
                                                        <h4>{key.name}<span>${key.price}</span></h4>

                                                        <a onClick={() => removeType(i, "Rental")} class="Edit two"> <i class="fa-solid fa-pen-to-square"></i> Remove</a>
                                                    </div>

                                                ))}

                                            </div>


                                        </div>
                                    </div>



                                }




                                <div class="Servicesdiv mt-4">


                                    <div class="row">
                                        <div className='col-sm-6'>
                                            <h2>Add on Services </h2>
                                            {details.length > 0 && details.map((key, i) => (
                                                <div class="set hilll mb-2">
                                                    <h4>{key.name}</h4>

                                                    <a onClick={() => addEx(i)} class="Edit two"> <i class="fa-solid fa-pen-to-square"></i> Add</a>
                                                    
                                                    {opneDiv === i &&
                                                        <div>
                                                            <label>Add Price (per item)</label>
                                                            <input type="number" onChange={addPriceAddOn} required />


                                                            <a onClick={() => onAddExtra(i, key.id, key.name)} class="Edit two"> <i class="fa-solid fa-plus"></i> Save</a>

                                                        </div>

                                                    }




                                                </div>



                                            ))}

                                        </div>

                                        <div className='col-sm-6'>
                                            <h2>Added Services </h2>
                                            {addedServices.length > 0 && addedServices.map((key, i) => (

                                                <div class="set hilll mb-2" >
                                                    <h4>{key.name}<span>${key.price}</span></h4>
                                                    <a onClick={() => remove(i)} class="Edit two"> <i class="fa-solid fa-pen-to-square"></i> Remove</a>
                                                </div>

                                            ))}

                                        </div>
                                    </div>
                                </div>

                            </div>

                        }

                    </div>
                </div>


            </div >


        </>
    )
}