import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllBlogs, getSingleBlog, getPgeData, getTestimonials, getAdditionalServices, getHomeData, submitContactQuery, submitComment, getComments, BlogSearch, getSingleBlogSerach} from './api';

export const AllBlogs = createAsyncThunk('All blogs', async (_, { rejectWithValue }) => {
    try {
      const response = await getAllBlogs();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });
export const SingleBlog = createAsyncThunk('Single blogs', async (data, { rejectWithValue }) => {
    try {
      const response = await getSingleBlog(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  export const PagesData = createAsyncThunk('page data', async (data, { rejectWithValue }) => {
    try {
      const response = await getPgeData(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });


  export const testimonial = createAsyncThunk('Testimonials', async (_, { rejectWithValue }) => {
    try {
      const response = await getTestimonials();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  export const AdditionalServices = createAsyncThunk('Additional services', async (_, { rejectWithValue }) => {
    try {
      const response = await getAdditionalServices();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  export const HomeData = createAsyncThunk('Get Home Data', async (_, { rejectWithValue }) => {
    try {
      const response = await getHomeData();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });


  export const ContactForm = createAsyncThunk('Form COntact Data', async (data, { rejectWithValue }) => {
    try {
      const response = await submitContactQuery(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  export const CommentFormSubmit = createAsyncThunk('Form Comment', async (data, { rejectWithValue }) => {
    try {
      const response = await submitComment(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  export const GetAllComments = createAsyncThunk('All Comments', async (data, { rejectWithValue }) => {
    try {
      const response = await getComments(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  
  export const SingleBlogSearchPage = createAsyncThunk('All SingleBlogSearchPage', async (data, { rejectWithValue }) => {
    try {
      const response = await getSingleBlogSerach(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  export const SearchBlog = createAsyncThunk('search blog', async (data, { rejectWithValue }) => {
    try {
      const response = await BlogSearch(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

const PagesSlice = createSlice({
    name: 'pages',
    initialState: {
      data: null,
      addOns : null,
      home : null,
      loading: false,
      error: null,
      blogs: null
    },
    reducers: {},
    extraReducers: builder => {
      builder
        .addCase(AllBlogs.pending, state => {
          state.loading = true;
          state.error = null;
        })
        .addCase(AllBlogs.fulfilled, (state, action) => {
          state.data = action.payload.data;
          state.loading = false;
          state.error = null;
        })
        .addCase(AllBlogs.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(SingleBlog.pending, state => {
          state.loading = true;
          state.error = null;
        })
        .addCase(SingleBlog.fulfilled, (state, action) => {
          state.data = action.payload.data;
          state.loading = false;
          state.error = null;
        })
        .addCase(SingleBlog.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(PagesData.pending, state => {
          state.loading = true;
          state.error = null;
        })
        .addCase(PagesData.fulfilled, (state, action) => {
          state.data = action.payload.data;
          state.loading = false;
          state.error = null;
        })
        .addCase(PagesData.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(testimonial.pending, state => {
          state.loading = true;
          state.error = null;
        })
        .addCase(testimonial.fulfilled, (state, action) => {
          state.data = action.payload.data;
          state.loading = false;
          state.error = null;
        })
        .addCase(testimonial.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(AdditionalServices.pending, state => {
          state.loading = true;
          state.error = null;
        })
        .addCase(AdditionalServices.fulfilled, (state, action) => {
          state.addOns = action.payload.data;
          state.loading = false;
          state.error = null;
        })
        .addCase(AdditionalServices.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(HomeData.pending, state => {
          state.loading = true;
          state.error = null;
        })
        .addCase(HomeData.fulfilled, (state, action) => {
          state.home = action.payload.data;
          state.loading = false;
          state.error = null;
        })
        .addCase(HomeData.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(ContactForm.pending, state => {
          state.loading = true;
          state.error = null;
        })
        .addCase(ContactForm.fulfilled, (state, action) => {
          state.data = action.payload;
          state.loading = false;
          state.error = null;
        })
        .addCase(ContactForm.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(CommentFormSubmit.pending, state => {
          state.loading = true;
          state.error = null;
        })
        .addCase(CommentFormSubmit.fulfilled, (state, action) => {
          state.data = action.payload.data;
          state.loading = false;
          state.error = null;
        })
        .addCase(CommentFormSubmit.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(GetAllComments.pending, state => {
          state.loading = true;
          state.error = null;
        })
        .addCase(GetAllComments.fulfilled, (state, action) => {
          state.data = action.payload.data;
          state.loading = false;
          state.error = null;
        })
        .addCase(GetAllComments.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(SearchBlog.pending, state => {
          state.loading = true;
          state.error = null;
        })
        .addCase(SearchBlog.fulfilled, (state, action) => {
          state.blogs = action.payload.data;
          state.loading = false;
          state.error = null;
        })
        .addCase(SearchBlog.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(SingleBlogSearchPage.pending, state => {
          state.loading = true;
          state.error = null;
        })
        .addCase(SingleBlogSearchPage.fulfilled, (state, action) => {
          state.blogs = action.payload.data;
          state.loading = false;
          state.error = null;
        })
        .addCase(SingleBlogSearchPage.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        ;
    },
  });

export default PagesSlice.reducer;

