import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AcceptRejectBooking, GetPending } from '../../../../../Reducer/vendorSlice';
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';
import Bookingdetails from '../Bookingdetails';
import ChatPopUp from '../../../ChatPopUp';
import axios from 'axios';


export default function Active(props) {
    const dispatch = useDispatch();
    const login = useSelector(state => state.auth.auth);
    const [open, setOpen] = useState(false);
    const [bookingId, setBookingId] = useState("");
    const [user, setUser] = useState({});
    const [chat, setChatbot] = useState(false);
    const [accessToken, setAccessToken] = useState(null);

    // const active = useSelector(state => state.vendor.data);

    const columns = [
        {
            name: 'User Name',
            selector: 'username',
            sortable: true,

        },
        {
            name: 'Service',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Address',
            selector: 'address',
            sortable: true,

        },
        {
            name: 'Number',
            selector: 'phone',
        },
        {
            name: 'Price',
            selector: 'finalPrice',
            cell: (row) => `$${row.finalPrice}`,
        },
        {
            name: 'Booking Date',
            selector: 'booking_date',
        },
        {
            name: 'Action',
            button: true,
            width: "225px",

            style: {
                textAlign: "center",
                borderRadius: "4px",
                padding: "10px",
                fontWeight: "600px",
                color: "#f1f1f1",
                marginRight: "10px",
                // background: "#72bd0a",
                border: "none",

            },
            cell: (record) => {
                return (<><button class="btn fff" onClick={() => complete(record.id, record.service_id, record.booking_date, 'complete', record.cleaner_earning, record.paypal_id)}>Done</button> <button onClick={() => openChild(record.booking_id)} class="btn fff"
                > View</button>
                    <button onClick={() => OpenChatBot(record.id, record.username, record.image)} class="btn fff"
                    > Chat</button> </>
                )

            }
        },


    ];

    function OpenChatBot(id, name, image) {
        setUser({ username: name, id: id, image: image });
        setChatbot(true);
    }

    const receiveResponseFromChild123 = (response) => {
        setChatbot(response);
    };


    async function openChild(booking_id) {
        setBookingId(booking_id);
        setOpen(true);
    }


    const receiveResponseFromChild = (response) => {
        setOpen(response);
    };

    useEffect(() => {
        fetchAccessToken();
    }, [login.user_id]);

    const fetchAccessToken = async () => {
        try {
            const response = await axios.post(
                'https://api.sandbox.paypal.com/v1/oauth2/token',
                null,
                {
                    params: {
                        grant_type: 'client_credentials'
                    },
                    auth: {
                        username: 'AW-nQQrYnhH6H-fxUVWvgiKaqJ5XgiuHmadr1YJuRqV-LskXXd-_d-vOzslDWYv5h6Kprw05sPmxfJU2',
                        password: 'ELOjGQgcVFBpfqbDPFNLDT6SWPI0ZsSwWn-YuIrX9RgcUlCwtUk4lK06kfM_tPtg9Xu3JW5m8uvkTHTy'
                    }
                }
            );
            setAccessToken(response.data.access_token);
        } catch (error) {
            console.error('Error fetching access token:', error);
        }
    };

    async function Transfer(vendorPayPalId, amount) {
        try {

            // Step 2: Transfer the money to the vendor's PayPal.me link
            const transferResponse = await axios.post(
                'https://api.sandbox.paypal.com/v1/payments/payouts',
                {
                    sender_batch_header: {
                        email_subject: 'Payment from Broomy',
                    },
                    items: [
                        {
                            recipient_type: 'PAYPAL_ID',
                            amount: {
                                value: amount.toFixed(2), // Amount to be transferred
                                currency: 'USD', // Currency
                            },
                            receiver: vendorPayPalId, // Vendor's PayPal ID (PayPal.me link)
                            note: 'Payment for services rendered.', // Optional note
                            sender_item_id: 'item_1', // Unique ID for the item
                        },
                    ],
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            console.log('Transfer Response:', transferResponse.data);
            return transferResponse.data;
        } catch (error) {
            console.error('Transfer Error:', error.response.data);
            throw error;
        }
    }



    async function complete(id, sid, date, type, cleaner_earning, vendorPayPalId) {
        console.log('uid cleaner_earning', cleaner_earning);

        // let transfer = await Transfer(vendorPayPalId, cleaner_earning);

        let data = { user_id: login.user_id, uid: id, service_id: sid, date: date, type: type };
        let response = await dispatch(AcceptRejectBooking(data));

        if (response.payload.status == 200) {
            Swal.fire({
                title: 'Congratulation',
                text: response.payload.data.message,
                icon: 'success'
            });

            // getActiveBookings();
        } else {
            Swal.fire({
                title: 'Error!',
                text: response.payload.data.message,
                icon: 'error'
            });
        }

    }


    return (

        <div class="tab-pane fade show active" id="pills-active" role="tabpanel"
            aria-labelledby="pills-active-tab">

            <div class="active_list_inner">

                {open === false &&

                    <div class="table-responsive">
                        {props && props.data && props.data.length > 0 ? (
                            <DataTableExtensions
                                columns={columns}
                                data={props.data}
                                print={false}
                                export={false}
                            >
                                <DataTable
                                    columns={columns}
                                    data={props.data}
                                    fixedHeader
                                    pagination
                                    highlightOnHover
                                />
                            </DataTableExtensions>
                        ) : (
                            <p>No data available</p>
                        )}

                    </div>

                }

                {open === true &&

                    <div>
                        <Bookingdetails id={bookingId} sendResponseToParent={receiveResponseFromChild} />

                    </div>

                }
                {chat && chat == true &&
                    <div>
                        <ChatPopUp data={user} sendResponseToParent={receiveResponseFromChild123}></ChatPopUp>
                        {/*  */}
                    </div>
                }


            </div>
        </div>


    )


}





