import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { GetBookings } from '../../../../../Reducer/userSlice';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';
import ChatPopUp from '../../../ChatPopUp';

export default function Active(props) {

    const dispatch = useDispatch();
    const login = useSelector(state => state.auth.auth);
    const [chat, setChatbot] = useState(false);
    const [providers, setProvider] = useState({});
    // const [active, setActive] = useState([]);

    const columns = [
        {
            name: 'User Name',
            selector: 'username',
            sortable: true,
        },
        {
            name: 'Service',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Address',
            selector: 'address_1',
            sortable: true,
        },
        {
            name: 'Number',
            selector: 'number',
        },
        {
            name: 'Price',
            selector: 'finalPrice',
            cell: (row) => `$ ${row.finalPrice}`, 
        },
        {
            name: 'Booking Date',
            selector: 'booking_date',
        },


   {
            name: 'Action',
            button: true,
            cell: (record) => {
                return (<> <a className='btn fff' onClick={() => OpenChatBot(record.id, record.username, record.image)}>Chat</a> </>
                )

            }
        },


    ];

    function OpenChatBot(id, name, image) {

        setProvider({ username: name, id: id, image: image });
        setChatbot(true);
    }

    const receiveResponseFromChild = (response) => {
        setChatbot(response);
    };


    useEffect(() => {

        // getActiveBookings();
    }, [login.user_id]);


    // async function getActiveBookings() {
    //     let uid = { user_id: login.user_id , type: "active" };

    //     let response = await dispatch(GetBookings(uid));

    //     console.log('resssps', response);
    //     if (response.payload.status === 200) {
    //         setActive(response.payload.data.data);
    //     }
    // }

    return (

        <div class="tab-pane fade show active" id="pills-active" role="tabpanel"
            aria-labelledby="pills-active-tab">

            <div class="active_list_inner">

                <div class="table-responsive">



                    {props && props.data && props.data.length > 0 ? (
                        <DataTableExtensions
                            columns={columns}
                            data={props.data}
                            print={false}
                            export={false}
                        >
                            <DataTable
                                columns={columns}
                                data={props.data}
                                fixedHeader
                                pagination
                                highlightOnHover
                            />
                        </DataTableExtensions>
                    ) : (
                        <p>No data available</p>
                    )}

                </div>
                {chat && chat == true &&
                <div>
                    <ChatPopUp data={providers} sendResponseToParent={receiveResponseFromChild}></ChatPopUp>
                    {/*  */}
                </div>
            }


            </div>


        </div>
    )

}