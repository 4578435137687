import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
export const verifyForm = createAsyncThunk('sendotp/verifyForm', async (formData) => {
  try {
    const response = await axios.post('https://admin.broomyy.com/api/verify_otp', formData);
    return response.data;
  } catch (error) {
    throw new Error('Failed to submit form.');
  }
});

const verifyformSlice = createSlice({
  name: 'verify_otp',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verifyForm.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyForm.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(verifyForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default verifyformSlice.reducer;
