import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationsWeb } from "../../../Reducer/notificationSlice";

export default function Notifications({ handleDataFromChild }) {

    const login = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.notifi.data);
    const dispatch = useDispatch();
    const img_url = "https://admin.broomyy.com/public/storage/profile_images/";


    useEffect(() => {
        getNotiii();

    }, []);


    async function getNotiii() {
        let data = { user_id: login.user_id };
        dispatch(getNotificationsWeb(data));

    }

    return (

        <div>
            {data && data.length > 0 &&
                <>
                    <ul class="notii_popup popupp">
                        <h4>Notifications <i class="fa-solid fa-xmark" onClick={() => handleDataFromChild()}></i></h4>
                        {data.map((item, i) => (
                            <li> <img src={img_url + item.image} alt="bell" />{item.description}<p>{new Date(item.created_at).toLocaleDateString('en-GB')}</p> </li>

                        ))}


                    </ul>
                </>
            }
        </div>


    )

}