import React, { Component, useEffect, useState } from "react";
export default function Loader(props) {

 const [status, setStatus] = useState(props.status);


 console.log('statuss', props.status);

 return (

 <>

 {status === true &&

 <div className="loderdiv">

 <img src="images/loader.gif" />

 </div>
 }
 </>




 )


}