import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { GetUserDetail } from '../../../../Reducer/userSlice';
import { UpdateUserProfile } from '../../../../Reducer/userSlice';

export default function Proile() {

    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [profileData, setProfile] = useState({});
    const [file, setFile] = useState();
    const [fileData, setData] = useState();
    const dispatch = useDispatch();
    const login = useSelector(state => state.auth.auth);
    useEffect(() => {
        getProfile();

        console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log(formErrors);
        }


    }, [])

    async function getProfile() {
        let uid = { user_id: login.user_id };
        let response = await dispatch(GetUserDetail(uid));
        if (response.payload.status == 200) {
            setProfile(response.payload.data.data);
        }


    }


    function handleChange(e) {
        console.log(e.target.files);
        setData(e.target.files[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
    }


    function handleChangeInput(evt) {

        console.log('eeee', evt.target.value);
        const value = evt.target.value;
        setProfile({
            ...profileData,
            [evt.target.name]: value
        });
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setFormErrors(validate(profileData));
        setIsSubmit(true);


        console.log('submitttedddd data', Object.keys(formErrors).length);

        if (Object.keys(formErrors).length == 0 && isSubmit === true) {

            console.log('valiudated data');
            let formData = new FormData();
            formData.append('user_id', login.user_id);
            formData.append('first_name', profileData.first_name);
            formData.append('last_name', profileData.last_name);
            formData.append('email', profileData.email);
            formData.append('phone', profileData.number);
            formData.append('dob', profileData.dob);
      
            formData.append('image', fileData);


            let update = await dispatch(UpdateUserProfile(formData));

            if (update.payload.status == 200) {
                Swal.fire({
                    title: 'Congratulation',
                    text: update.payload.data.message,
                    icon: 'success'
                });

            } else {
                Swal.fire({
                    title: 'Error!',
                    text: update.payload.data.message,
                    icon: 'error'
                });
            }

   

        }
    }

    const validate = (values) => {
        const errors = {};
        // const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

        if (!values.first_name) {
            errors.first_name = "first name is required!";
        }
        if (!values.last_name) {
            errors.last_name = "last name is required!";
        }

        if (!values.email) {
            errors.email = "Email is required!";
        }
        // else if(!regex.test(values.email)){
        //     errors.email = "This is not a valid email format!";
        // }
        if (!values.number) {
            errors.number = "Phone is required!";
        }

        if (!values.dob) {
            errors.dob = "Date of birth is required!";
        } else if(values.dob){
            const birthDate = new Date(values.dob);
            const currentDate = new Date();
            const age = currentDate.getFullYear() - birthDate.getFullYear();
    
            if (age < 18) {
                errors.dob = "You must be 18 years or older!";
            }
        }
       
        return errors;
    }



    return (<>

        <div class="tab-pane fade show active" id="pills-profile" role="tabpanel"
            aria-labelledby="pills-profile-tab">

            <div class="tab_profile_upload">
                <div class="row">
                    <div class="col-lg-4">

                        <div class="imagefile">
                            <span><i class="fas fa-camera"></i>
                                <input type='file'  onChange={handleChange} />
                            </span>

                            {file ? <img class="w-100" id="blah" src={file} /> : <img class="w-100" id="blah" src={"https://admin.broomyy.com/public/storage/profile_images/" + profileData.image} alt="your image" />}

                        </div>
                    </div>

                    <div class="col-lg-8">

                        <form onSubmit={handleSubmit}>


                            <div class="row">

                                <div class="col-md-6">

                                    <div class="form-group">
                                        <label class="form-label">First Name</label>
                                        <input type="text" className="form-control" name="first_name" placeholder="Enter full name" onChange={handleChangeInput} value={profileData.first_name}/>
                                        <p className="error">{formErrors.first_name}</p>
                                    </div>


                                </div>
                                <div class="col-md-6">

                                    <div class="form-group">
                                        <label class="form-label">Last Name</label>
                                        <input type="text" class="form-control" name="last_name" onChange={handleChangeInput} value={profileData.last_name} />
                                        <p className="error">{formErrors.last_name}</p>
                                    </div>

                                </div>


                                <div class="col-md-6">

                                    <div class="form-group">
                                        <label class="form-label">Phone Number</label>
                                        <input type="tel" class="form-control" name="number" placeholder="Enter phone number" onChange={handleChangeInput} value={profileData.number} readOnly />
                                        <p className="error">{formErrors.number}</p>
                                    </div>

                                </div>

                                <div class="col-md-6">

                                    <div class="form-group">
                                        <label class="form-label">Email Id</label>
                                        <input type="email" class="form-control" name="email" placeholder="Enter email id"  onChange={handleChangeInput} value={profileData.email} readOnly />
                                        <p className="error">{formErrors.email}</p>
                                    </div>

                                </div>

                                <div class="col-md-6">

                                    <div class="form-group">
                                        <label class="form-label">Date of Birth</label>
                                        <input type="date" class="form-control" name="dob" placeholder="Enter age"  value={profileData.dob} onChange={handleChangeInput}  max="9999-12-31"/>
                                        <p className="error">{formErrors.dob}</p>
                                    </div>

                                </div>



                                <div class="col-md-12">

                                    <div class="form-group text-center">

                                        <button type="submit">Update</button>
                                    </div>

                                </div>

                            </div>

                        </form>

                    </div>

                </div>
            </div>

        </div>

    </>);




}