import { NavLink, useNavigate } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { clearStatus, clearToken, clearUser } from "../../../Reducer/authSlice";
import Notifications from "../../Pages/Notifications";
import { logo, clean, loc, notificom, search } from "./urls";
import { clearSearchData } from "../../../Reducer/searchSlice";
import { getNotiCounts } from "../../../Reducer/notificationSlice";


export default function Header() {

  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const type = useSelector(state => state.auth.auth);

  const [lat, setlat] = useState("");
  const [long, setlong] = useState("");

  const [noti, setNoti] = useState(false);

  const [location, setLocation] = useState(null);
  const [address, setAddress] = useState(null);
  const login = useSelector(state => state.auth.auth);
  const count = useSelector(state => state.notifi.count);

  const handleDataFromChild = () => {
    setNoti(false);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      const requestPermission = () => {
        navigator.permissions
          .query({ name: 'geolocation' })
          .then(function (result) {
            console.log('result', result);
            if (result.state === 'granted') {
              console.log(result.state);

              // If granted, get the user's location
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  console.log('Location access granted');
                  const { latitude, longitude } = position.coords;
                  console.log('Latitude:', latitude);
                  console.log('Longitude:', longitude);
                  setLocation({ latitude, longitude });
                  setlat(latitude);
                  setlong(longitude);
                  localStorage.setItem('latitude', latitude);
                  localStorage.setItem('longitude', longitude);


                  // Use a geocoding service to get the address
                  const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyA4mFVw62S7LCfcIGBHrqeG6k9Ty_NCuJw&`;

                  fetch(geocodingApiUrl)
                    .then((response) => response.json())
                    .then((data) => {
                      const formattedAddress = data.results[0].formatted_address;

                      if (data.results[0].plus_code && data.results[0].plus_code.compound_code) {
                        console.log('Address:', data.results[0].plus_code.compound_code);
                        setAddress(data.results[0].plus_code.compound_code);
                      }


                    });
                },
                () => {
                  // User denied location access or didn't respond
                  console.log('Location access denied or not responded');
                  // Request permission again after a delay
                  setTimeout(requestPermission, 1000); // Adjust the delay as needed
                }
              );
            } else if (result.state === 'prompt') {
              console.log(result.state);
              // If in prompt state, ask the user for permission
              navigator.geolocation.getCurrentPosition(
                () => {
                  // User allowed location access
                  console.log('Location access granted');
                },
                () => {
                  // User denied location access or didn't respond
                  console.log('Location access denied or not responded');
                  // Request permission again after a delay
                  setTimeout(requestPermission, 1000); // Adjust the delay as needed
                }
              );
            } else if (result.state === 'denied') {
              // If denied, you have to show instructions to enable location
              console.log('Location access denied');
            }
            result.onchange = function () {
              console.log(result.state);
            };
          });
      };

      requestPermission(); // Start the permission request process
    } else {
      alert('Sorry, geolocation is not available in your browser.');
    }
    // console.log('login', login);
    if (Object.keys(login).length > 0) {
      let da = { uid: login.user_id };
      dispatch(getNotiCounts(da));
    }
  }, [dispatch]);



  const logout = () => {
    dispatch(clearUser());
    dispatch(clearStatus());
    dispatch(clearToken());
    dispatch(clearSearchData());
    localStorage.removeItem('latitude');
    localStorage.removeItem('longitude');
    localStorage.removeItem('searchData');


  }

  function openNoti() {
    setNoti(true);
    let da = { uid: login.user_id };
    dispatch(getNotiCounts(da));
  }


  return (<header class="header">
    <div class="container">
      <div class="row">
        <div class="col-lg-2 col-md-2">
          <div class="logo">
            <NavLink className="navbar-brand" to="/"><img src={logo} alt="img" /></NavLink>
          </div>
        </div>
        <div class="col-lg-10 col-md-10">
          <ul>
            {type && type.role == "provider" ? "" :
              <li class="nav-item">
                <NavLink className="nav-link" style={({ isActive }) => ({ color: isActive ? 'greenyellow' : '' })} to="/Service"> <img src={search} alt="img" /> Search Cleaners</NavLink>
              </li>
            }
            {type && type.role == "user" ? "" : <li class="nav-item">
              <NavLink className="nav-link" style={({ isActive }) => ({ color: isActive ? 'greenyellow' : '' })} to="/provider"> <img src={clean} alt="img" /> Sign Up Cleaner</NavLink>
            </li>}

            {address != null &&
              <li class="nav-item" >
                <NavLink className="nav-link" style={({ isActive }) => ({ color: isActive ? 'greenyellow' : '' })}> <img src={loc} alt="img" />  {address}
                </NavLink>
              </li>

            }

          </ul>
          <ul class="login-right">
            {token && token != null &&
              <li class="position-relative"><NavLink style={({ isActive }) => ({ color: isActive ? 'greenyellow' : 'white' })} onClick={openNoti}><img src={notificom} alt="img" /></NavLink> {count > 0 ?
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {count}
                </span> : ""}</li>
            }

            {token ? <><li><NavLink onClick={logout} to="/" style={({ isActive }) => ({ color: isActive ? 'greenyellow' : 'white' })} className="btn btn-dark login-icon" >Logout</NavLink></li> </> :
              <li><NavLink style={({ isActive }) => ({ color: isActive ? 'greenyellow' : 'white' })} className="btn btn-dark login-icon" to="/Login">Login</NavLink></li>
            }
            {type && type.role == 'user' && <li><NavLink to="/UserDashboard" className={"user"}><i class="fa fa-user ff" aria-hidden="true"></i></NavLink></li>
            }
            {
              type && type.role == 'provider' && <li><NavLink to="/Dashboard" className={"user"}><i class="fa fa-user ff" aria-hidden="true"></i>
              </NavLink></li>
            }

          </ul>
        </div>
      </div>
      {noti == true &&
        <div>
          <Notifications handleDataFromChild={handleDataFromChild} ></Notifications>
        </div>
      }
    </div>
  </header>);
}
