import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';
import { getTransactions } from '../../../../Reducer/vendorSlice';

export default function Transactions() {

    const dispatch = useDispatch();
    const login = useSelector(state => state.auth.auth);
    const data = useSelector(state => state.vendor.data);

    const columns = [
        {
            name: 'User Name',
            selector: 'username',
            sortable: true,

        },
        {
            name: 'Service',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Number',
            selector: 'phone',
        },
        {
            name: 'Booking Date',
            selector: 'booking_date',
        },
        {
            name: 'Service Price',
            selector: 'main_price',
            cell: (row) => `$${row.main_price}`, 
        },
        {
            name: 'Earning',
            selector: 'cleaner_earning',
            cell: (row) => `$${row.cleaner_earning}`, 
        },
        {
            name: 'Admin Commission',
            selector: 'cleaner_commission',
            cell: (row) => `$${row.cleaner_commission}`, 
        },

    ];

    useEffect(() => {
        let dd = { uid: login.user_id };
        dispatch(getTransactions(dd));

    }, [login.user_id], dispatch);

    return (


        <div class="active_list_inner">


            {data && data.length > 0 ? (

                <DataTableExtensions
                columns={columns}
                data={data}
                print={false}
                export={false}
                >
                    <DataTable
                    columns={columns}
                    data={data}
                    fixedHeader
                    pagination
                    highlightOnHover
                    />
                </DataTableExtensions>

            ) : (
                <p>No data available</p>
            )

            }

        </div>

    )


}
