
import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Active from '../Bookings/Active/index';
import Pending from '../Bookings/Pending/index';
import Completed from '../Bookings/Completed/index';
import Cancelled from '../Bookings/Cancelled/index';
import Bookingdetails from './Bookingdetails';
import { GetPending, getCounts } from '../../../../Reducer/vendorSlice';


export default function Bookings() {

    const dispatch = useDispatch();
    const login = useSelector(state => state.auth.auth);
    const counts = useSelector(state => state.vendor.dataArry);

    useEffect(() => {
        let fetch = { user_id: login.user_id };
        dispatch(GetPending(fetch));
    }, [dispatch]);

    const handleTabSelect = (index, lastIndex, event) => {
        // Dispatch your action here
        let fetch = { user_id: login.user_id };
        dispatch(GetPending(fetch));
    };


    return (
        <>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-booking" role="tabpanel" aria-labelledby="pills-booking-tab">

                    <div class="tab-pane fade show" >

                        <div className="booking_list_tab" >
                            <Tabs onSelect={handleTabSelect}>

                                <TabList className="nav nav-pills mb-3">
                                    <Tab>Active ({counts && counts.activeCount})</Tab>
                                    <Tab>Pending ({counts && counts.pendingCount})</Tab>
                                    <Tab>Completed ({counts && counts.completedCount})</Tab>
                                    <Tab>Cancelled ({counts && counts.cancelledCount})</Tab>
                                </TabList>


                                <TabPanel >
                                    <Active data={counts && counts.active} />
                                </TabPanel>
                                <TabPanel >
                                    <Pending data={counts && counts.pending} />
                                </TabPanel>
                                <TabPanel>
                                    <Completed data={counts && counts.completed} />
                                </TabPanel>
                                <TabPanel>
                                    <Cancelled data={counts && counts.cancelled} />
                                </TabPanel>

                            </Tabs>

                        </div>

                    </div>







                </div>
            </div>
        </>
    )
}