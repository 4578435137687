import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { GalleryData } from '../../../../Reducer/vendorSlice';
import { AddGallery } from '../../../../Reducer/vendorSlice';


export default function Gallery() {
    // const [session, setUid] = useState(JSON.parse(localStorage.getItem('user')));
    const [gallery, setGallery] = useState([]);
    const [images, setImages] = useState([]);
    const [uploaded, upImages] = useState([]);
    const login = useSelector(state => state.auth.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        getGallery();
    }, [])

    async function getGallery() {
        let id = { user_id: login.user_id };
        let response = await dispatch(GalleryData(id));
        if (response.payload.status === 200) {

            console.log('imagesss', response.payload.data.data);

            setGallery(response.payload.data.data.images);
        }

    }

    const selectFile = async (event) => {
        const file = event.target.files[0];
        const base64data = await uploadImagess(file);
        upImages([...uploaded, file]);
        setImages([...images, base64data]);
    }

    function uploadImagess(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    function delbackImage(index, type) {

        if (type === "back") {
            const updatedGallery = [...gallery.slice(0, index), ...gallery.slice(index + 1)];
            // Update the state with the new array
            setGallery(updatedGallery);
        } if (type === "front") {
            const updatedGallery = [...images.slice(0, index), ...images.slice(index + 1)];
            // Update the state with the new array
            setImages(updatedGallery);

            const updatedUploaded = [...uploaded.slice(0, index), ...uploaded.slice(index + 1)];
            // Update the state with the new array
            upImages(updatedUploaded);
        }

    }

    async function SaveGalley() {
        if (uploaded.length > 0 || gallery.length > 0) {
            let formData = new FormData();

            console.log('uploaded images', uploaded);

            for (let i = 0; i < uploaded.length; i++) {
                formData.append('images[]', uploaded[i]);
            }
            formData.append('user_id', login.user_id);
            formData.append('old_gallery', JSON.stringify(gallery));

            let response = await dispatch(AddGallery(formData));

            console.log('responseresponse', response.payload.data)

            if (response.payload.status == 200) {
                Swal.fire({
                    title: 'Congratulation',
                    text: response.payload.data.message,
                    icon: 'success'
                });

            } else {
                Swal.fire({
                    title: 'Error!',
                    text: response.payload.data.message,
                    icon: 'error'
                });
            }

        } else {
            Swal.fire({
                title: 'Error!',
                text: "Please select atleast one image",
                icon: 'error'
            });
        }


    }


    return (
        <>

            <div class="tab-pane fade show active" id="pills-gallery" role="tabpanel" aria-labelledby="pills-gallery-tab">

                <div class="gallery_tab">
                    <div class="row">


                        <section id="booking_form" class="mt-4">
                            <div class="row">
                                <form>
                                    <div class="form-bg">

                                        <div class="gray_box_family">
                                            <p>Add photos of yourself with cleaning supplies and
                                                equipment to show the service you give to them.
                                                We suggest 5-10 of your best photos.</p>
                                        </div>

                                        <div class="row mt-4">
                                            {gallery && gallery.length > 0 && gallery.map((pro, i) => {
                                                return (<>
                                                    <div class="col-md-3 col-sm-4 col-6">
                                                        <div class="add_img_uplod" style={{ backgroundImage: `url(${"https://admin.broomyy.com/public/storage/profile_images/" + pro})` }}>
                                                            <span onClick={() => delbackImage(i, 'back')}> <i class="fa fa-trash" aria-hidden="true" ></i></span>
                                                        </div>

                                                    </div>

                                                </>)

                                            })}



                                            {images.map((item, key) => {
                                                return (
                                                    <div class="col-md-3 col-sm-4 col-6">
                                                        <div class="add_img_uplod" style={{ backgroundImage: `url(${item})` }}>
                                                            <span onClick={() => delbackImage(key, 'front')}>
                                                                <i class="fa fa-trash" aria-hidden="true" ></i></span>

                                                        </div>

                                                    </div>)
                                            })}


                                            <div class="col-md-3 col-sm-4 col-6">
                                                <div class="add_img_uplod">
                                                    <input type="file" onChange={selectFile} />
                                                    <h1> <i class="bi bi-plus"></i>
                                                        <p>Add More</p></h1>

                                                </div>
                                            </div>

                                        </div>


                                        <div class="row">

                                            <div class="col-12">
                                                <div class="text-center">
                                                    {/* <a onClick={()=>{props.onClick();}} class="btn profilee"> Continue</a> */}

                                                    <a onClick={SaveGalley} class="btn profilee"> Submit</a>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </form>
                            </div>

                        </section>

                    </div>
                </div>


            </div>
        </>
    )
}