import React, { Component, useEffect, useState } from "react";
import Swal from 'sweetalert2';
import { Link, Navigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { CommentFormSubmit } from "../../../../Reducer/PagesSlice";
import { clearStatus } from "../../../../Reducer/authSlice";
import { useLocation } from 'react-router-dom';


export default function CommentForm() {
    const dispatch = useDispatch();
    const error = useSelector(state => state.pages.error);
    const success = useSelector(state => state.pages.data);
    const params = useParams();
    const [inputField, setInputField] = useState({
        name: '',
        email: '',
        comment: '',
        BlogId : params.id
     
    });
    let location = useLocation();

    useEffect(() => {

    }, [location.pathname]);

    const inputsHandler = (e) => {
        setInputField({
            ...inputField,
            [e.target.name]: e.target.value
        });
    }


    const submitButton = (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('name', inputField.name);
        formData.append('email', inputField.email);
        formData.append('comment', inputField.Address);
        formData.append('BlogId', params.id);

      dispatch(CommentFormSubmit(formData));
    //   console.log('testt ', res);

    //   if(res.payload.status == "succsss"){
    //       Swal.fire({
    //           title: 'Success!',
    //           text: res.payload.message,
    //           icon: 'success'
    //       });
    //   }else{
    //       Swal.fire({
    //           title: 'Error!',
    //           text: res.payload.message,
    //           icon: 'error'
    //       });
    //   }

    }


    if (success && success.status) {

    console.log('test comment', success);
     
        switch (success.status) {
            case 403:
                Swal.fire({
                    title: 'Success!',
                    text: success.data.message,
                    icon: 'success'
                });

                break;
            default:
                Swal.fire({
                    title: 'Success!',
                    text: 'Form Submitted succssfully',
                    icon: 'success'
                });
                break;
        }
        dispatch(clearStatus());
    }

    return (
        <div class="details-form">
        <h3>Leave A Reply</h3>
        <span>Your email address will not be published. Required fields are marked *</span>
        <form>
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label>Name*</label>
                        <input type="text" name="name" onChange={inputsHandler} class="form-control"/>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label>Email*</label>
                        <input type="email" name="email" onChange={inputsHandler} class="form-control"/>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="form-group">
                        <label>Comment</label>
                        <textarea id="your-comment" rows="6" onChange={inputsHandler} name="comment" class="form-control"></textarea>
                    </div>
                </div>
                <div class="col-lg-12">
                    <button type="submit" onClick={submitButton} class="btn cmn-btn">Post A Comment</button>
                </div>
            </div>
        </form>
    </div>
    )

}