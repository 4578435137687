import React, { Component, useEffect, useState } from "react";
import SearchForm from "./SearchForm";
import PropertyCare from "./PropertyCare";
import Included from "./Included";
import Additional from "./Additional";
import Testimonials from "./Testimonials";

export default function Home() {
    return (<>

    <SearchForm />
    <PropertyCare />
    <Included />
    <Additional />
    <Testimonials />

      
        </>);
}
