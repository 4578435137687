import React, { useContext, useState, useEffect } from "react";
// import LocationSearch from "../Location";
import { Formik } from "formik";
import { Button } from "antd";
import { Input } from "formik-antd";
import MultiStepFormContext from "./MultiStepFormContext";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';

const Address = () => {
  const { address, setAddress, next, prev } = useContext(MultiStepFormContext);
  const [location, setLocation] = useState(null);
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const handleSelect = async (value) => {
    try {
      const results = await geocodeByAddress(value.label);
      const latLng = await getLatLng(results[0]);
      setLocation({
        value: value.label,
        label: value.label
    });

      setLat(latLng.lat);
      setLong(latLng.lng);

      console.log('Selected Place: ', value);
      console.log('Latitude and Longitude: ', latLng);
    } catch (error) {
      console.error('Error selecting place: ', error);
    }
  };

  useEffect(() => {

    setLocation({
      value: "",
      label: "Type address"
  });

    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyA4mFVw62S7LCfcIGBHrqeG6k9Ty_NCuJw&libraries=places&language=encallback=initMap';
    script.async = true;

    script.onload = () => {
      setScriptLoaded(true);
    };

    document.head.appendChild(script);

  }, []);

  return (

    <Formik
      initialValues={address}
      onSubmit={(values) => {
        values.address1 = location.value;
        values.lat = lat;
        values.long = long;
        console.log('values', values);
        setAddress(values);
        next();
      }}
      validate={(values) => {
        const errors = {};
        if (location === "") errors.address1 = "First Address is required";
        if (!values.city) errors.city = "City is required";
        if (!values.country) errors.country = "Country is required";
        if (!values.state) errors.state = "State is required";
        if (!values.postal_code) errors.postal_code = "Postal Code is required";       
        return errors;
      }}
    >
      {({ handleSubmit, errors }) => {
        return (


          <div class="row">

            {scriptLoaded === true &&
              <form>
                <div class="form-bg">
                  <h2>Add your Address</h2>
                  <label for="Service" class="form-label">Address Line 1</label>
                  <PlacesAutocomplete
                    selectProps={{
                      value : location,
                      onChange: handleSelect,
                    }}
                    // name={"address1"}                     
                    query={{ key: "AIzaSyA4mFVw62S7LCfcIGBHrqeG6k9Ty_NCuJw" }}
                    fetchDetails={true}
                    onFail={error => console.log(error)}
                    placeholder="Search"
                    // name={"address1"}
                    className="service-input"
                  />
                  <p className={"error__feedback"}>{errors.address1}</p>
                  <Input name={"address1"} className="service-input" type="hidden" placeholder="Enter address" value={location} /> 


                  <label for="Service" class="form-label">Address Line 2</label>
                  <Input name={"address2"} className="service-input" type="text" placeholder="Enter address" />
                  <p className={"error__feedback"}>{errors.address2}</p>
                  <div class="row">
                    <div class="col-sm-6">
                      <label for="Service" class="form-label">Country</label>
                      <Input name={"country"} className="service-input" type="text" placeholder="Country" />
                      <p className={"error__feedback"}>{errors.country}</p>
                    </div>

                    <div class="col-sm-6">
                      <label for="Service" class="form-label">City</label>
                      <Input name={"city"} className="service-input" type="text" placeholder="City" />
                      <p className={"error__feedback"}>{errors.city}</p>
                    </div>

                  </div>

                  <div class="row">
                    <div class="col-sm-6">
                      <label for="Service" class="form-label">State</label>
                      <Input name={"state"} className="service-input" type="text" placeholder="State" />
                      <p className={"error__feedback"}>{errors.state}</p>
                    </div>

                    <div class="col-sm-6">
                      <label for="Service" class="form-label">Postal Code</label>
                      <Input name={"postal_code"} className="service-input" type="text" placeholder="Postal Code" />
                      <p className={"error__feedback"}>{errors.postal_code}</p>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="text-center">
                        <Button type={"default"} onClick={prev}>
                          Back
                        </Button>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="text-center">
                        <Button type={"primary"} onClick={handleSubmit}>
                          Next
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            }


          </div>

        );
      }}
    </Formik>
  );
};
export default Address;

