import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { HomeData } from "../../../../Reducer/PagesSlice";
import img from "../../Data/work-bg.png";
import { NavLink, useNavigate } from "react-router-dom";


export default function PropertyCare() {

    const dispatch = useDispatch();
    const data = useSelector(state => state.pages.home);

    useEffect(() => {

        getPageData();

        console.log('home data', data);

    }, []);

    async function getPageData() {

        dispatch(HomeData());

    }

    return (
        <>
            <section id="property-care">
                <div class="container">
                <h2>Property Care Services</h2>
                    {data &&
                        <div class="row row_property">

                          

                            <div class="col-lg-6">
                                <div class="residential-property h-100">
                                    <div class="residential-img">
                                        <img class="w-100" src="images/country-house.png" alt="" />
                                    </div>



                                    <h4>Residential Property</h4>
                                    <p dangerouslySetInnerHTML={{ __html: data.residential_content }}></p>

                             
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="residential-property h-100">
                                    <div class="residential-img">
                                        <img class="w-100" src="images/rental.png" alt="" />
                                    </div>

                                    <h4>Rental Property</h4>
                                    <p dangerouslySetInnerHTML={{ __html: data.rental_content }}></p>
                                
                                </div>
                            </div>

                        </div>

                    }
                </div>
            </section>
            <section style={{ backgroundImage:`url(${img})`}} id="how-work">
                <div class="container">
                    {data &&
                        <div class="row">

                            <h2>How it Works</h2>

                            <div class="col-lg-3 col-6">
                                <div class="work-sec">
                                    <img src="images/work-search.png" alt="" />
                                    <h6>{data.search_work}</h6>
                                </div>
                            </div>

                            <div class="col-lg-3 col-6">
                                <div class="work-sec">
                                    <img src="images/reviews.png" alt="" />
                                    <h6>{data.verifyReview}</h6>
                                </div>
                            </div>

                            <div class="col-lg-3 col-6">
                                <div class="work-sec">
                                    <img src="images/share.png" alt="" />
                                    <h6>{data.message}</h6>
                                </div>
                            </div>

                            <div class="col-lg-3 col-6">
                                <div class="work-sec">
                                    <img src="images/support.png" alt="" />
                                    <h6>{data.support}</h6>
                                </div>
                            </div>

                            <div class="work-btn">
                            <NavLink className="btn" to="/Service">Start Now</NavLink>
                            </div>

                        </div>
                    }
                </div>
            </section>


        </>
    )
}