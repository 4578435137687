import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { PagesData } from "../../../../Reducer/PagesSlice";

export default function CookieContent() {

    const dispatch = useDispatch();
    const data = useSelector(state => state.pages.data);

    useEffect(() => {

        getPageData();

    }, []);

    async function getPageData() {
        let data = { type: "Cookie Policy" };
        dispatch(PagesData(data));

    }


    return (
        <section id="privacy_statement">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        {data && data.content &&
                            <p dangerouslySetInnerHTML={{ __html: data.content }}></p>

                        }
                    </div>
                </div>
            </div>
        </section>
    )
}
