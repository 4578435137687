import React, { useEffect, useRef, useState } from 'react';
import { InfoWindow } from 'google-maps-react';


const Map = ({ data, key }) => {
    const googleMapRef = useRef(null);
    let googleMap = null;

    const [selectedMarker, setSelectedMarker] = useState(null);


    useEffect(() => {
        console.log('datd map', data);
        googleMap = initGoogleMap();
        var bounds = new window.google.maps.LatLngBounds();
        if (Array.isArray(data) && data.length > 0) {
            data.map(x => {

                const lat = parseFloat(x.position.lat);
                const lng = parseFloat(x.position.lng);

                const updatedPosition = {
                    lat: lat,
                    lng: lng
                };

                const marker = createMarker({ ...x, position: updatedPosition });
                bounds.extend(marker.position);

                return { ...x, position: updatedPosition };
            });
        } else {

            const marker = createMarker(data);
            bounds.extend(marker.position);

        }

        googleMap.fitBounds(bounds);
    }, [key]);

    const img_url = "https://admin.broomyy.com/public/storage/profile_images/";


    const initGoogleMap = () => {
        return new window.google.maps.Map(googleMapRef.current, {
            center: { lat: 30.7333148, lng: 76.7794179 },
            zoom: 8
        });
    }


    const createMarker = (markerObj) => {
        if (markerObj.position && markerObj.position.lat !== undefined && markerObj.position.lng !== undefined) {
            const marker = new window.google.maps.Marker({
                position: {
                    lat: parseFloat(markerObj.position.lat),
                    lng: parseFloat(markerObj.position.lng),
                },
                map: googleMap,
                icon: {
                    url: img_url + markerObj.image,
                    scaledSize: new window.google.maps.Size(30, 30)
                }
            });

     
            // Display marker info on hover
            displayMarkerInfoOnHover(marker, markerObj);


            return marker;
        } else {
            // Handle cases where lat and lng are missing or undefined
            console.warn("Invalid marker data. Missing lat or lng.");
            return null; // Or handle it as appropriate for your use case
        }
    };

    // Create a function to display marker information on hover
    const displayMarkerInfoOnHover = (marker, markerObj) => {
        const infowindow = new window.google.maps.InfoWindow({
            content: `<strong>${markerObj.title}</strong>`,
        });

        marker.addListener('mouseover', () => {
            infowindow.open(googleMap, marker);
        });

        marker.addListener('mouseout', () => {
            infowindow.close();
        });
    };




    return (<div ref={googleMapRef} style={{ height: 800 }}>
        {/* Show InfoWindow when a marker is selected */}
        {selectedMarker && (
            <InfoWindow
                position={selectedMarker.position}
                visible={true}
            >
                <div>
                    {/* Display marker details here */}
                    <p>{selectedMarker.name}</p>
                    {/* <p>{selectedMarker.description}</p> */}
                </div>
            </InfoWindow>
        )}
    </div>);
}

export default Map;