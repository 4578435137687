import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { AvailibiltyData } from '../../../../Reducer/vendorSlice';
import { UpdateAvailibility } from '../../../../Reducer/vendorSlice';
import { useDispatch, useSelector } from 'react-redux';
import { PriorDays } from "../../Data/content";
import $ from 'jquery';



export default function Availability() {
 
    const [data23, setData] = useState({});

    const login = useSelector(state => state.auth.auth);
    const dispatch = useDispatch();
    const [errors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [day, setDay] = useState([]);

    useEffect(() => {
        
        getAvailability();

    }, []);

    useEffect(() => {
        console.log('data23',data23)
      }, {data23})

    async function getAvailability() {
        let uid = { user_id: login.user_id };
        let response = await dispatch(AvailibiltyData(uid));
        if (response.payload.status == 200) {

            setData(response.payload.data.data);
            setDay(response.payload.data.data.cleaning_days);

        }


    }

    function handleChangeInput(evt) {
      
        const value = evt.target.value;
        setData({
            ...data23,
            [evt.target.name]: value
        });

    }

    // const handleClick = (e, daydata, id) => {
    //     e.preventDefault(e);
    //     let array = day;
    //     console.log('day', day);
    //     if (array.length > 0) {
    //         let index = array.findIndex((days) => days === daydata);
    //         if (index !== -1) {
    //             console.log('under if condition');
    //             array.splice(index, 1);
    //             $('#' + id).removeClass('active');
    //             setDay(array);
                

    //         } else {
    //             console.log('under else condition');
    //             array.push(daydata);
    //             $('#' + id).addClass('active');
    //             setDay(array);
               
    //         }
    //         console.log(day);
    //     } else {
    //         array.push(daydata);
    //         setDay(array);
    //         $('#' + id).addClass('active');

         
    //     }
    // }
    const handleClick = (e, daydata, id) => {
        e.preventDefault();
        const currentDayArray = [...day]; // Create a copy of the day array
    
        const index = currentDayArray.findIndex((days) => days === daydata);
    
        if (index !== -1) {
            // Day is already in the array, remove it
            currentDayArray.splice(index, 1);
            $('#' + id).removeClass('active');
        } else {
            // Day is not in the array, add it
            currentDayArray.push(daydata);
            $('#' + id).addClass('active');
        }
    
        setDay(currentDayArray); // Update the state with the new array
    }
    


    const validate = (values) => {
        const errors = {};

        // if (!values.full_time) {
        //     errors.full_time = "This field is required!";
        // }
        if (day.length == 0) {
            errors.cleaning_days = "Please select days!";
        }

        if (!values.reach_out_time) {
            errors.reach_out_time = "This field is required!";
        }
        if (!values.own_cleaning_supplies) {
            errors.own_cleaning_supplies = "This field is required!";
        }
        if (!values.own_car) {
            errors.own_car = "This field is required!";
        }
        if (!values.cancellation_policy) {
            errors.cancellation_policy = "This field is required!";
        }
     

        return errors;
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setFormErrors(validate(data23));
        setIsSubmit(true);

        if (Object.keys(errors).length == 0 && isSubmit === true && day.length > 0) {
            console.log('abc', day);


            let formData = new FormData();
            formData.append('user_id', login.user_id);
            formData.append('full_time', data23.full_time);
            formData.append('cleaning_days', JSON.stringify(day));
            formData.append('reach_out_time', data23.reach_out_time);
            formData.append('own_cleaning_supplies', data23.own_cleaning_supplies);
            formData.append('own_car', data23.own_car);
            formData.append('cancellation_policy', data23.cancellation_policy);

            let update = await dispatch(UpdateAvailibility(formData));
            if (update.payload.status == 200) {
                Swal.fire({
                    title: 'Congratulation',
                    text: update.payload.data.message,
                    icon: 'success'
                });

            } else {
                Swal.fire({
                    title: 'Error!',
                    text: update.payload.data.message,
                    icon: 'error'
                });
            }

            
        }

    }



    return (
        <>

            <div class="tab-pane fade show active" id="pills-time-slots" role="tabpanel" aria-labelledby="pills-time-slots-tab">

                <section id="booking_form" class="mt-4">
                    <div class="container">
                        <div class="row">
                            { Object.keys(data23).length > 0 &&
                                <form onSubmit={handleSubmit}>
                                    <div class="form-bg">


                                        <h2>Availability</h2>

                                        <p class="pop_gill" data-bs-toggle="modal" data-bs-target="#exampleModal">Why is
                                            availability important?</p>

                                        {/* <div class="col-12">
                                            <label for="Service" class="form-label">Are you home full time during the
                                                week?</label>
                                            <br />

                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio" class="form-check-input" name="full_time" checked={data23.full_time == "yes"} value="yes" onChange={handleChangeInput} />Yes
                                                </label>
                                            </div>

                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio" class="form-check-input" name="full_time" onChange={handleChangeInput} value="no"
                                                        checked={data23.full_time == "no"} />No
                                                </label>
                                            </div>

                                            <p className={"error__feedback"}>{errors.full_time}</p>

                                        </div> */}

                                        <div class="col-12">
                                            <label for="Service" class="form-label">What days of the week will you
                                                typically be available for Cleaning?</label>
                                            <ul class="sunday_week">

                                                <li onClick={event => handleClick(event, 'Sunday', 'day1')}  className={day.length > 0 && day.includes("Sunday") ? 'active' : ""}>Sun</li>

                                                <li onClick={event => handleClick(event, 'Monday', 'day2')} className={day.length > 0 && day.includes("Monday") && 'active'}>Mon</li>

                                                <li onClick={event => handleClick(event, 'Tuesday', 'day3')}  className={day.length > 0 && day.includes("Tuesday") && 'active'}>Tue</li>

                                                <li onClick={event => handleClick(event, 'Wednesday', 'day4')} className={day.length > 0 && day.includes("Wednesday") && 'active'}>Wed</li>

                                                <li onClick={event => handleClick(event, 'Thursday', 'day5')} className={day.length > 0 && day.includes("Thursday") && 'active'}>Thu</li>

                                                <li onClick={event => handleClick(event, 'Friday', 'day6')} className={day.length > 0 && day.includes("Friday") && 'active'}>Fri</li>

                                                <li onClick={event => handleClick(event, 'Saturday', 'day7')} className={day.length > 0 && day.includes("Saturday") && 'active'}>Sat</li>
                                            </ul>

                                            <p className={"error__feedback"}>{errors.cleaning_days}</p>
                                        </div>

                                        <label for="Service" class="form-label">How far in advance do you need new
                                            clients to reach out to you before a booking?</label>
                                        <br />

                                        <select className='form-control' onChange={handleChangeInput} name="reach_out_time">

                                            {PriorDays.length > 0 && PriorDays.map((a, i) => (
                                                <option selected={data23.reach_out_time == a.name} value={a.name}>{a.name}</option>
                                            ))}
                                        </select>

                                        <p className={"error__feedback"}>{errors.reach_out_time}</p>

                                        <div class="col-12">
                                            <label for="Service" class="form-label">Are you able to provide your own
                                                cleaning supplies:</label>
                                            <br />

                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio" class="form-check-input" onChange={handleChangeInput} value="yes" name="own_cleaning_supplies" checked={data23.own_cleaning_supplies == "yes"} />Yes
                                                </label>
                                            </div>

                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio" class="form-check-input" value="no" name="own_cleaning_supplies" onChange={handleChangeInput} 
                                                        checked={data23.own_cleaning_supplies === "no"} />No
                                                </label>
                                            </div>

                                            <p className={"error__feedback"}>{errors.own_cleaning_supplies}</p>

                                        </div>

                                        <div class="col-12">
                                            <label for="Service" class="form-label">Do you have your own car:</label>
                                            <br />

                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio" onChange={handleChangeInput} value="yes" class="form-check-input" name="own_car" checked={data23.own_car === "yes"} />Yes
                                                </label>
                                            </div>

                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio"  onChange={handleChangeInput} value="no" class="form-check-input" name="own_car"
                                                        checked={data23.own_car === "no"} />No
                                                </label>
                                            </div>

                                            <p className={"error__feedback"}>{errors.own_car}</p>

                                        </div>

                                        <h2>Cancellation Policy</h2>

                                        <p class="pop_gill" data-bs-toggle="modal" data-bs-target="#exampleModal1">What
                                            do these mean?</p>

                                        <label for="Service" class="form-label">What is your cancellation policy for
                                            Cleaning?</label>

                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input" onChange={handleChangeInput} value="same day" name="cancellation_policy" checked={data23.cancellation_policy === "same day"} />Same Day
                                            </label>
                                        </div>

                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input" name="cancellation_policy" onChange={handleChangeInput} value="one day"
                                                    checked={data23.cancellation_policy === "one day"} />One Day
                                            </label>
                                        </div>

                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input" name="cancellation_policy" onChange={handleChangeInput} value="three day" checked={data23.cancellation_policy === "three day"} />Three Day
                                            </label>
                                        </div>

                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input" name="cancellation_policy" value="seven day"
                                                    checked={data23.cancellation_policy === "seven day"}  onChange={handleChangeInput}  />Seven Day
                                            </label>
                                        </div>

                                        <p className={"error__feedback"}>{errors.cancellation_policy}</p>



                                        <div class="row">

                                            <div class="col-12">

                                        
                                            <div class="text-center">
                                            <button type="submit">Update</button>
                                                {/* <a  class="btn profilee" type="submit"> Save</a> */}
                                            </div>

                                        </div>

                                        </div>

                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                </section>


            </div>
        </>
    )
}