
import React, { useContext, useState } from "react";
import MultiStepFormContext from "./MultiStepFormContext";
import { useDispatch, useSelector } from "react-redux";
import { saveGall } from "../../../Reducer/providerForms";
export const Gallery = (props) => {
  const { gallery, setFiles } = useContext(MultiStepFormContext);
  const [error, setError] = useState('');
  const [base64, setBase64] = useState([]);
  const dispatch = useDispatch();
  const glData = useSelector(state => state.forms.gallry);

  // if(glData && glData.length > 0){
  //   console.log('gggggggggggggggggggggggggggggggggggggg', glData);
  //   setFiles(glData);
  // }

  const selectFile = async (event) => {
    const file = event.target.files[0];
    const base64data = await convertBase64(file);
    console.log('fileeeeee', file); 
    console.log('baseee', base64data);
    setFiles([...gallery, file]);
    setBase64([...base64, base64data]);
  }

  function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const handleContinue = () => {
    // Check if at least one image is uploaded
    if (gallery.length === 0) {
      setError("Please upload at least one image.");
    } else {
      setError(""); // Clear the error message if there is at least one image

      console.log('test gallry', gallery);
      dispatch(saveGall(gallery));
      props.onClick();
    }
  };

  console.log("gallery", gallery);
  return (<div class="row">
    <form>
      <div class="form-bg">
        <div class="gray_box_family"><p>Add photos of yourself with cleaning supplies and
          equipment to show the service you give to them.
          We suggest 5-10 of your best photos.</p></div>

        <div class="row mt-4">
          {glData.length > 0 && glData.map((item, i) => (
            <div class="col-md-3 col-sm-4 col-6">
              <div class="add_img_uplod">
                <img src={URL.createObjectURL(item)} alt="" />
              </div>
            </div>
          ))}

          {base64.map((item, key) => {
            return (
              <div class="col-md-3 col-sm-4 col-6">
                <div class="add_img_uplod">
                  <img src={item} alt="" />
                </div>
              </div>)
          })}


          <div class="col-md-3 col-sm-4 col-6">
            <div class="add_img_uplod">
              <input type="file" onChange={selectFile} />
              <h1>
                <i class="bi bi-plus"></i>
                <p>Add More</p>
              </h1>

            </div>
          </div>
          {/* {error} */}
          {error && <div className="text-danger">{error}</div>}

        </div>
        <div class="row">
          <div class="col-12">
            <div class="text-center">
              <a onClick={handleContinue} className="btn profilee">
                Continue
              </a>
              {/* <a onClick={()=>{props.onClick();}} class="btn profilee"> Continue</a> */}
            </div>
          </div>
        </div>

      </div>
    </form>
  </div>
  );
};