import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useParams, useLocation } from "react-router-dom";
import MyBackgroundImage from '../Data/booking-bg.png';
import { BookingRequest, CheckApi, CleanerDetail, testPayment } from "../../../Reducer/bookingSlice";
import Swal from 'sweetalert2';
import moment from 'moment';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
// import { PayPalButton } from 'react-paypal-button-v2';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-google-places-autocomplete';
import Loader from "../Loader";
import axios from 'axios';


export default function SingleProfile() {

    const dispatch = useDispatch();
    const params = useParams();
    const [clientId, setCliendId] = useState("AW-nQQrYnhH6H-fxUVWvgiKaqJ5XgiuHmadr1YJuRqV-LskXXd-_d-vOzslDWYv5h6Kprw05sPmxfJU2");

    const [adOnPricee, setadOnPricee] = useState(0);
    const [testTime, settestTime] = useState({});
    const [hoursPrice, setHoursprice] = useState(0);
    const [allTotal, setAllTotal] = useState(0);
    const [durationPrice, setDurationPrice] = useState(0);

    // const [data, setData] = useState({});
    const [price, setPrice] = useState(0);
    const [total, setTotal] = useState(0);
    const [fee, setFee] = useState(0);

    const [addOns, setAddOn] = useState([]);
    const [types, setTypes] = useState([]);
    const [addOnId, setAddonId] = useState([]);
    const img_url = "https://admin.broomyy.com/public/storage/profile_images/";
    const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
    const [sid, setSid] = useState(0);
    const [errors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [open, setOpen] = useState(false);
    const login = useSelector(state => state.auth.auth);
    const [payid, setPaymentid] = useState(null);

    const [searchData, setSearchData] = useState(JSON.parse(localStorage.getItem('searchData')));
    const data = useSelector(state => state.booking.data);
    const commission = useSelector(state => state.booking.commission);
    const [loc, setLoc] = useState(null);
    const [loading, setLoading] = useState(false);


    const [opneDiv, setDiv] = useState({});
    const [addCount, setAddCount] = useState(0);

    const highlightedDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const today = new Date().toISOString().slice(0, 10);
    const [booking, setBookngForm] = useState({
        service: "Select",
        service_date: "",
        start_time: "",
        end_time: "",
        postal_code: "",
        type: "Select",
        address: "",
        latitude: "",
        longitude: "",
        size: "",
        bedroom_count: "",
        bathroom_count: "",
        pet: "",
        need_photos: "",
        addOn: [],
        phone: login.number,
        message: "",
        repeat: "One Time",
        repeat_Type: "",

    });

    const [accessToken, setAccessToken] = useState(null);



    useEffect(() => {
        // calculatePrice(40, 5, 0);
        // console.log('searchData', searchData);
        let dataa = { user_id: params.id };
        dispatch(CleanerDetail(dataa));
        setLoc({
            value: "",
            label: "Type address"
        });
        if (searchData && Object.keys(searchData).length > 0) {
            setBookngForm((prevBooking) => ({
                ...prevBooking,
                service: searchData.search,
            }));

            if (data && data.services && data.services.length > 0) {
                const foundService = data.services.findIndex((service) => service.name === searchData.search);
                if (foundService !== -1) {
                    setAddOn(data.services[foundService].addOns);
                    setTypes(data.services[foundService].typesArry);
                    setSid(data.services[foundService].id);
                }
            }

            if (searchData.type !== "") {
                // Set the selected type based on searchData.type
                if (types.length > 0) {
                    const foundTypeIndex = types.findIndex((type) => type.name === searchData.type);
                    if (foundTypeIndex !== -1) {
                        setBookngForm((prevBooking) => ({
                            ...prevBooking,
                            type: searchData.type,
                        }));

                        setPrice(types[foundTypeIndex].price);
                    }
                }

            }
            if (searchData.date !== "") {
                setBookngForm((prevBooking) => ({
                    ...prevBooking,
                    service_date: searchData.date,
                }));
            }

            if (
                searchData.latitude !== null &&
                searchData.longitude !== null &&
                searchData.place !== undefined &&
                searchData.place !== ""
            ) {
                setBookngForm((prevBooking) => ({
                    ...prevBooking,
                    address: searchData.place,
                    latitude: searchData.latitude,
                    longitude: searchData.longitude,
                }));

                setLoc({
                    value: searchData.place,
                    label: searchData.place
                });
            }
        };
        fetchAccessToken();
    }, [dispatch]);


    function selectService(event) {
        setOpen(false);
        setPrice(0);
        setAddOn(data.services[event.target.value].addOns);
        setTypes(data.services[event.target.value].typesArry);
        const value = data.services[event.target.value].name;
        const id = data.services[event.target.value].id;

        setSid(id);
        setBookngForm({
            ...booking,
            [event.target.name]: value,
            ['service']: ""
        });

    }

    function handleChangeInput(evt) {
        setOpen(false);
        const value = evt.target.value;
        if (evt.target.name === "type") {
            setBookngForm({
                ...booking,
                [evt.target.name]: types[evt.target.value].name
            });
            setPrice(types[evt.target.value].price);
        } else {
            setBookngForm({
                ...booking,
                [evt.target.name]: value
            });
        }
    }


    function calculateTimeDifference(startTime, endTime) {
        // Convert start and end times to Date objects
        const startDate = new Date(`1970-01-01T${booking.start_time}`);
        const endDate = new Date(`1970-01-01T${booking.end_time}`);

        // Calculate the difference in milliseconds
        const timeDifference = endDate - startDate;

        // Calculate hours and minutes
        const hours = Math.floor(timeDifference / (60 * 60 * 1000));
        const minutes = Math.floor((timeDifference % (60 * 60 * 1000)) / (60 * 1000));

        return { hours, minutes };
    }

    function calculatePrice(pricePerHour, hours, minutes) {
        // Convert the duration to minutes
        const totalMinutes = hours * 60 + minutes;

        // Calculate the total hours
        const totalHours = totalMinutes / 60;

        // Calculate the total cost
        const totalPrice = totalHours * pricePerHour;


        return totalPrice;
    }


    async function handleSubmit(e) {
        e.preventDefault();
        console.log('addon', adOnPricee, addOnId);
        const errors = validate(booking);
        if (Object.keys(errors).length === 0) {

            let test = calculateTimeDifference(booking.start_time, booking.end_time);
            if (test) {
                settestTime(test);
                let calPrice = calculatePrice(price, testTime.hours, testTime.minutes);
                if (calPrice) {
                    setHoursprice(calPrice);

                    setDurationPrice(parseInt(calPrice));

                    let TotalOfAll = 0;
                    TotalOfAll = parseInt(adOnPricee) + parseInt(calPrice);
                    setAllTotal(TotalOfAll);

                    let pay = 0;
                    if (commission && commission.type === "Percentage") {
                        let pay2 = TotalOfAll * commission.amount / 100;

                        setFee(pay2);

                        pay = parseInt(pay2) + parseInt(TotalOfAll);
                        setTotal(pay);

                        setFormErrors({});
                        setIsSubmit(true);
                        setOpen(true);

                    }
                    if (commission && commission.type === "Amount") {

                        setFee(commission.amount);
                        pay = parseInt(commission.amount) + parseInt(TotalOfAll);
                        setTotal(pay);

                        setFormErrors({});
                        setIsSubmit(true);
                        setOpen(true);

                    }
                }
            }
        } else {
            // There are errors, do not proceed with form submission
            setFormErrors(errors);
            setIsSubmit(false);
            setOpen(false); // You may want to set this to false if there are errors
        }
    }

    function setAddCountFc(e) {
        setAddCount(e.target.value);
    }

    function addCountAddOns(id, add_price, name) {

        let data = {
            id: id,
            price: add_price,
            name: name,
            count: addCount
        };

        // setAddonId([...addOnId, data]);

        let index = addOnId.findIndex((a) => a.id === id);

        if (index > -1) {
            let existingAddOn = addOnId[index];
            let subtractedPrice = parseInt(existingAddOn.price) * parseInt(existingAddOn.count);
            let newTotalPrice = parseInt(adOnPricee) - subtractedPrice;
            setadOnPricee(newTotalPrice);

            setAddonId(addOnId.filter((item, i) => i !== index));
            setDiv(-1);
        } else {
            let pp = parseInt(add_price) * parseInt(addCount);
            let newTotalPrice = parseInt(adOnPricee) + pp;
            setadOnPricee(newTotalPrice);

            setAddonId([...addOnId, data]);
            setDiv(-1);
        }

        // console.log('addcon pricesss', adOnPricee);
    }


    function addIds(e, id, add_price, index) {
        setAddCount(0);
        setOpen(false);
        if (e.target.checked === true) {
            setDiv(index); // Open the additional div if checkbox is checked
        } else if (e.target.checked === false) {
            let index = addOnId.findIndex((a) => a.id === id);
            if (index > -1) {
                let existingAddOn = addOnId[index];
                let subtractedPrice = parseInt(existingAddOn.price) * parseInt(existingAddOn.count);
                let newTotalPrice = parseInt(adOnPricee) - subtractedPrice;
                setadOnPricee(newTotalPrice);
                setAddonId(addOnId.filter((item, i) => i !== index));
            }

            setDiv(-1);
        } else {
            setDiv(-1); // Close the additional div if checkbox is unchecked
        }



    }


    const validate = (values) => {
        const errors = {};

        if (!values.service && values.service === "Select") {
            errors.service = "This field is required!";
        }
        if (!values.service_date) {
            errors.service_date = "Please select date!";
        }
        if (!values.start_time) {
            errors.start_time = "This field is required!";
        }
        if (!values.end_time) {
            errors.end_time = "This field is required!";
        }
        if (!values.postal_code) {
            errors.postal_code = "This field is required!";
        }
        if (!values.address) {
            errors.address = "This field is required!";
        }
        if (!values.size) {
            errors.size = "This field is required!";
        }
        if (!values.bedroom_count) {
            errors.bedroom_count = "This field is required!";
        }
        if (!values.bathroom_count) {
            errors.bathroom_count = "This field is required!";
        }
        if (!values.bathroom_count) {
            errors.bathroom_count = "This field is required!";
        }
        if (!values.pet) {
            errors.pet = "This field is required!";
        }
        if (!values.need_photos) {
            errors.need_photos = "This field is required!";
        }
        if (!values.phone) {
            errors.phone = "This field is required!";
        }
        if (!values.address) {
            errors.repeat = "This field is required!";
        }
        // if (!values.payal) {
        //     errors.payal = "This field is required!";
        // }


        return errors;
    }


    const fetchAccessToken = async () => {
        try {
            const response = await axios.post(
                'https://api.sandbox.paypal.com/v1/oauth2/token',
                null,
                {
                    params: {
                        grant_type: 'client_credentials'
                    },
                    auth: {
                        username: 'AW-nQQrYnhH6H-fxUVWvgiKaqJ5XgiuHmadr1YJuRqV-LskXXd-_d-vOzslDWYv5h6Kprw05sPmxfJU2',
                        password: 'ELOjGQgcVFBpfqbDPFNLDT6SWPI0ZsSwWn-YuIrX9RgcUlCwtUk4lK06kfM_tPtg9Xu3JW5m8uvkTHTy'
                    }
                }
            );
            setAccessToken(response.data.access_token);
        } catch (error) {
            console.error('Error fetching access token:', error);
        }
    };


    const createOrder = (data, actions) => {
        return actions.order.create({
            intent: "AUTHORIZE",
            purchase_units: [
                {
                    amount: {
                        value: total,
                        currency_code: "USD",
                    },
                },
            ],
            application_context: {
                user_action: "PAY_NOW",
                scopes: "openid profile email",
            },
            returnUrl: 'http://localhost:3000/BookingForm/' + params.id,
            cancelUrl: 'http://localhost:3000/BookingForm/' + params.id,
        }).then((response) => {
            console.log('Order creation response:', response);
            return response;
        }).catch((error) => {
            console.error('Error creating order:', error);
            throw error;
        });
    };



    // const onApprove = async (details) => {
    const onApprove = async (data, actions, accessToken) => {


        console.log('data data', data, accessToken);
        // Use the access token in your API request
        const approvalUrl = await getApprovalURL(data.orderID, accessToken);
        console.log('approvalUrl afteeeeee getApprivalFc', approvalUrl);

        if (approvalUrl === true) {
            payment(data);
            // console.log('approvalUrl22', approvalUrl);
            // Redirect the user to the approval URL for finalizing the payment
            // window.location.href = approvalUrl;
        } else {
            console.error("Approval URL not found.");
        }

        // await getApprovalURL(details.orderID, details.facilitatorAccessToken)
        //     .then((approvalUrl) => {

        //         console.log('approvalUrl', approvalUrl);
        //         if (approvalUrl) {

        //             console.log('approvalUrl22', approvalUrl);
        //             // Redirect the user to the approval URL for finalizing the payment
        //             // window.location.href = approvalUrl;
        //         } else {
        //             console.error("Approval URL not found.");
        //         }
        //     });

        // payment(details);

    };

    async function getApprovalURL(order, token) {
        // alert('tetstttt');
        console.log('order id', order, token);
        // try {
        const response = await axios.get(`https://api.sandbox.paypal.com/v2/checkout/orders/${order}`, {
            headers: {
                'Authorization': `Bearer ${token}` // Replace with your access token
            }
        });


        if (response) {
            console.log('response response response response response', response.data);
            // const data = await response;

            console.log('data links', data);

            if (response.data.status === "APPROVED") {

                return true;
            }

            // if (data.links && data.links.length > 0) {
            //     const approvalLink = data.links.find((link) => link.rel === "approve");
            //     console.log('data.links------------------>', data.links);
            //     if (approvalLink) {
            //         return approvalLink.href; // This is the approval URL
            //     }
            // }
        } else {
            return false;
        }

        // if (!response.ok) {
        //     return false;
        //     // throw new Error(`HTTP error! Status: ${response.status}`);
        // }


        // Approval URL not found
        // } catch (error) {
        //     console.error("Error getting approval URL:", error);
        //     return null;
        // }
    }



    const onError = (error) => {
        Swal.fire({
            title: 'Error!',
            text: error,
            icon: 'error'
        });
    }

    // async function payment(details) {
    // console.log('payment details in payment function', details);
    console.log('errors', errors,);
    console.log('isSubmit', isSubmit,);
    async function payment() {
        if (Object.keys(errors).length === 0 && isSubmit === true) {
            // setOpen(true);
            let formData = new FormData();
            formData.append('cleaner_id', params.id);
            formData.append('user_id', login.user_id);
            formData.append('service_id', sid);
            formData.append('start_time', moment(booking.start_time, 'hh:mm').format('hh:mm A'));
            formData.append('end_time', moment(booking.end_time, 'hh:mm').format('hh:mm A'));
            formData.append('date', booking.service_date);
            formData.append('service_type', booking.type);
            formData.append('postal_code', booking.postal_code);
            formData.append('address', booking.address);
            formData.append('latitude', booking.latitude);
            formData.append('longitude', booking.longitude);
            formData.append('size', booking.size);
            formData.append('bedroom_count', booking.bedroom_count);
            formData.append('bathroom_count', booking.bathroom_count);
            formData.append('need_photos', booking.need_photos);
            formData.append('phone', booking.phone);
            formData.append('message', booking.message);
            formData.append('pet', booking.pet);
            formData.append('addOns', JSON.stringify(addOnId));
            formData.append('finalPrice', total);
            formData.append('processing_fee', fee);
            formData.append('main_price', price);
            formData.append('repeat', booking.repeat);
            formData.append('repeatType', booking.repeat_Type);
            formData.append('paymentID', "");
            formData.append('orderID', "");
            formData.append('payerID', "");

            // formData.append('paymentID', details.paymentID);
            // formData.append('orderID', details.orderID);
            // formData.append('payerID', details.payerID);
            let update = await dispatch(BookingRequest(formData));
            setLoading(true);
            console.log('abcccc', update);
            if (update.payload.status === "Success") {
                setLoading(false);
                Swal.fire({
                    title: 'Congratulation',
                    text: update.payload.message,
                    icon: 'success'
                });

                setOpen(false);
                setPrice(0);
                setLoc({
                    value: "",
                    label: "Type address"
                });
                let dataa = { user_id: params.id };
                dispatch(CleanerDetail(dataa));
            } else {
                setLoading(false);
                Swal.fire({
                    title: 'Error!',
                    text: update.payload.data.message,
                    icon: 'error'
                });
            }

        }

    }

    const handleSelect = async (value) => {
        try {
            const results = await geocodeByAddress(value.label);
            const latLng = await getLatLng(results[0]);

            console.log('rsultsss', value);

            setBookngForm((prevBooking) => ({
                ...prevBooking,
                latitude: latLng.lat,
                longitude: latLng.lng,
                address: value.label
            }));
            setLoc({
                value: value.label,
                label: value.label
            });
        } catch (error) {
            console.error('Error selecting place: ', error);
        }
    };





    return (

        <div>
            <section style={{ backgroundImage: `url(${MyBackgroundImage})` }} id="booking_sec">
                <div class="container">
                    <div class="row">
                        <h2>Booking Form</h2>

                    </div>
                </div>
            </section>


            <section id="booking_form">
                <div class="container">

                    {data && Object.keys(data).length > 0 &&
                        <div class="row">
                            <form onSubmit={handleSubmit} id="bookingForm">

                                <div class="form-bg">

                                    <>
                                        <div class="d-flex">
                                            <div class="book-img" style={{ backgroundImage: `url(${img_url + data.image})` }}>


                                            </div>

                                            <h4>{data.username}</h4>
                                        </div>

                                        <label for="Service" class="form-label">Select Service</label>

                                        <div class="row">

                                            <div class="col-md-10 col-sm-9">

                                                <select onChange={selectService} class="form-select" name="service">

                                                    <option selected disabled>Select</option>
                                                    {data.services && data.services.length > 0 && data.services.map((a, i) => (
                                                        <option selected={booking.service && booking.service === a.name} value={i}>{a.name}</option>

                                                    ))}
                                                </select>

                                                <p className={"error__feedback"}>{errors.service}</p>

                                            </div>

                                            <div class="col-md-2 col-sm-3">
                                                <div class="flex-img-sec">
                                                    <h6>${price} <br /><span>per hour</span></h6>
                                                </div>
                                            </div>

                                        </div>

                                        {types && types.length > 0 &&
                                            <div class="col-sm-12">
                                                <label for="Service" class="form-label">Service Type:</label>
                                                <select class="form-select" name="type" aria-label="Default select example" onChange={handleChangeInput}>

                                                    <option disabled selected >Select
                                                    </option>
                                                    {types.map((ser, i) => (

                                                        <option value={i} selected={booking.type && booking.type === ser.name} >
                                                            {ser.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        }

                                        <label for="Service" class="form-label">What Date?</label>
                                        <input class="service-input" type="date" name="service_date" onChange={handleChangeInput} max="9999-12-31" min={today} />
                                        <p className={"error__feedback"}>{errors.service_date}</p>

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label for="Service" class="form-label">Start Time</label>
                                                <input class="service-input" type="time" name="start_time" value={booking.start_time} onChange={handleChangeInput} />

                                                <p className={"error__feedback"}>{errors.start_time}</p>
                                            </div>

                                            <div class="col-sm-6">
                                                <label for="Service" class="form-label">End Time</label>
                                                <input class="service-input" type="time" name="end_time" value={booking.end_time} onChange={handleChangeInput} />

                                                <p className={"error__feedback"}>{errors.start_time}</p>

                                            </div>

                                        </div>
                                        <label class="form-label">How often do you need this service:</label>
                                        <div class="row">
                                            <div class="col-sm-6">

                                                <div class="form-check form-check-inline home mb-2">
                                                    <input class="form-check-input" type="radio" name="repeat" value="One Time"
                                                        id="exampleRadios3" onChange={handleChangeInput} />
                                                    <label class="form-check-label" for="exampleRadios3">
                                                        One Time Cleaning
                                                    </label>
                                                </div>

                                            </div>

                                            <div class="col-sm-6">


                                                <div class="form-check form-check-inline home mb-2">
                                                    <input class="form-check-input" type="radio" name="repeat" value="Multiple" onChange={handleChangeInput}
                                                        id="exampleRadios4" />
                                                    <label class="form-check-label" for="exampleRadios4">
                                                        Repeat Cleaning(Regular)
                                                    </label>
                                                </div>

                                            </div>

                                            {booking.repeat === "Multiple" &&

                                                <div class="home-check-box">

                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" id="chk_red" value="Weekly" name="repeat_Type" onChange={handleChangeInput} />
                                                        <label class="form-check-label" for="chk_red">Weekly</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" id="chk_green" value="By-Weekly" name="repeat_Type" onChange={handleChangeInput} />
                                                        <label class="form-check-label" for="chk_green">By-Weekly</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" id="chk_blue" value="Monthly" name="repeat_Type" onChange={handleChangeInput} />
                                                        <label class="form-check-label" for="chk_blue">Monthly</label>
                                                    </div>

                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" id="chk_white" value="After each tenant" name="repeat_Type" onChange={handleChangeInput} />
                                                        <label class="form-check-label" for="chk_white">After each tenant</label>
                                                    </div>

                                                </div>
                                            }



                                        </div>

                                        <h2>Property Details:</h2>

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label for="Service" class="form-label">Postal Code</label>
                                                <input class="service-input" type="text" placeholder="postal code" name="postal_code" value={booking.postal_code} onChange={handleChangeInput} />

                                                <p className={"error__feedback"}>{errors.postal_code}</p>
                                            </div>

                                            <div className="col-sm-6">
                                                <label for="Service" class="form-label">Address</label>

                                                <PlacesAutocomplete
                                                    selectProps={{
                                                        // location,
                                                        value: loc,
                                                        onChange: handleSelect,
                                                    }}
                                                    query={{ key: "AIzaSyA4mFVw62S7LCfcIGBHrqeG6k9Ty_NCuJw" }}
                                                    fetchDetails={true}
                                                    onFail={error => console.log(error)}
                                                />
                                                <input class="service-input" type="hidden" placeholder="enter complete address" name="address" value={booking.address} />

                                                <p className={"error__feedback"}>{errors.address}</p>
                                            </div>

                                        </div>



                                        <label for="Service" class="form-label">Size/Sqft</label>

                                        <input class="service-input" type="text" placeholder="Enter Size" name="size" value={booking.size} onChange={handleChangeInput} />

                                        <p className={"error__feedback"}>{errors.size}</p>

                                        <label for="Service" class="form-label">Bedrooms</label>
                                        <input class="service-input" type="text" placeholder="Enter number of bedroom" name="bedroom_count" value={booking.bedroom_count} onChange={handleChangeInput} />

                                        <p className={"error__feedback"}>{errors.bedroom_count}</p>

                                        <label for="Service" class="form-label">Bathrooms</label>
                                        <input class="service-input" type="text" placeholder="Enter number of bathrooms" name="bathroom_count" value={booking.bathroom_count} onChange={handleChangeInput} />
                                        <p className={"error__feedback"}>{errors.bathroom_count}</p>


                                        <label for="Service" class="form-label">Do you have pet?:

                                            <div class="form-check-inline ms-4">
                                                <label class="form-check-label">
                                                    <input type="radio" class="form-check-input" name="pet" value="Yes" onChange={handleChangeInput} checked={booking.pet == "Yes"} />Yes
                                                </label>
                                            </div>

                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio" class="form-check-input" name="pet" value="No" onChange={handleChangeInput} checked={booking.pet == "No"} />No
                                                </label>
                                            </div>

                                            <p className={"error__feedback"}>{errors.pet}</p>

                                        </label>

                                        <div class="col-12">
                                            <label for="Service" class="form-label">Do you need photos of the property after
                                                cleaning?</label>
                                            <br />


                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio" class="form-check-input" name="need_photos" value="Yes" onChange={handleChangeInput} checked={booking.need_photos == "Yes"} />Yes
                                                </label>
                                            </div>

                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio" class="form-check-input" name="need_photos" value="No" onChange={handleChangeInput} checked={booking.need_photos == "No"} />No
                                                </label>
                                            </div>
                                            <p className={"error__feedback"}>{errors.need_photos}</p>

                                        </div>

                                        {addOns && addOns.length > 0 &&
                                            <>

                                                <label for="Service" class="form-label">Additional Request</label>

                                                {addOns.map((add, i) => (
                                                    <>

                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="addOn" value={add.id} id="flexCheckDefault" onChange={(e) => addIds(e, add.id, add.add_price, i)} />
                                                            <label class="form-check-label" for="flexCheckDefault">
                                                                {add.name} <span class="req_span">${add.add_price} per item</span>
                                                            </label>
                                                        </div>

                                                        {opneDiv === i &&

                                                            <div class="add_class">
                                                                <input class="form-control" type="number" placeholder="No of items" onChange={setAddCountFc} />
                                                                <span onClick={() => addCountAddOns(add.id, add.add_price, add.name)}>Save</span>
                                                            </div >
                                                        }
                                                    </>

                                                ))}

                                            </>}

                                        <label for="Service" class="form-label">Add your phone number</label>
                                        <p>Broomy requires a verified phone number to secure you account.</p>
                                        <input class="service-input" type="text" placeholder="Enter your phone number " name="phone" value={booking.phone} onChange={handleChangeInput} />
                                        <p className={"error__feedback"}>{errors.phone}</p>


                                        <label for="Service" class="form-label">Message</label>
                                        <textarea name="message" value={booking.message} onChange={handleChangeInput}></textarea>


                                        <button class="clen-btn-book" type="submit">Preview</button>
                                    </>
                                    {/* } */}

                                    {open === true &&


                                        <>

                                            <div class="lawaris">
                                                <h6>Charges details: </h6>
                                                <p>Service amount($/hr): <span>{price}</span></p>
                                                <p>Duration of Service : <span>{testTime.hours} hour {testTime.minutes} minutes</span></p>
                                                <p>Total amount($) : <span>{durationPrice} </span></p>
                                                <p>Additional Services($) :<span>{adOnPricee}</span> </p>
                                                {addOnId.length > 0 &&
                                                    <ul>
                                                        {addOnId.map((item, i) => (
                                                            <li>{item.name}, {item.price} * {item.count}</li>
                                                        ))}
                                                    </ul>
                                                }
                                                <p>Processing fees : <span>{fee}</span></p>
                                                <p>Grand total($) :<span>{total}</span> </p>

                                            </div>

                                            <button onClick={payment}>Book Now And Pay Later</button>


                                            {/* <PayPalScriptProvider options={{
                                                clientId: clientId,
                                                intent: "authorize"
                                            }}>
                                                <PayPalButtons

                                                    shippingPreference="NO_SHIPPING"

                                                    style={{ layout: "horizontal" }}
                                                    createOrder={createOrder}
                                                    onApprove={(data, actions) => onApprove(data, actions, accessToken)}

                                                    onError={onError}



                                                />
                                            </PayPalScriptProvider> */}
                                        </>

                                    }


                                </div>
                            </form>



                            {/* <button class="btn" onClick={createPayment}>Pay</button> */}




                        </div>
                    }






                </div >

            </section >
            {loading === true &&
                <Loader status={loading}></Loader>
            }
        </div >



    )

}


// onInit={alert("testing")}
// onInit={(data, actions) => {
//     actions.request.post('/YOUR_ORDER_CREATION_ENDPOINT', {
//         headers: {
//             Authorization: `Bearer ${accessToken}`
//         }
//     }).then((response) => {
//         console.log('Order creation response:', response);
//         //   setOrderID(response.data.id);
//     }).catch((error) => {
//         console.error('Error creating order:', error);
//     });
// }}
