import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CancelBooking, GetBookings } from '../../../../../Reducer/userSlice';
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';
import ChatPopUp from '../../../ChatPopUp';


export default function Pending(props) {

    const dispatch = useDispatch();
    const login = useSelector(state => state.auth.auth);
    const [chat, setChatbot] = useState(false);
    const [providers, setProvider] = useState({});
    const [open, setOpen] = useState(false);
    const [reason, setReason] = useState("");
    const [det, setdetails] = useState({});

    // const req = useSelector(state => state..auth);

    const columns = [
        {
            name: 'User Name',
            selector: 'username',
            sortable: true,
        },
        {
            name: 'Service',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Address',
            selector: 'address_1',
            sortable: true,
        },
        {
            name: 'Number',
            selector: 'number',
        },
        {
            name: 'Price',
            selector: 'finalPrice',
            cell: (row) => `$ ${row.finalPrice}`,
        },
        {
            name: 'Booking Date',
            selector: 'booking_date',
        },
        {
            name: 'Action',
            button: true,
            cell: (record) => {
                return (<><a className="btn fff" onClick={() => cancel(record.id, record.service_id, record.booking_date)}>Cancel</a> <a className='btn fff' onClick={() => OpenChatBot(record.id, record.username, record.image)}>Chat</a> </>
                )

            }
        },


    ];
    const receiveResponseFromChild = (response) => {
        setChatbot(response);
    };


    useEffect(() => {

        // getSentRequests();
    }, [login.user_id]);


    // async function getSentRequests() {
    //     let uid = { user_id: login.user_id, type: "pending" };

    //     let response = await dispatch(GetBookings(uid));

    //     console.log('resssps', response);
    //     if (response.payload.status === 200) {
    //         setRequests(response.payload.data.data);
    //     }
    // }
    function OpenChatBot(id, name, image) {

        setProvider({ username: name, id: id, image: image });
        setChatbot(true);
    }

    function cancelReason(e){
        setdetails({
            ...det, 
            ['reason'] : e.target.value
        })
      
    }

    function closePop(){
        setOpen(false);
    }

    function cancel(id, sid, date) {
        setdetails({ user_id: login.user_id, cleaner_id: id, service_id: sid, date: date });
        setOpen(true);
       
    }

    async function cancelApprove(){
        console.log('det', det);

      let response = await dispatch(CancelBooking(det));

        console.log('ressss', response);

        if (response.payload.status == 200) {
            setOpen(false);
            Swal.fire({
                title: 'Congratulation',
                text: response.payload.data.message,
                icon: 'success'
            });

            // getSentRequests();
        } else {
            Swal.fire({
                title: 'Error!',
                text: response.payload.data.message,
                icon: 'error'
            });
        }
    }

    return (
        <div class="tab-pane fade show active" id="pills-pending" role="tabpanel"
            aria-labelledby="pills-pending-tab">


            <div class="active_list_inner">

                <div class="table-responsive">

                    {props && props.data && props.data.length > 0 ? (
                        <DataTableExtensions
                            columns={columns}
                            data={props.data}
                            print={false}
                            export={false}
                        >
                            <DataTable
                                columns={columns}
                                data={props.data}
                                fixedHeader
                                pagination
                                highlightOnHover
                            />
                        </DataTableExtensions>
                    ) : (
                        <p>No data available</p>
                    )}

                    {open == true &&
                        <div class="rate">
                            <h5><i class="bi bi-x-square" onClick={closePop}></i></h5>
                         
                            <label>Add Cancellation Reason</label>
                            <input type="text" name="review" onChange={cancelReason}/>

                            {/* value={reason} onChange={cancelReason} */}
                            <a class="btn" onClick={cancelApprove}>Add</a>

                        </div>

                    }


                </div>

            </div>

            {chat && chat == true &&
                <div>
                    <ChatPopUp data={providers} sendResponseToParent={receiveResponseFromChild}></ChatPopUp>
                    {/*  */}
                </div>
            }

        </div>

    )

}