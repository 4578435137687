import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { GetBookings } from '../../../../../Reducer/userSlice';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';


export default function Cancelled(props) {

    const dispatch = useDispatch();
    const login = useSelector(state => state.auth.auth);
    // const [cancel, setCancelled] = useState([]);

    const columns = [
        {
            name: 'User Name',
            selector: 'username',
            sortable: true,
        },
        {
            name: 'Service',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Address',
            selector: 'address_1',
            sortable: true,
        },
        {
            name: 'Number',
            selector: 'number',
        },
        {
            name: 'Price',
            selector: 'finalPrice',
            cell: (row) => `$ ${row.finalPrice}`, 
        },
        {
            name: 'Booking Date',
            selector: 'booking_date',
        },
        {
            name: 'Cancelled By',
            selector: 'Cancelled_by',

        },
        {
            name: 'Reason',
            selector: 'cancel_reason',
            wrap: true
        },


    ];


    useEffect(() => {

        // getCancelledBookings();
    }, [login.user_id]);


    // async function getCancelledBookings() {
    //     let uid = { user_id:login.user_id,  type: "cancelled" };

    //     let response = await dispatch(GetBookings(uid));

    //     console.log('resssps', response);
    //     if (response.payload.status === 200) {
    //         setCancelled(response.payload.data.data);
    //     }
    // }


    return (
        <div class="tab-pane fade show active" id="pills-pending" role="tabpanel"
            aria-labelledby="pills-pending-tab">


            <div class="active_list_inner">

                <div class="table-responsive">

                    {props && props.data && props.data.length > 0 ? (
                        <DataTableExtensions
                            columns={columns}
                            data={props.data}
                            print={false}
                            export={false}
                        >
                            <DataTable
                                columns={columns}
                                data={props.data}
                                fixedHeader
                                pagination
                                highlightOnHover
                            />
                        </DataTableExtensions>
                    ) : (
                        <p>No data available</p>
                    )}


                    {/* <div class="row mb-3">
                        <div class="col-sm-6">
                            <label>Show <select name="DataTables_Table_1_length"
                                aria-controls="DataTables_Table_1"
                                class="form-select form-select-sm">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select> entries</label>
                        </div>

                        <div class="col-sm-6">
                            <form action="">
                                <div class="form-group">
                                    <label>
                                        Search
                                        <input type="text" />
                                    </label>
                                </div>
                            </form>
                        </div>

                    </div>

                    <table class="table booking_tab_table">
                        <thead>
                            <tr>
                                <th>User Name</th>
                                <th>Service</th>
                                <th>Address</th>
                                <th>Number</th>
                                <th>Price</th>
                                <th>Date</th>
                                <th>Cancelled By</th>
                            </tr>
                        </thead>

                        <tbody>

                            {cancel && cancel.length > 0 ? cancel.map((data, i) => (
                                <tr>
                                    <td>{data.username}</td>
                                    <td>{data.name}</td>
                                    <td>{data.address_1}</td>
                                    <td>{data.number}</td>
                                    <td>£{data.finalPrice}</td>
                                    <td>{data.booking_date}</td>
                                    <td>{data.Cancelled_by}</td>


                                </tr>

                            )) : <tr aria-colspan={7}>No cancelled bookings</tr>}

                        </tbody>

                    </table> */}


                </div>
                {/* 
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-end">
                        <li class="page-item"><a class="page-link" href="#">Previous</a>
                        </li>
                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item"><a class="page-link" href="#">Next</a>
                        </li>
                    </ul>
                </nav> */}

            </div>


        </div>

    )

}