import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Components/Pages/Home';
import Header from './Components/Layouts/Header';
import Footer from './Components/Layouts/Footer';
import Login from './Components/Pages/Login';
import Registration from './Components/Pages/Registration';
import Service from './Components/Pages/Service';
// import Provider from './Components/Pages/Provider';
import Providers from './Components/Pages/Provider';
import Dashboard from './Components/Pages/Dashboard';
import AuthRoute from './AuthRoute';
import SingleProfile from './Components/Pages/SingleProfile';
import UserDashboard from './Components/Pages/UserDashboard';
import BookingForm from './Components/Pages/BookingForm';
import AboutUs from './Components/Pages/AboutUs';
import ContactUs from './Components/Pages/ContactUs';
import Accessibility from './Components/Pages/Accessibility';
import GetApp from './Components/Pages/GetApp';
import Privacy from './Components/Pages/Privacy';
import CookiePolicy from './Components/Pages/CookiePolicy';
import Terms from './Components/Pages/Terms';
import HelpCenter from './Components/Pages/HelpCenter';
import Blog from './Components/Pages/Blog';
import BlogDetail from './Components/Pages/BlogDetail';
import AllComments from './Components/Pages/AllComments';

function App() {
  return (
    <div className="App">
       <BrowserRouter basename={'/'}>
        <Header/>
          <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route exact path="/Login" element={<Login/>}/>
            <Route exact path="/Service" element={<Service/>}/>
            <Route exact path="/Registration" element={<Registration/>}/>
            {/* <Route exact path="/Provider" element={<Provider/>}/> */}
            <Route exact path="/Provider" element={<Providers/>}/>
            <Route exact path="/Dashboard" element={<AuthRoute element={<Dashboard />} user={'provider'} />}/>
            <Route exact path="/UserDashboard" element={<AuthRoute element={<UserDashboard />} user={'user'} />}/>
            <Route exact path="/profile/:id" element={<SingleProfile />} />
            <Route exact path="/BookingForm/:id" element={<AuthRoute element={<BookingForm />} user={'user'}/> } />
            <Route exact path="/AboutUs" element={<AboutUs/>}/>
            <Route exact path="/ContactUs" element={<ContactUs/>}/>
            <Route exact path="/Accessibility" element={<Accessibility/>}/>
            <Route exact path="/GetApp" element={<GetApp/>}/>
            <Route exact path="/PrivacyStatement" element={<Privacy/>}/>
            <Route exact path="/Cookies" element={<CookiePolicy/>}/>
            <Route exact path="/TermsOfService" element={<Terms/>}/>
            <Route exact path="/Help" element={<HelpCenter/>}/>
            <Route exact path="/Blog" element={<Blog/>}/>
            <Route exact path="/BlogDetail/:id" element={<BlogDetail/>}/>
            <Route exact path="/AllComments/:id" element={<AllComments/>}/>
          </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
