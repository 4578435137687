import React, { Component, useEffect, useState } from "react";
import Banner from "./Banner";
import CookieContent from "./CookieContent";


export default function CookiePolicy() {


    return (
        <>
            <Banner />
            <CookieContent />
        </>
    )
}