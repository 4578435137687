import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { PagesData } from "../../../../Reducer/PagesSlice";

export default function Steps() {

    const dispatch = useDispatch();
    const data = useSelector(state => state.pages.data);

    useEffect(() => {

        getPageData();

    }, []);

    async function getPageData() {
        let data = { type: "Get the App" };
        dispatch(PagesData(data));

    }


    return (
        <section id="get_app">
            <div class="container">
                <div class="row">
                    {data && data.image &&

                        <div class="col-lg-6">
                            <img class="w-100" src={"https://admin.broomyy.com/public/storage/page_image/" + data.image} alt="" />
                        </div>

                    }

                    <div class="col-lg-6">

                        <div class="app_heading">

                            {/* <h4>How to Download</h4> */}
                            {data && data.content &&

                                <p dangerouslySetInnerHTML={{ __html: data.content }}></p>
                            }

                            {/* 
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam illo explicabo aut ad
                            obcaecati perspiciatis consectetur, unde aliquam, numquam, ipsam similique quaerat quos
                            laudantium vel distinctio rerum nisi corrupti. Ut.</p>

                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam illo explicabo aut ad
                                obcaecati perspiciatis consectetur, unde aliquam, numquam, ipsam similique quaerat quos
                                laudantium vel distinctio rerum nisi corrupti. Ut.</p> */}


                            <a > <img src="images/app-store-apple.svg" alt="" /> </a>
                            <a > <img src="images/app-store-google.svg" alt="" /> </a>


                        </div>
                    </div>



                </div>
            </div>
        </section>
    )

}