import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useParams } from "react-router-dom";
import { SingleBlog } from "../../../../Reducer/PagesSlice";
import moment from 'moment';
import { useLocation } from 'react-router-dom';


export default function Comments() {

    const dispatch = useDispatch();
    const data = useSelector(state => state.pages.data);
    const params = useParams();
    let location = useLocation();


    useEffect(() => {

        getSingle();

    }, [location.pathname]);

    async function getSingle() {
        let data = { id: params.id };
        dispatch(SingleBlog(data));

    }
    return (
        <div class="details-comments">



            {data && data.comments && data.comments.length > 0 &&

                <>
                    <h3>Comments ({data.count})
                    <span><Link to={`/AllComments/${params.id}`}>View all</Link></span></h3>
                    <ul>

                        {data.comments.map((dat, i) => (
                            <li>
                                {/* <img src="images/test-1.png" alt="Details" /> */}
                                <h5>{dat.name.charAt(0).toUpperCase()}</h5>
                                <h4>{dat.name}  <span>  {`${new Date(dat.created_at).toISOString().slice(0, 10)}`}</span></h4>
                                <p>{dat.comment}</p>

                            </li>
                        ))}

                
                    </ul>
                </>

            }
        </div>

    )

}