import React, { Component, useEffect, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { AllVendors, clearSearchData, saveSearchData } from "../../../Reducer/searchSlice";
import { Search } from "../../../Reducer/searchSlice";
import Swal from 'sweetalert2';
import { ResidentialCat } from "../Data/content";
import { RentalCat } from "../Data/content";
import { $ } from "jquery";
import { Rating } from 'react-simple-star-rating'
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-google-places-autocomplete';
import Map from "./Map";



export default function Service() {

    // const [providers, setProvider] = useState([]);

    const [search, setSearched] = useState("");
    const today = new Date().toISOString().slice(0, 10);
    const [date, setDate] = useState("");
    const [types, setTypes] = useState([]);
    const [place, setPlace] = useState("");
    const [typ, setp] = useState("");
    const img_url = "https://admin.broomyy.com/public/storage/profile_images/";

    const dispatch = useDispatch();
    const login = useSelector(state => state.auth.auth);
    const token = useSelector(state => state.auth.token);
    const data = useSelector(state => state.search.data);
    const loc = useSelector(state => state.search.locations);
    const success = useSelector(state => state.search.success);
    const sideData = useSelector(state => state.search.sidebar);
    const location = useLocation();
    const [newData, setNewData] = useState(location.state);

    const [lat, setlat] = useState(localStorage.getItem('latitude'));
    const [long, setlong] = useState(localStorage.getItem('longitude'));

    const [mapKey, setMapKey] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Adjust this according to your needs

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToShow = data && data.length > 0 ? data.slice(startIndex, endIndex) : [];


    useEffect(() => {
        setPlace({
            value: "",
            label: "Type address"

        })
        if (newData && newData.search && newData.search.name) {
            setSearched(newData.search.name);
            if (newData.search.date !== '') {
                setDate(newData.search.date);
            }
            if (newData.search.name === "Residential Property") {
                setTypes(ResidentialCat);
            } else {
                setTypes(RentalCat);
            }
            if (newData.search.type !== "") {
                setp(newData.search.type);
            }

            if (newData.search.place && newData.search.place !== '') {
                setPlace({
                    value: newData.search.place,
                    label: newData.search.place
                });
            }
            test();
        } else {
            localStorage.removeItem('searchData');
            getProviders();
        }

        console.log('ned data on service pafe', newData);

    }, []);


    function clearFilter() {
        dispatch(clearSearchData());
        setPlace({
            value: "",
            label: "Type here..."

        })
        getProviders();

    }


    async function getProviders() {
        let data = { "latitude": lat, "longitude": long }
        await dispatch(AllVendors(data));
        setMapKey(prevKey => prevKey + 1);
    }

    function handlePageChange(newPage) {
        setCurrentPage(newPage);
    }


    function renderPaginationControls() {
        const totalPages = data ? Math.ceil(data.length / itemsPerPage) : 0;
        // const totalPages = Math.ceil(data.length / itemsPerPage);
        const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

        return (
            <div className="pagination">
                {pageNumbers.map((page) => (
                    <button key={page} onClick={() => handlePageChange(page)}>{page}</button>
                ))}
            </div>
        );
    }


    async function addType(e) {
        if (e.target.checked === true) {
            setSearched(e.target.value);
            if (newData === null) {
                setNewData({
                    search: {
                        name: e.target.value,
                    },
                });
            } else {
                setNewData({
                    ...newData,
                    search: {
                        ...newData.search,
                        name: e.target.value,
                    },
                });
            }

            if (e.target.value === "Residential Property") {
                setTypes(ResidentialCat);
            }
            if (e.target.value === "Rental Property") {
                setTypes(RentalCat);
            }
        }
    }

    function select(e) {
        setp(e.target.value);
        if (newData === null) {
            setNewData({
                search: {
                    type: e.target.value,
                },
            });
        } else {
            setNewData({
                ...newData,
                search: {
                    ...newData.search,
                    type: e.target.value,
                },
            });
        }
    }

    async function test() {

        if (newData.search.name != "") {
            let data = { 'search': newData.search.name, 'date': newData.search.date, 'type': newData.search.type, "latitude": newData.search.latitude, "longitude": newData.search.longitude, "place": newData.search.place };

            dispatch(saveSearchData(data));
            await dispatch(Search(data));
            setMapKey(prevKey => prevKey + 1);

        } else {
            Swal.fire({
                title: 'Error!',
                text: "Please select property type",
                icon: 'error'
            });
        }
    }

    const handleSelect = async (value) => {
        try {
            const results = await geocodeByAddress(value.label);
            const latLng = await getLatLng(results[0]);

            setlat(latLng.lat);
            setlong(latLng.lng);
            setPlace({
                label: value.label,
                value: value.label,
            });
            if (newData === null) {
                setNewData({
                    search: {
                        latitude: latLng.lat,
                        longitude: latLng.lng,
                        place: value.label,
                    },
                });
            } else {
                setNewData({
                    ...newData,
                    search: {
                        ...newData.search,
                        ["latitude"]: latLng.lat,
                        ["longitude"]: latLng.lng,
                        ["place"]: value.label,
                    },
                });
            }
        } catch (error) {
            console.error('Error selecting place: ', error);
        }
    };



    async function searchCleaning() {

        if (search !== "") {

            let data = { 'search': search, 'date': date, 'type': typ, "latitude": lat, "longitude": long, "place": newData.search.place };

            dispatch(saveSearchData(data));

            await dispatch(Search(data));

            setMapKey(prevKey => prevKey + 1);

        } else {
            Swal.fire({
                title: 'Error!',
                text: "Please select property type",
                icon: 'error'
            });
        }

    }

    async function onSelectDate(e) {
        console.log('event date', e.target.value);
        setDate(e.target.value);
        if (newData === null) {
            setNewData({
                search: {
                    date: e.target.value
                },
            });
        } else {
            setNewData({
                ...newData,
                search: {
                    ...newData.search,
                    ["date"]: e.target.value
                },
            });
        }

    }


    return (<section id="service">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-3 col-lg-4">
                    <div class="filter-section">

                        <div class="cancel-filter d-none filter-show">
                            <i class="bi bi-x-square"></i>
                        </div>

                        <form>
                            <div class="service-type">
                                <h4>Cleaning Type:</h4>

                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" onChange={addType} checked={search === "Residential Property"}
                                        id="flexRadioDefault1" value="Residential Property" />
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        <img src="images/hand-home.png" alt="" /> Residential Property
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" onChange={addType} value="Rental Property" checked={search === "Rental Property"}
                                        id="flexRadioDefault2" />
                                    <label class="form-check-label" for="flexRadioDefault2">
                                        <img src="images/key-img.png" /> Rental Property
                                    </label>
                                </div>
                                {types && types.length > 0 &&

                                    <select onChange={select} class="form-select" aria-label="Default select example" value={typ}>
                                        <option value="" selected disabled>Select</option>
                                        {types.map((data, i) => (
                                            <option key={i} value={data.name}>{data.name}</option>
                                        ))}

                                    </select>
                                }

                                <h4>Address</h4>
                                <PlacesAutocomplete

                                    selectProps={{
                                        // location,
                                        value: place,
                                        onChange: handleSelect,
                                    }}

                                    query={{ key: "AIzaSyA4mFVw62S7LCfcIGBHrqeG6k9Ty_NCuJw" }}
                                    fetchDetails={true}
                                    onFail={error => console.log(error)}
                                    placeholder="Search"
                                />

                                <h4>Date</h4>
                                <input class="service-input" type="date" placeholder="enter postal code" onChange={onSelectDate} value={date} />





                                <div class="row">
                                    <div class="col-xxl-6 col-sm-6">
                                        <div class="filter_btn_sec">
                                            <button class="clear_filter_btn" onClick={clearFilter}>Clear Filter</button>
                                        </div>
                                    </div>
                                    <div class="col-xxl-6 col-sm-6">
                                        <div class="filter_btn_sec">
                                            <button class="clear_apply_btn" type="button" onClick={() => searchCleaning()} >Apply</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
                <div class="col-xl-6 col-lg-8">
                    <div class="border-filter">
                        <button>
                            <h6 class="filter-show"><i class="bi bi-filter-left"></i> Filter</h6>
                        </button>
                    </div>

                    {itemsToShow && itemsToShow.length > 0 ?

                        <>

                            {itemsToShow.map((pro) => (
                                <>
                                    <a class="per-anchor">

                                        <div class="service_bg_sh">

                                            <div class="row">

                                                <div class="col-lg-9 col-md-10 col-sm-9">

                                                    <div class="d-flex">
                                                        <div class="service-img" style={{ backgroundImage: `url(${img_url + pro.image})` }}>

                                                            {/* <img class="w-100" src={img_url + pro.image} alt="" /> */}
                                                            <i class="bi bi-heart"></i>
                                                        </div>

                                                        <div class="service-heading">
                                                            <Link
                                                                to={`/profile/${pro.id}`}
                                                            >  <h4>{pro.username}</h4></Link>

                                                            <p>{pro.headline}</p>

                                                            <ul>

                                                                {pro.services && pro.services.length > 0 && pro.services.map((a, i) => (
                                                                    <>
                                                                        <li >{a.name}</li>
                                                                    </>


                                                                ))}

                                                            </ul>

                                                            <h6>


                                                                <span>

                                                                    {pro.RatingsUser && pro.RatingsUser.length > 0 &&
                                                                        <img src={img_url + pro.RatingsUser[0].image} alt="" />

                                                                    }
                                                                    {pro.RatingsUser && pro.RatingsUser.length > 1 &&
                                                                        <img src={img_url + pro.RatingsUser[1].image} alt="" />

                                                                    }
                                                                    {pro.RatingsUser && pro.RatingsUser.length > 2 &&
                                                                        <img src={img_url + pro.RatingsUser[2].image} alt="" />

                                                                    }



                                                                </span>     {pro.RatingsUser && pro.RatingsUser.length > 3 &&

                                                                    <>
                                                                        {pro.RatingsUser.length - 3} Reviews
                                                                    </>
                                                                }
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-lg-3 col-md-2 col-sm-3">
                                                    <div class="service-rating">

                                                        <h6><span>

                                                            <Rating allowHover={false} readonly={true}
                                                                initialValue={pro.Star_count}
                                                            />


                                                        </span></h6>



                                                        {pro.minPrice && pro.minPrice != 0 &&


                                                            <p> Starts at <br /> <strong>${pro.minPrice}</strong> <br /> per hour</p>

                                                        }
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </a>

                                </>

                            ))}

                            {/* Pagination controls */}
                            {renderPaginationControls()}
                        </>
                        : <p>No data found</p>
                    }

                </div>



                <div class="col-xl-3 mt-3">


                    <div class="service-map">

                        {loc && loc.length > 0 &&
                            // <></>

                            <Map key={mapKey} data={loc}></Map>


                        }
                        {sideData &&

                            <div class="accordion" id="accordionExample">

                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            How do I adjust the timing of my quote?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            {sideData.quote_timming}
                                        </div>
                                    </div>
                                </div>


                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                            How does pricing work?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse"
                                        aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            {sideData.pricing}
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapsefour" aria-expanded="false"
                                            aria-controls="collapsefour">
                                            What’s included in the cleaning service?
                                        </button>
                                    </h2>
                                    <div id="collapsefour" class="accordion-collapse collapse" aria-labelledby="headingfour"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            {sideData.service}
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapsefive" aria-expanded="false"
                                            aria-controls="collapsefive">
                                            How do I pick a specific time/date?
                                        </button>
                                    </h2>
                                    <div id="collapsefive" class="accordion-collapse collapse" aria-labelledby="headingfive"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            {sideData.time_date}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                </div>

            </div>
        </div>
    </section >);
}