import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { PagesData } from "../../../../Reducer/PagesSlice";

export default function Mission() {

    const dispatch = useDispatch();
    const data = useSelector(state => state.pages.data);

    useEffect(() => {

        getPageData();

    }, []);

    async function getPageData() {
        let data = { type: "About Us" };
        dispatch(PagesData(data));

    }

    return (
        <>
            <section id="mission">
                <div class="container">
                    <div class="row">
                    <h2>What we do</h2>
                        <div class="col-lg-12">

                            {data && data.content2 &&

                                <p dangerouslySetInnerHTML={{ __html: data.content2 }}></p>

                            }

                     
                        </div>

                        {/* {data && data.video &&

                            <div class="col-lg-6">
                                <div class="vidimg">
                                 
                                    <video controls class="w-100">
                                        <source src={"https://admin.broomyy.com/public/storage/page_image/" + data.video} type="video/mp4" />
                                    </video>

                                </div>
                            </div>

                        } */}
                    </div>
                </div>
            </section>


            <section id="misssion_contact">
                <div class="container">
                    <div class="row">

                        <div class="col-sm-8">

                            <h4>Ready to book your cleaning?</h4>
                        </div>

                        <div class="col-sm-4">
                            <div class="text-center">
                                <Link to={'/ContactUs'} class="btn" >Contact Us</Link>
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    )

}