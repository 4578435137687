import React, { Component, useEffect, useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { HomeData, testimonial } from "../../../../Reducer/PagesSlice";
import { useDispatch, useSelector } from 'react-redux';
import img from "../../Data/book-bg.png";

export default function Testimonials() {

    const dispatch = useDispatch();
    const data = useSelector(state => state.pages.data);
    const data2 = useSelector(state => state.pages.home);
    // const options = {
    //     items: 1,
    //     nav: true,
    //     rewind: true,
    //     autoplay: true,
    //     slideBy: 3,
    //     // dots: true,
    //     // dotsEach: true,
    //     // dotData: true
    // };

    const homeOptions = {
        loop: true,
        autoplay: false,
        autoplayTimeout: 5000,
        margin: 0,
        responsiveClass: true, 
        responsive: {
        0: { items: 1, nav: false },
        576: { items: 1, nav: true },
        768: { items: 2, nav: true },
        992: { items: 2, nav: true },
        1200: { items: 2, nav: true }
        }
        };


    useEffect(() => {

        getPageData();

    }, []);

    async function getPageData() {

        dispatch(testimonial());
        dispatch(HomeData());

    }

    return (
        <>
            <section id="testimonials">
                <div class="container">


                    <h2>Testimonials</h2>
               

                    {data && data.length > 0 &&

                    

                        <OwlCarousel className='owl-theme' {...homeOptions}>
                            {data.map((a, i) => (
                                <div class='item' key={i}>
                                    <div class="test-property">
                                        <div class="test-img">
                                            <img class="w-100" src={"https://admin.broomyy.com/public/storage/page_image/" + a.profile} alt={a.name} />
                                        </div>
                                        <div class="comma-para">
                                            <span><img src="images/comma-2.png" alt="" /></span>
                                            <p dangerouslySetInnerHTML={{ __html: a.description }}></p>
                                            <p class="comma-img"><img src="images/comma-1.png" alt="" /></p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>

                    }

                </div >
            </section >

            <section style={{ backgroundImage: `url(${img})` }} id="book_pay">
                <div class="container">
                    {data2 &&
                        <div class="row">
                            <div class="col-lg-4 col-sm-6">
                                <div class="book-sec">
                                    <img src="images/work-search.png" alt="" />
                                    <h5>Search</h5>
                                    <h6>{data2.search}</h6>
                                </div>
                            </div>

                            <div class="col-lg-4 col-sm-6">
                                <div class="book-sec">
                                    <img src="images/book-pay.png" alt="" />
                                    <h5>Book and Pay</h5>
                                    <h6>{data2.book_pay}</h6>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <div class="book-sec">
                                    <img src="images/Relax.png" alt="" />
                                    <h5>Relax</h5>
                                    <h6>{data2.relax}</h6>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </section>

        </>
    )

}