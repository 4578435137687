import { Button, Col, Row } from "antd";
import React, { useContext } from "react";
import MultiStepFormContext from "./MultiStepFormContext";
import { Modal } from "react-bootstrap";
import { CleaningSetting } from "./cleaning-setting";
import { Testimonials } from "./testimonials";
import { Experience } from "./experience";
import { Final } from "./final";
import { Gallery } from "./gallery";
const Review = () => {
  const [isShowClean, invokeModalClean] = React.useState(false);
  const [isShowTestimonials, invokeModalTestimonials] = React.useState(false);
  const [isShowExperience, invokeModalExperience] = React.useState(false);
  const [isShowGallery, invokeModalGallery] = React.useState(false);
  const [isShowFinal, invokeModalFinal] = React.useState(false);
  const [cfill, cleanFill] = React.useState(null);
  const [tfill, testFill] = React.useState(null);
  const [exfill, expFill] = React.useState(null);
  const [gfill, galleryFill] = React.useState(null);
  const [ffill, finalFill] = React.useState(null);
  const { details, address, next, prev, skip } =
    useContext(MultiStepFormContext);

  const parentToChild = () => {
    invokeModalClean(false);
  };

  return (
    <div class="row">
      <form>
        <div class="form-bg">
          <h2>Service Setup</h2>
          <p
            class="pop_gill"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="To get started, we only require that you setup Cleaning. You’ll have the opportunity to setup Home Cleaning, Rental Cleaning, Commercial Cleaning, Window Cleaning, Steam Carpet once you complete the Sign-up process.\n \nIf you wish to add, remove, or modify the services you provide, you may do so at any time after completing the sign-up process."
          >
            Where are my other services?
          </p>
          <a
            href="javaScript:void(0)"
            onClick={() => invokeModalClean(!isShowClean)}
          >
            <div class="box_cleaner">
              <h3>Cleaning</h3>
              <p>Set your service preferences</p>
              {cfill !== null ? (
                <span>
                  <img
                    src={cfill == true ? "images/right.png" : "images/exp.png"}
                  />
                </span>
              ) : (
                ""
              )}
            </div>
          </a>

          <a
            href="javaScript:void(0)"
            onClick={() => invokeModalTestimonials(!isShowTestimonials)}
          >
            <div class="box_cleaner">
              <h3>Request Testimonials</h3>
              <p>Use references to build trust with new potential clients</p>
              {tfill !== null ? (
                <span>
                  <img
                    src={tfill == true ? "images/right.png" : "images/exp.png"}
                  />
                </span>
              ) : (
                ""
              )}
            </div>
          </a>

          <a
            href="javaScript:void(0)"
            onClick={() => invokeModalExperience(!isShowExperience)}
          >
            <div class="box_cleaner">
              <h3>Cleaning Experience</h3>
              <p>Let clients know about your personal qualities</p>
              {exfill !== null ? (
                <span>
                  <img
                    src={exfill == true ? "images/right.png" : "images/exp.png"}
                    alt="img"
                  />
                </span>
              ) : (
                ""
              )}
            </div>
          </a>

          <a
            href="javaScript:void(0)"
            onClick={() => invokeModalGallery(!isShowGallery)}
          >
            <div class="box_cleaner">
              <h3>Gallery</h3>
              <p>Upload photos of your work</p>
              {gfill !== null ? (
                <span>
                  <img
                    src={gfill == true ? "images/right.png" : "images/exp.png"}
                    alt="img"
                  />
                </span>
              ) : (
                ""
              )}
            </div>
          </a>

          <a
            href="javaScript:void(0)"
            onClick={() => invokeModalFinal(!isShowFinal)}
          >
            <div class="box_cleaner">
              <h3>Final Details</h3>
              <p>Background check and review fee</p>
              {ffill !== null ? (
                <span>
                  <img
                    src={ffill == true ? "images/right.png" : "images/exp.png"}
                    alt="img"
                  />
                </span>
              ) : (
                ""
              )}
            </div>
          </a>

          <div class="row">
            <div class="col-4">
              <div class="text-center">
                <Button type={"default"} onClick={prev}>
                  Back
                </Button>
              </div>
            </div>
            <div class="col-4">
              <div class="text-center">
                <Button type={"default"} onClick={skip}>
                  skip
                </Button>
              </div>
            </div>

            <div class="col-4">
              <div class="text-center">
                <Button type={"primary"} onClick={next}>
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal show={isShowClean} dialogClassName="modal-lg">
        <Modal.Header
          closeButton
          onClick={() => invokeModalClean(!isShowClean)}
        ></Modal.Header>
        <Modal.Body>
          <CleaningSetting
            onClick={() => {
              invokeModalClean(!isShowClean);
              cleanFill(true);
            }}
          />
        </Modal.Body>
      </Modal>

      <Modal show={isShowTestimonials} dialogClassName="modal-lg">
        <Modal.Header
          closeButton
          onClick={() => invokeModalTestimonials(!isShowTestimonials)}
        ></Modal.Header>
        <Modal.Body>
          <Testimonials
            onClick={() => {
              invokeModalTestimonials(!isShowTestimonials);
              testFill(true);
            }}
          />
        </Modal.Body>
      </Modal>

      <Modal show={isShowFinal} dialogClassName="modal-lg">
        <Modal.Header
          closeButton
          onClick={() => invokeModalFinal(!isShowFinal)}
        ></Modal.Header>
        <Modal.Body>
          <Final
            onClick={() => {
              invokeModalFinal(!isShowFinal);
              finalFill(true);
            }}
          />
        </Modal.Body>
      </Modal>

      <Modal show={isShowExperience} dialogClassName="modal-lg">
        <Modal.Header
          closeButton
          onClick={() => invokeModalExperience(!isShowExperience)}
        ></Modal.Header>
        <Modal.Body>
          <Experience
            onClick={() => {
              invokeModalExperience(!isShowExperience);
              expFill(true);
            }}
          />
        </Modal.Body>
      </Modal>

      <Modal show={isShowGallery} dialogClassName="modal-lg">
        <Modal.Header
          closeButton
          onClick={() => invokeModalGallery(!isShowGallery)}
        ></Modal.Header>
        <Modal.Body>
          <Gallery
            onClick={() => {
              invokeModalGallery(!isShowGallery);
              galleryFill(true);
            }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Review;
