import React, { Component, useEffect, useState } from "react";
import Banner from "./Banner";
import TermsOfService from "./TermsOfService";

export default function Terms() {

    return (
        <>
            <Banner />
            <TermsOfService />

        </>
    )


}
