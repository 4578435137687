import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useParams } from "react-router-dom";
import { GetAllComments } from "../../../../Reducer/PagesSlice";


export default function AllComnt() {

    const dispatch = useDispatch();
    const data = useSelector(state => state.pages.data);
    const params = useParams();

    useEffect(() => {

        allcomments();

        console.log('blg iddd', data);

    }, []);

    async function allcomments() {
        let data = { id: params.id };
        dispatch(GetAllComments(data));

    }

    return (

        <>


            <div class="details-comments">



                <div className="container">

                    {data && data.length > 0 &&

                        <ul>

                            {data.map((dat, i) => (

                                <li>
                                    <h5>{dat.name.charAt(0).toUpperCase()}</h5>
                                    <h4>{dat.name} <span>{`${new Date(dat.created_at).toISOString().slice(0, 10)}`}</span></h4>
                                    <p>{dat.comment}</p>

                                </li>

                            ))}

                           


                        </ul>
                    }

                </div>

            </div>

        </>


    )

}