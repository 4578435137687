import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { PagesData } from "../../../../Reducer/PagesSlice";

export default function Overview() {

    const dispatch = useDispatch();
    const data = useSelector(state => state.pages.data);

    useEffect(() => {

        getPageData();

    }, []);

    async function getPageData() {
        let data = { type: "About Us" };
        dispatch(PagesData(data));

    }

    return (
        <section id="about_us">
            <div class="container">
                <div class="row">
                    {/* <div class="col-lg-6 col-md-12"> */}

                        {data && data.content &&

                            <p dangerouslySetInnerHTML={{ __html: data.content }}></p>

                        }

                        {/* <h2>Company Overview</h2>

                        <h5>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna aliqua. Ut enim ad minim veniam.</h5>

                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}

                        {/* <div class="row">
                            <div class="col-sm-6">

                                <ul>
                                    <li>Offer a broad range</li>
                                    <li>Offer a broad range</li>
                                    <li>Offer a broad range</li>
                                </ul>

                            </div>

                            <div class="col-sm-6">

                                <ul>
                                    <li>Offer a broad range</li>
                                    <li>Offer a broad range</li>
                                    <li>Offer a broad range</li>
                                </ul>

                            </div>

                        </div> */}

                        {/* <!-- <a class="btn" href="contact_us.html">Contact Us</a> --> */}

                    {/* </div> */}

                    {/* {data && data.image &&

                        <div class="col-lg-6 col-md-12">
                            <img class="w-100" src={"https://admin.broomyy.com/public/storage/page_image/" + data.image}  alt="" />
                        </div>
                    } */}
                </div>
            </div>
        </section>

    )

}