import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AcceptRejectBooking, GetPending } from '../../../../../Reducer/vendorSlice';
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';


export default function Pending(props) {
    const dispatch = useDispatch();
    const login = useSelector(state => state.auth.auth);
    // const [cancel, setCancel] = useState([]);

    const columns = [
        {
            name: 'User Name',
            selector: 'username',
            sortable: true,
        },
        {
            name: 'Service',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Address',
            selector: 'address',
            sortable: true,
        },
        {
            name: 'Number',
            selector: 'phone',
        },
        {
            name: 'Price',
            selector: 'finalPrice',
            cell: (row) => `$ ${row.finalPrice}`, 
        },
        {
            name: 'Booking Date',
            selector: 'booking_date',
        },
        {
            name: 'Cancelled By',
            selector: 'Cancelled_by',

        },
        {
            name: 'Reason',
            selector: 'cancel_reason',
            wrap: true

        },


    ];

    useEffect(() => {

        // getCancelledBookings();
    }, [login.user_id]);


    // async function getCancelledBookings() {
    //     let uid = { user_id: login.user_id, type: "cancelled" };

    //     let response = await dispatch(GetPending(uid));

    //     console.log('resssps', response);
    //     if (response.payload.status === 200) {
    //         setCancel(response.payload.data.data);
    //     }
    // }


    return (
        <div class="tab-pane fade show active" id="pills-pending" role="tabpanel"
            aria-labelledby="pills-pending-tab">


            <div class="active_list_inner">

                <div class="table-responsive">

                    {props && props.data && props.data.length > 0 ? (
                        <DataTableExtensions
                            columns={columns}
                            data={props.data}
                            print={false}
                            export={false}
                        >
                            <DataTable
                                columns={columns}
                                data={props.data}
                                fixedHeader
                                pagination
                                highlightOnHover
                            />
                        </DataTableExtensions>
                    ) : (
                        <p>No data available</p>
                    )}

                </div>



            </div>


        </div>
    )

}