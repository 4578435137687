import { combineReducers } from '@reduxjs/toolkit';
// import userReducer from './Reducer/userSlice';
// import postReducer from './Reducer/postSlice';
// import apiReducer from './Reducer/apiSlice';
import formReducer from './Reducer/formSlice';
import  sendotpReducer  from './Reducer/sendotpSlice';
import verifyformReducer from './Reducer/verifyformSlice';
import authReducer from './Reducer/authSlice';
import searchSlice from './Reducer/searchSlice';
import bookingSlice from './Reducer/bookingSlice';
import PagesSlice from './Reducer/PagesSlice';
import vendorSlice from './Reducer/vendorSlice';
import notificationSlice from './Reducer/notificationSlice';
import providerForms from './Reducer/providerForms';
import userSlice from './Reducer/userSlice';


const rootReducer = combineReducers({
    auth: authReducer,
    form:formReducer,
    sendotp:sendotpReducer,
    verify_otp:verifyformReducer, 
    search: searchSlice,
    booking : bookingSlice,
    pages : PagesSlice,
    vendor : vendorSlice,
    notifi : notificationSlice,
    forms : providerForms,
    user : userSlice
});

export default rootReducer;