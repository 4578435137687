import React from 'react';
import { NavbarData } from '../../Data/content';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Profile from '../Profile';
import Inbox from '../Inbox';
import Gallery from '../Gallery';
import PaymentDetails from '../PaymentDetails';
import Availability from '../Availability';
import Services from '../Services';
import Bookings from '../Bookings';
import  Chat from '../../Chat';
import Transactions from '../Transactions';

export default function Navbar() {

    return (<>
        <section id="user_dashboard">
            <div class="container">

                <div class="responsive_tab">
                    <Tabs>

                        <TabList className="nav nav-pills mb-3 responsive_scroll provider" id="pills-tab">
                            {NavbarData.length > 0 && NavbarData.map((arr, i) => {
                                return (
                                    <>
                                        <Tab>{arr.name}</Tab>
                                    </>

                                )
                            })}
                        </TabList>
                        <TabPanel>
                            <Profile />
                        </TabPanel>
                        <TabPanel>
                            <Chat />
                        </TabPanel>
                        <TabPanel>
                            <Gallery />
                        </TabPanel>
                        <TabPanel>
                            <PaymentDetails />
                        </TabPanel>
                        <TabPanel>
                            <Availability />
                        </TabPanel>
                        <TabPanel>
                            <Services />
                        </TabPanel>
                        <TabPanel>
                            <Bookings />
                        </TabPanel>
                        <TabPanel>
                            <Transactions/>
                        </TabPanel>

                    </Tabs>
                </div>
            </div>
        </section>
    </>
    );


}
