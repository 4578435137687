import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileData } from '../../../../Reducer/vendorSlice';
import { UpdateProfile } from '../../../../Reducer/vendorSlice';
import { Formik } from "formik";
import { Button } from "antd";
import { Input, InputNumber } from "formik-antd";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-google-places-autocomplete';

export default function Proile() {

    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [profileData, setProfile] = useState({});
    const [file, setFile] = useState();
    const [fileData, setData] = useState();
    const dispatch = useDispatch();
    const login = useSelector(state => state.auth.auth);
    const [location, setLocation] = useState("");
    const [lat, setLat] = useState("");
    const [long, setLong] = useState("");
    const [selectedPlace, setSelectedPlace] = useState(null);

    useEffect(() => {
        getProfile();

        console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log(formErrors);
        }
    }, [])

    async function getProfile() {
        let uid = { user_id: login.user_id };
        let response = await dispatch(ProfileData(uid));
        if (response.payload.status === 200) {
            setProfile(response.payload.data.data);
            setSelectedPlace({
                label: response.payload.data.data.address_1,
                value: response.payload.data.data.address_1,
            });
        }


    }


    function handleChange(e) {
        console.log(e.target.files);
        setData(e.target.files[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
    }


    function handleChangeInput(evt) {
        const value = evt.target.value;
        setProfile({
            ...profileData,
            [evt.target.name]: value
        });
    }

    const handleSelect = async (value) => {
        try {
            const results = await geocodeByAddress(value.label);
            const latLng = await getLatLng(results[0]);
            //   setLocation(value.label);
            //   setLat(latLng.lat);
            //   setLong(latLng.lng);
            setSelectedPlace({
                label: value.label,
                value: value.label,
            });
            setProfile({
                ...profileData,
                ["address_1"]: value.label,
                ['latitude']: latLng.lat,
                ['longitude']: latLng.lng,
            });

            console.log('Selected Place: ', value);
            console.log('Latitude and Longitude: ', latLng);
        } catch (error) {
            console.error('Error selecting place: ', error);
        }
    };



    async function handleSubmit(e) {
        e.preventDefault();
        setFormErrors(validate(profileData));
        setIsSubmit(true);

        console.log('dataa', profileData);

        if (Object.keys(formErrors).length == 0 && isSubmit === true) {

            console.log('valiudated data');
            let formData = new FormData();
            formData.append('user_id', login.user_id);
            formData.append('first_name', profileData.first_name);
            formData.append('last_name', profileData.last_name);
            formData.append('email', profileData.email);
            formData.append('phone', profileData.number);
            formData.append('age', profileData.age);
            formData.append('address_1', profileData.address_1);
            formData.append('address_2', profileData.address_2);
            formData.append('country', profileData.country);
            formData.append('city', profileData.city);
            formData.append('state', profileData.state);
            formData.append('postal_code', profileData.postal_code);
            formData.append('years', profileData.years);
            formData.append('experience', profileData.experience);
            formData.append('headline', profileData.headline);
            formData.append('Schedule', profileData.Schedule);
            formData.append('image', fileData);
            formData.append('latitude', profileData.latitude);
            formData.append('longitude', profileData.longitude);
            formData.append('service_amount', profileData.service_amount);

            let update = await dispatch(UpdateProfile(formData));
            if (update.payload.status == 200) {
                Swal.fire({
                    title: 'Congratulation',
                    text: update.payload.data.message,
                    icon: 'success'
                });

            } else {
                Swal.fire({
                    title: 'Error!',
                    text: update.payload.data.message,
                    icon: 'error'
                });
            }



        }
    }


    const validate = (values) => {
        const errors = {};

        console.log('vaues', values);
        // const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

        if (!values.first_name) {
            errors.first_name = "first name is required!";
        }
        if (!values.last_name) {
            errors.last_name = "last name is required!";
        }

        if (!values.email) {
            errors.email = "Email is required!";
        }
        // else if(!regex.test(values.email)){
        //     errors.email = "This is not a valid email format!";
        // }
        if (!values.number) {
            errors.number = "Phone is required!";
        }

        if (!values.age) {
            errors.age = "Date of birth is required!";
        } else if(values.age) {
            const birthDate = new Date(values.age);
            const currentDate = new Date();
            const age = currentDate.getFullYear() - birthDate.getFullYear();

            if (age < 18) {
                errors.age = "You must be 18 years or older!";
            }
        }
        if (!values.address_1) {
            errors.address_1 = "This field is required!";
        }
        // if (!values.address_2) {
        //     errors.address_2 = "This field is required!";
        // }
        if (!values.country) {
            errors.country = "This field is required!";
        }
        if (!values.city) {
            errors.city = "This field is required!";
        }
        if (!values.state) {
            errors.state = "This field is required!";
        }
        if (!values.postal_code) {
            errors.postal_code = "This field is required!";
        }
        if (!values.years) {
            errors.years = "This field is required!";
        }
        if (!values.experience) {
            errors.experience = "This field is required!";
        }
        if (!values.headline) {
            errors.headline = "This field is required!";
        }
        if (!values.Schedule) {
            errors.Schedule = "This field is required!";
        }
        if (!values.Schedule) {
            errors.Schedule = "This field is required!";
        }
        return errors;
    }


    return (<>

        <div class="tab-pane fade show active" id="pills-profile" role="tabpanel"
            aria-labelledby="pills-profile-tab">

            <div class="tab_profile_upload">
                <div class="row">
                    <div class="col-lg-4">

                        <div class="imagefile">
                            <span><i class="fas fa-camera"></i>
                                <input type='file' onChange={handleChange} />
                            </span>

                            {file ? <img class="w-100" id="blah" src={file} /> : <img class="w-100" id="blah" src={"https://admin.broomyy.com/public/storage/profile_images/" + profileData.image} alt="your image" />}

                        </div>
                    </div>

                    <div class="col-lg-8">

                        <form onSubmit={handleSubmit}>
                            {/* 
                        {Object.keys(formErrors).length === 0 && isSubmit ? <p>successful</p>: <p>unsuccessful</p>}  */}

                            <div class="row">

                                <div class="col-md-6">

                                    <div class="form-group">
                                        <label class="form-label">First Name</label>
                                        <input type="text" className="form-control" name="first_name" placeholder="Enter full name" onChange={handleChangeInput} value={profileData.first_name} />
                                        <p className="error">{formErrors.first_name}</p>
                                    </div>


                                </div>
                                <div class="col-md-6">

                                    <div class="form-group">
                                        <label class="form-label">Last Name</label>
                                        <input type="text" class="form-control" name="last_name" onChange={handleChangeInput} value={profileData.last_name} />
                                        <p className="error">{formErrors.last_name}</p>
                                    </div>

                                </div>


                                <div class="col-md-6">

                                    <div class="form-group">
                                        <label class="form-label">Phone Number</label>
                                        <input type="tel" class="form-control" name="number" placeholder="Enter phone number" onChange={handleChangeInput} value={profileData.number} readOnly />
                                        <p className="error">{formErrors.number}</p>
                                    </div>

                                </div>

                                <div class="col-md-6">

                                    <div class="form-group">
                                        <label class="form-label">Email Id</label>
                                        <input type="email" class="form-control" name="email" placeholder="Enter email id" onChange={handleChangeInput} value={profileData.email} readOnly />
                                        <p className="error">{formErrors.email}</p>
                                    </div>

                                </div>

                                <div class="col-md-6">

                                    <div class="form-group">
                                        <label class="form-label">Date of Birth</label>
                                        <input type="date" class="form-control" name="age" placeholder="Enter age" onChange={handleChangeInput} value={profileData.age} max="9999-12-31" />
                                        <p className="error">{formErrors.age}</p>
                                    </div>

                                </div>

                                <div class="col-md-6">

                                    <div class="form-group">
                                        <label class="form-label">Address Line 1</label>
                                        <input type="hidden" class="form-control" defaultValue={profileData.address_1} name="address_1" placeholder="Enter address" />

                                        <PlacesAutocomplete
                                            selectProps={{
                                                value: selectedPlace,
                                                onChange: handleSelect,
                                            }}
                                            query={{ key: "AIzaSyA4mFVw62S7LCfcIGBHrqeG6k9Ty_NCuJw" }}
                                            fetchDetails={true}
                                            onFail={error => console.log(error)}
                                            placeholder="Search"
                                        />

                                        <p className="error">{formErrors.address_1}</p>
                                    </div>


                                </div>

                                {profileData.address_2 != "null" &&

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-label">Address Line 2</label>
                                            <input type="text" class="form-control" name="address_2" placeholder="Enter address" onChange={handleChangeInput} value={profileData.address_2} />
                                            <p className="error">{formErrors.address_2}</p>
                                        </div>

                                    </div>
                                }

                                <div class="col-md-6">

                                    <div class="form-group">
                                        <label class="form-label">Country</label>
                                        <input type="text" class="form-control" name="country" placeholder="country" onChange={handleChangeInput} value={profileData.country} />
                                        <p className="error">{formErrors.country}</p>
                                    </div>

                                </div>

                                <div class="col-md-6">

                                    <div class="form-group">
                                        <label class="form-label">City</label>
                                        <input type="text" class="form-control" name="city" placeholder="city" onChange={handleChangeInput} value={profileData.city} />
                                        <p className="error">{formErrors.city}</p>
                                    </div>

                                </div>

                                <div class="col-md-6">

                                    <div class="form-group">
                                        <label class="form-label">State</label>
                                        <input type="text" class="form-control" name="state" placeholder="state" onChange={handleChangeInput} value={profileData.state} />
                                        <p className="error">{formErrors.state}</p>
                                    </div>

                                </div>

                                <div class="col-md-6">

                                    <div class="form-group">
                                        <label class="form-label">Postal Code</label>
                                        <input type="text" class="form-control" name="postal_code" onChange={handleChangeInput} placeholder="postal code" value={profileData.postal_code} />
                                        <p className="error">{formErrors.postal_code}</p>
                                    </div>

                                </div>

                                <div class="col-md-6">

                                    <div class="form-group">
                                        <label class="form-label">Base price</label>
                                        <input type="text" class="form-control" name="service_amount" onChange={handleChangeInput} placeholder="service_amount" value={profileData.service_amount} />
                                        <p className="error">{formErrors.service_amount}</p>
                                    </div>

                                </div>

                                <div class="col-12">
                                    <h2>Cleaning Experience</h2>
                                </div>

                                <div class="col-md-6">

                                    <div class="form-group">
                                        <label class="form-label">Cleaning Experience</label>
                                        <input class="form-control"
                                            placeholder="cleaning experience" name="experience" onChange={handleChangeInput} value={profileData.experience} />
                                        <p className="error">{formErrors.experience}</p>

                                    </div>

                                </div>

                                <div class="col-md-6">

                                    <div class="form-group">
                                        <label class="form-label">Write and eye catching headline</label>
                                        <input class="form-control" name="headline" onChange={handleChangeInput} placeholder="enter headline" value={profileData.headline} />
                                        <p className="error">{formErrors.headline}</p>
                                    </div>

                                </div>

                                <div class="col-md-12">

                                    <div class="form-group">
                                        <label class="form-label">Years of personal or professional experience
                                            in cleaning.</label>
                                        <input type="text" class="form-control" name="years"
                                            placeholder="enter years of experience" onChange={handleChangeInput} value={profileData.years} />
                                        <p className="error">{formErrors.years}</p>
                                    </div>



                                </div>

                                <div class="col-md-12">

                                    <div class="form-group">
                                        <label class="form-label">Schedule</label>
                                        <input class="form-control" name="Schedule" onChange={handleChangeInput} placeholder="schedule" value={profileData.Schedule} />
                                        <p className="error">{formErrors.Schedule}</p>

                                    </div>

                                </div>

                                <div class="col-md-12">

                                    <div class="form-group text-center">

                                        <button type="submit">Update</button>
                                    </div>

                                </div>

                            </div>

                        </form>

                    </div>

                </div>
            </div>

        </div>

    </>);




}