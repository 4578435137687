
import React, { Component, useEffect, useState } from "react";
import Image from '../../Data/booking-bg.png';

export default function Banner() {
return (
    <section style={{ backgroundImage: `url(${Image})` }} id="about_banner">
    <div class="container">
        <div class="row">
            <div class="text-center">

                <h2>Help Center</h2>
                <ol class="breadcrumb">
                    <li><a href="index.html">Home</a></li>
                    <li>Help Center</li>
                </ol>


            </div>
        </div>
    </div>

</section>
)

}