import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetPending } from '../../../../../Reducer/vendorSlice';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';
import Bookingdetails from '../Bookingdetails';

export default function Completed(props) {

    const dispatch = useDispatch();
    const login = useSelector(state => state.auth.auth);
    // const [complete, setCompleted] = useState([]);
    const [open, setOpen] = useState(false);
    const [bookingId, setBookingId] = useState("");

    const columns = [
        {
            name: 'User Name',
            selector: 'username',
            sortable: true,
        },
        {
            name: 'Service',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Address',
            selector: 'address',
            sortable: true,
        },
        {
            name: 'Number',
            selector: 'phone',
        },
        {
            name: 'Price',
            selector: 'finalPrice',
            cell: (row) => `$${row.finalPrice}`, 
        },
        {
            name: 'Booking Date',
            selector: 'booking_date',
        },
        {
            name: 'View',
            button: true,
            cell: (record) => {
                return (<> <i onClick={() => openChild(record.booking_id)} className='fa fa-eye color'> </i> </>
                )

            }
        },


    ];

    async function openChild(booking_id) {
        setBookingId(booking_id);
        setOpen(true);
    }

    useEffect(() => {

        // getCompletedBookings();
    }, [login.user_id]);

    const receiveResponseFromChild = (response) => {
        setOpen(response);
    };
    // async function getCompletedBookings() {
    //     let uid = { user_id: login.user_id, type: "completed" };

    //     let response = await dispatch(GetPending(uid));

    //     console.log('resssps', response);
    //     if (response.payload.status === 200) {
    //         setCompleted(response.payload.data.data);
    //     }
    // }






    return (
        <div class="tab-pane fade show active" id="pills-completed" role="tabpanel"
            aria-labelledby="pills-completed-tab">

            <div class="active_list_inner">

                {open === false &&

                    <div class="table-responsive">


                        {props && props.data && props.data.length > 0 ? (
                            <DataTableExtensions
                                columns={columns}
                                data={props.data}
                                print={false}
                                export={false}
                            >
                                <DataTable
                                    columns={columns}
                                    data={props.data}
                                    fixedHeader
                                    pagination
                                    highlightOnHover
                                />
                            </DataTableExtensions>
                        ) : (
                            <p>No data available</p>
                        )}


                    </div>

                }
                {open === true &&

                    <div>

                        <Bookingdetails id={bookingId} sendResponseToParent={receiveResponseFromChild}/>

                    </div>

                }


            </div>

        </div>

    )

}