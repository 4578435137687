import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useParams } from "react-router-dom";
import { SingleBlog } from "../../../../Reducer/PagesSlice";
import { useLocation } from 'react-router-dom';

export default function BlogSection() {

    const dispatch = useDispatch();
    const data = useSelector(state => state.pages.data);
    const params = useParams();
    let location = useLocation();

    useEffect(() => {

        getSingle();

        console.log('datataaa', data);

    }, [location.pathname]);

    async function getSingle() {
        let data = { id: params.id };
        dispatch(SingleBlog(data));

    }

    return (

        <>

            {data && data.singleBlog && data.singleBlog != "" &&

                <div class="details-img">
                    <img class="w-100" src={"https://admin.broomyy.com/public/storage/page_image/" + data.singleBlog.image} alt="Details" />
                    <ul>

                        <li>
                            <i class="bi bi-calendar2"></i>
                         {`${new Date(data.singleBlog.created_at).toISOString().slice(0, 10)}`}
                        
                        </li>
                        <li>
                            {/* <i class="bi bi-chat-text"></i> */}
                            {/* <a href="#">Comments (02)</a> */}
                        </li>
                    </ul>
                    <h2>{data.singleBlog.title}</h2>

                    <p dangerouslySetInnerHTML={{ __html: data.singleBlog.description }}></p>



                </div>

            }
        </>
    )


}