import React, { Component, useEffect, useState } from "react";
import Banner from "./Banner";
import PageContent from "./PageContent";

export default function Accessibility() {

    return (

        <>
         <Banner />
         <PageContent/>
        </>
    )



}
