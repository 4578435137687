import React from 'react';
import { UserNavbar } from '../../Data/content';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Profile from '../Profile';
import Inbox from '../Inbox';
// import Gallery from '../Gallery';
// import PaymentDetails from '../PaymentDetails';
// import Availability from '../Availability';
// import Services from '../Services';
import Bookings from '../Bookings';
import  Chat from '../../Chat';

export default function Navbar() {

    return (<>


        <section id="user_dashboard">
            <div class="container">

                <div class="responsive_tab">
                    <Tabs>

                        <TabList className="nav nav-pills responsive_scroll provider newClass">
                            {UserNavbar.length > 0 && UserNavbar.map((arr, i) => {
                                return (
                                    <>
                                        <Tab>{arr.name}</Tab>
                                    </>

                                )
                            })}
                            {/* <Tab>Title 1</Tab>
                            <Tab>Title 2</Tab> */}
                        </TabList>


                        <TabPanel>
                            <Profile />
                        </TabPanel>
                        <TabPanel>
                            <Chat />
                        </TabPanel>
                        <TabPanel>
                            <Bookings />
                        </TabPanel>

                    </Tabs>
                </div>
            </div>
        </section>
    </>
    );


}
