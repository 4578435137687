import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const sendOtpForm = createAsyncThunk('sendotp/sendOtpForm', async (formData) => {
  try {
    const response = await axios.post('https://admin.broomyy.com/api/send_otp', formData);
    return response.data;
  } catch (error) {
    throw new Error('Failed to submit form.');
  }
});

const sendotpSlice = createSlice({
  name: 'sendotp',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendOtpForm.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendOtpForm.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(sendOtpForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        console.log('erooo in slic', state);
        state.data = action.payload;
      });
  },
});

export default sendotpSlice.reducer;
