import React, { Component, useEffect, useState } from "react";
import Image from '../../Data/booking-bg.png';

export default function Banner() {

    return (

        <section style={{ backgroundImage:`url(${Image})`}} id="about_banner">
        <div class="container">
            <div class="row">
                <div class="text-center">

                    <h2>Comments</h2>
            

                </div>
            </div>
        </div>

    </section>
    )



}
