import React, { Component, useEffect, useState } from "react";
import { BookingData } from '../../../../../Reducer/vendorSlice';
import { useDispatch, useSelector } from 'react-redux';


export default function Bookingdetails(id) {

    const [bookingId, setBookingId] = useState(id);
    const details = useSelector(state => state.vendor.data.data);
    const dispatch = useDispatch();


    useEffect(() => {
        let data = { id: bookingId };
        dispatch(BookingData(data));
    }, [dispatch]);

    const sendResponse = () => {
        id.sendResponseToParent(false);
    };


    return (
        <>

            <section id="booking_form_details">
                <div class="container">

                    {details &&
                        <div class="row">

                            <h3><span class="me-3" onClick={sendResponse}><i class="fa fa-arrow-left" aria-hidden="true"></i> </span>Booking Details </h3>
                            <div class="booking_form_user">

                                {/* <button class="btn btn-dark" onClick={sendResponse}>Back</button> */}

                                <div class="booking_form_user_img">
                                    <img src={"https://admin.broomyy.com/public/storage/profile_images/" + details.image}></img>
                                </div>

                                <div class="booking_form_user_head">
                                    <h6>{details.userName}</h6>
                                    <p><i class="bi bi-geo-alt-fill"></i>{details.address}</p>
                                    <p><i class="bi bi-telephone-fill"></i>{details.phone}</p>
                                </div>
                            </div>

                            <div class="col-xl-3 col-lg-4 col-sm-6">
                                <h3>Service:<p>{details.name}</p>
                                </h3>

                            </div>

                            <div class="col-xl-3 col-lg-4 col-sm-6">
                                <h3>What Date?: <p>{details.booking_date}</p>
                                </h3>

                            </div>

                            <div class="col-xl-3 col-lg-4 col-sm-6">

                                <h3>Start Time: <p>{details.start_time}</p>
                                    {/* {`${new Date(data.singleBlog.created_at).toISOString().slice(0, 10)}`} */}
                                </h3>

                            </div>

                            <div class="col-xl-3 col-lg-4 col-sm-6">
                                <h3>End Time: <p>{details.end_time}</p>
                                </h3>
                            </div>

                            <div class="col-12">
                                <h2>Property Details</h2>
                            </div>

                            <div class="col-xl-3 col-lg-4 col-sm-6">
                                <h3>Postal Code: <p>{details.postal_code}</p>
                                </h3>

                            </div>

                            <div class="col-xl-3 col-lg-4 col-sm-6">

                                <h3>Service Type: <p>{details.service_type}</p>
                                </h3>

                            </div>

                            <div class="col-xl-3 col-lg-4 col-sm-6">

                                <h3>Size/Sqft: <p>{details.size}</p>
                                </h3>

                            </div>

                            <div class="col-xl-3 col-lg-4 col-sm-6">
                                <h3>Bedrooms: <p>{details.bedrooms_count}</p>
                                </h3>
                            </div>

                            <div class="col-xl-3 col-lg-4 col-sm-6">
                                <h3>Bathrooms: <p>{details.bathroom_count}</p>
                                </h3>
                            </div>

                            <div class="col-sm-6">
                                <h3>Do you have pet?: <p>{details.pet}</p>
                                </h3>
                            </div>

                            <div class="col-sm-12">
                                <h3>Do you need photos of the property after cleaning?:<p>{details.need_photos}</p>
                                </h3>
                            </div>

                            {details.add_on_ids && details.add_on_ids.length > 0 &&

                                < div class="col-sm-12">
                                    <h3>Additional Request:
                                        {details.add_on_ids.map((data, i) => (
                                            <li>{data.name}</li>
                                        ))}
                                    </h3>
                                </div>

                            }

                            {details.message && details.message != null &&

                                <div class="col-sm-12">
                                    <h3>Message:<p>{details.message}</p>
                                    </h3>
                                </div>

                            }




                        </div>
                    }
                </div>
            </section >
        </>
    )

}