import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";

export default function UserDashboard() {
    return (<>
    {/* add multiple component */}
        <Navbar/>
    
       
    </>)
}