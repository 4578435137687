
import React, { useContext, useState } from "react";
import MultiStepFormContext from "./MultiStepFormContext";
// import { Input, TextArea, InputNumber, DatePicker } from "formik-antd";
// import { Formik } from "formik";
// import { Button, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { savePdf } from "../../../Reducer/providerForms";

export const Final = (props) => {
    const { final, setFinal } = useContext(MultiStepFormContext);
    // const [fileData, setData] = useState(null);
    const [name, setname] = useState(null);
    const [err, setError] = useState("");
    const dispatch = useDispatch();
    const pdf = useSelector(state => state.forms.details.background_verification);

    // console.log('fileDatafileDatafileDatafileDatafileData', fileData);

    function handleChange(e) {
        console.log('e.target.filedddddddddddddddddddddddddds', e.target.files[0].name);
        setname(e.target.files[0].name);
        // setData(e.target.files[0]);
        setFinal({
            ...final,
            background_verification: e.target.files[0]
          });

    }

    // if(pdf && pdf.background_verification != null){
    //     console.log('pdff backk', pdf.background_verification);
    //     setname(pdf.background_verification.name);
    // }

    console.log('pdf', pdf);

    function handleSubmit() {
        if (name != null && final.background_verification != null) {
          setError("");
          dispatch(savePdf(final));
          props.onClick();
        } else {
          setError("This is required");
        }
      }
      
    function test() {
        // setTimeout(() => {
        // if (name != null && final.background_verification != null) {
        //     setError("");
        //     setFinal(final);
        //     dispatch(savePdf(final));
        //     props.onClick();
        // } else {
        //     setError("This is required");
        //         }
        //     }, 1000);
        }

        return (<div class="row">
            <form>
                <div class="form-bg">

                    <div class="gray_box_family mb-3">
                        <p>You’re almost there! Complete these final steps.</p>
                    </div>

                    <div class="authorize_box">
                        <h1> <img src="images/chat.png" alt="" /> Profile Review Fee ($100)
                            <p>Save a payment card for a one-time fee that goes
                                towards our quality assurance process and is non
                                refundable.</p> </h1>

                    </div>

                    <div class="authorize_box">
                        <h1> <img src="images/background-check.png" alt="" /> Authorize a background check
                            <p>A quick and essential step that all providers pass to
                                be part of Broomyy and build trust with clients. We
                                will charge your card after completing this step.</p> </h1>
                        <label class="form-label">Upload background check form(Canadian criminal record)</label>
                        <input type="file" name={'verification'} onChange={(e) => { handleChange(e) }} class="form-control" accept="application/pdf" required />
                        <p className={"error__feedback"}>{err}</p>


                    </div>

                    <div class="authorize_box">
                        <h1> <img src="images/send.png" alt="" /> Submit your profile
                            <p>With these two steps completed you’re ready to
                                submit your profile! We’ll review it within 5 business
                                days.</p> </h1>

                    </div>


                    <div class="row">

                        <div class="col-12">
                            <div class="text-center">
                                <button type="button" class="btn profilee" onClick={handleSubmit}> Submit</button>
                            </div>

                        </div>

                    </div>

                </div>
            </form>
        </div>
        );
};
