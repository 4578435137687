import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const submitForm = createAsyncThunk('form/submitForm', async (formData) => {
  try {
    const response = await axios.post('https://admin.broomyy.com/api/user_signup', formData);
    return response.data;
  } catch (error) {
    throw new Error('Failed to submit form.');
  }
});

const formSlice = createSlice({
  name: 'form',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitForm.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitForm.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(submitForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default formSlice.reducer;
