import React, { Component, useEffect, useState } from "react";
import Banner from './Banner';
import Choosing from './Choosing';
import Mission from './Mission';
import Overview from "./Overview";

export default function AboutUs() {

    return (

        <>
            <Banner />
            <Overview />
            <Choosing />
            <Mission />
        </>
    )



}
