import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ProfileDetail } from "../../../Reducer/searchSlice";
import { useFormState } from "react-hook-form";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './style.css';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import TimeAgo from 'timeago-react'; // var TimeAgo = require('timeago-react');
import ChatPopUp from "../ChatPopUp";
import { Rating } from 'react-simple-star-rating';
import Image from '../Data/blog-3.jpg';
import moment from 'moment-timezone';


export default function SingleProfile() {

    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    // const [providers, setProvider] = useState({});
    const [img_url, setUrl] = useState("https://admin.broomyy.com/public/storage/profile_images/")
    const [open, setOpen] = useState(false);
    const highlightedDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const today = new Date().toISOString().slice(0, 10);
    const [date, setDate] = useState(today);
    const [chat, setChatbot] = useState(false);
    const type = useSelector(state => state.auth.auth);
    // const location = useLocation();
    // const newData = location.state;
    const login = useSelector(state => state.auth.auth);
    const providers = useSelector(state => state.search.data);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;



    console.log('new login', login);


    useEffect(() => {
        getProfile();
        console.log('Current time zone:', timeZone);

    }, [])

    async function getProfile() {
        let data = { user_id: params.id };
        dispatch(ProfileDetail(data));
        // setProvider(response.payload.data ? response.payload.data : {});

        // if (response.payload.status === 'success') {
        //     // console.log('prfilee00000000000', response);
        //     setProvider(response.payload.data);
        // }


    }

    function openImages() {
        setOpen(true);
    }

    function closeDiv() {
        setOpen(false);
    }


    const tileClassName = ({ date }) => {
        // Adjust the date to the desired time zone (America/Edmonton)
        const adjustedDate = moment(date).tz(timeZone);
        const test = highlightedDays[adjustedDate.day()];
        const check = adjustedDate.format('YYYY-MM-DD');


        // Get the current date in the desired time zone
        // moment.tz.setDefault("America/Calgary"); Edmonton
        const currentDate = moment().tz(timeZone).format('YYYY-MM-DD');

        if (providers && providers.cleaning_days && providers.cleaning_days.length > 0) {
            const test2 = providers.cleaning_days.includes(test);

            if (test2 === true && check >= currentDate) {
                // console.log('test', test, check, currentDate, providers.cleaning_days);
                return 'highlighted-day';
            } else {
                return 'new-class';
            }
        }
    };



    function Book() {
        if (login && login.role && login.role === "user") {
            navigate(
                `/BookingForm/${params.id}`,
            );
        } else {

            console.log('else case');
            navigate({
                pathname: '/Login',
                search: `?id=${params.id}`,
            });
        }
    }

    function OpenChatBot() {
        setChatbot(true);
    }

    const receiveResponseFromChild = (response) => {
        setChatbot(response);
    };

    return (<>

        <section id="cleaning_service">
            <div class="container-fluid">
                <div class="row">

                    <div class="col-xl-6 mt-3">
                        <div className="service_part_divided">
                            {/* --- Box 1 --- */}
                            {providers && providers.images && providers.images.length === 1 &&
                                <div className="row">
                                    <div className="col-12">
                                        <div style={{ backgroundImage: `url("${img_url + providers.images[0]}")` }} className="box-one"></div>
                                    </div>
                                </div>
                            }

                            {/* --- Box 2 --- */}
                            {providers && providers.images && providers.images.length === 2 &&
                                <div className="row">
                                    <div className="col-6">
                                        <div style={{ backgroundImage: `url("${img_url + providers.images[0]}")` }} className="box-two"></div>
                                    </div>

                                    <div className="col-6">
                                        <div style={{ backgroundImage: `url("${img_url + providers.images[1]}")` }} className="box-two"></div>
                                    </div>
                                </div>
                            }

                            {/* --- Box 3 --- */}
                            {providers && providers.images && providers.images.length === 3 &&
                                <div className="row">
                                    <div className="col-7">
                                        <div style={{ backgroundImage: `url("${img_url + providers.images[0]}")` }} className="box-three"></div>
                                    </div>
                                    <div className="col-5">
                                        <div style={{ backgroundImage: `url("${img_url + providers.images[1]}")` }} className="box-three1"></div>

                                        <div style={{ backgroundImage: `url("${img_url + providers.images[2]}")` }} className="box-three1"></div>
                                    </div>
                                </div>
                            }

                            {/* --- Box 4 --- */}
                            {providers && providers.images && providers.images.length === 4 &&
                                <div className="row">
                                    <div className="col-6">
                                        <div style={{ backgroundImage: `url("${img_url + providers.images[0]}")` }} className="box-four"></div>
                                    </div>

                                    <div className="col-6">
                                        <div style={{ backgroundImage: `url("${img_url + providers.images[1]}")` }} className="box-four"></div>
                                    </div>

                                    <div className="col-6">
                                        <div style={{ backgroundImage: `url("${img_url + providers.images[2]}")` }} className="box-four"></div>
                                    </div>

                                    <div className="col-6">
                                        <div style={{ backgroundImage: `url("${img_url + providers.images[3]}")` }} className="box-four"></div>
                                    </div>
                                </div>
                            }

                            {providers && providers.images && providers.images.length === 5 &&
                                <div class="row">

                                    {providers.images && providers.images.length > 0 &&
                                        <div className={'col-xl-7 col-md-8'}>

                                            <div className={'cleaning-img'} style={{ backgroundImage: `url("${img_url + providers.images[0]}")` }}>

                                            </div>

                                        </div>


                                    }
                                    {providers.images && providers.images.length > 1 &&

                                        <div className={"col-xl-5 col-md-4"}>

                                            <div class="row">


                                                <div className={'col-6 mb-3 pe-1'} >

                                                    <div className={'cleaning-img-2'}>
                                                        <img src={img_url + providers.images[1]} />
                                                    </div>

                                                </div>



                                                {providers.images.length > 2 &&

                                                    <div class="col-6 mb-3">

                                                        <div class="cleaning-img-2">
                                                            <img src={img_url + providers.images[2]} />
                                                        </div>



                                                    </div>


                                                }

                                                {providers.images.length > 3 &&
                                                    <div class="col-6 pe-1">

                                                        <div class="cleaning-img-2">
                                                            <img src={img_url + providers.images[3]} />
                                                        </div>




                                                    </div>

                                                }

                                                {providers.images.length > 4 &&

                                                    <div class="col-6">

                                                        <div onClick={() => openImages()} class="cleaning-img-2">

                                                            <img src={img_url + providers.images[4]} />


                                                            <h4>{providers.imagesCount} pictures</h4>
                                                            <p> <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">view
                                                                all</a> </p>
                                                        </div>

                                                    </div>
                                                }

                                            </div>


                                        </div>

                                    }
                                </div>
                            }
                        </div>
                    </div>



                    {open == true &&


                        <div className="gelll">
                            <h4>Gallery</h4>
                            <div class="dv_close" onClick={closeDiv}>
                                <i class="bi bi-x-square"></i>
                            </div>
                            <div class="row">

                                {providers.images && providers.images.map((img, i) => (

                                    <div class="col-lg-3">
                                        <div className="pop_img">
                                            <img class="w-100" src={img_url + img} />
                                        </div>
                                    </div>
                                ))}


                            </div>

                        </div>
                    }

                    <div class="col-xl-6 mt-3">

                        {providers &&

                            <div class="about_profile">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="about_flex border-bottom">
                                            {providers && providers.image &&
                                                <div class="about_img" style={{ backgroundImage: `url("${img_url + providers.image}")` }}
                                                ></div>
                                            }

                                            <div class="about_heading">
                                                <h4>{providers && providers.username}<span>
                                                    {type && type.role === "user" &&
                                                        <a onClick={OpenChatBot} class="btn">Send Message</a>
                                                    }
                                                    <a onClick={Book}
                                                        class="btn">Book Now</a>
                                                </span></h4>
                                                <p>{providers && providers.headline}
                                                </p>
                                                <h5><span>
                                                    <Rating allowHover={false} readonly={true}
                                                        initialValue={providers.Star_count}
                                                    />
                                                </span>
                                                    {/* <span>$47/hrs</span> */}
                                                </h5>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-md-5 col-sm-4 pt-3">
                                        <div class="about_flex border-end">
                                            <ul>
                                                <li>
                                                    <b>{providers.bookingCount}</b> Jobs since {new Date(providers.since).toLocaleDateString('en-US', {
                                                        year: 'numeric',
                                                        month: 'short'
                                                    })}
                                                </li>
                                                <li><b>{providers.review_count}</b> Reviews</li>
                                                <li><b>{providers.repeat_clients}</b> Repeat Clients</li>
                                            </ul>
                                        </div>

                                    </div>

                                    <div class="col-xl-8 col-md-7 col-sm-8 pt-3">
                                        <div class="about_flex border-0">
                                            <h6>Cleaning Experience</h6>
                                            <label>{providers.experience}</label>

                                            <h6>Year of personal or professional experience</h6>
                                            <label>{providers.years}</label>

                                        </div>

                                    </div>

                                </div>



                            </div>
                        }

                    </div>

                </div>
            </div>
        </section >

        <section id="home_service">

            <div class="container-fluid">
                <div class="row">

                    {/* <div class="col-xxl-6 col-lg-12">
                        <div class="service-heading-per">
                            <h4>Services</h4>
                            <div class="row">
                          
                                {providers.services && providers.services.length > 0 && providers.services.map((a, i) => (
                                    <div class="col-sm-6">
                                        <h6> <img src="../images/Common-Areas.png" alt="" />{a.name}<p class="float-end">

                                            <span><div class="dropdown drop_btn_sh">
                                                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    View
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    {a.typesArry.map((typ, i) => (
                                                        <li>{typ.name} <span>${typ.price}</span> </li>
                                                    ))}

                                             
                                                </ul>
                                            </div></span>
                                        </p>
                                        </h6>


                                    </div>
                                ))}


                            </div>
                        </div>

                  
                    </div> */}

                    <div class="col-xxl-6 col-lg-12">
                        <div class="service-heading-per">
                            <h4>Services</h4>
                            <div class="row">
                                {providers && providers.services && providers.services.length > 0 && providers.services.map((a, i) => (

                                    <div class="col-sm-6">
                                        <h6> <img src="../images/Common-Areas.png" alt="" />{a.name}
                                            <div class="dropdown">
                                                <button class="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    View
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                                                    {a.typesArry.map((typ, i) => (
                                                        <li>{typ.name} <span>${typ.price}</span> </li>
                                                    ))}

                                                </ul>
                                            </div>
                                        </h6>
                                    </div>

                                ))}
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <div class="additional_pack">
                                    <h2>Additional Services</h2>
                                    <ul>
                                        {providers && providers.addOnSer && providers.addOnSer.length > 0 ? providers.addOnSer.map((typ, i) => (
                                            <li>{typ.name}  <span>${typ.add_price}</span></li>
                                        )) : <li>No Additional Services</li>}
                                    </ul>

                                    {/* <ul>
                                        <li>Carpet Cleaning</li>
                                        <li>Basement Cleaning</li>
                                        <li>Windows Cleaning</li>
                                        <li>Appliance Deep Cleaning</li>
                                        <li>Outdoor Furniture Cleaning</li>
                                        <li>Dust off - blinds, ceiling fan</li>
                                    </ul> */}

                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-xxl-3 col-lg-5 col-md-6 col-sm-6">

                        <div class="calendar_about">
                            <h4>Availability</h4>

                            <div class="wrapper">

                                <Calendar
                                    tileClassName={tileClassName}

                                />





                            </div>

                            <div class="checkbox-available">

                                <label class="disable-check">

                                    <div class="box-2"></div>
                                    Available
                                </label>


                                <label class="disable-check">

                                    <div class="box"></div>
                                    Unavailable
                                </label>
                            </div>

                        </div>

                    </div>


                    <div class="col-xxl-3 col-lg-7 col-md-6 col-sm-6">

                        <div class="review-sec">
                            <h4>Reviews</h4>

                            {providers && providers.reviews && providers.reviews.length > 0 ?

                                <>

                                    {providers.reviews.map((aa, i) => (

                                        <div>
                                            <h6> <img src={img_url + aa.image} alt="" />{aa.username}<p class="float-end">
                                                <i class="bi bi-star-fill"></i><span>{aa.star_count}</span>
                                            </p>
                                            </h6>

                                            <p>{aa.review}.</p>

                                            <h5>

                                                <TimeAgo
                                                    datetime={aa.updated_at}
                                                    locale='Asia/Kolkata'
                                                />
                                            </h5>

                                        </div>

                                    ))}
                                </> : <p>No reviews</p>}

                        </div>

                    </div>

                </div>
            </div>

        </section>
        {
            chat && chat == true &&
            <div>
                <ChatPopUp data={providers} sendResponseToParent={receiveResponseFromChild}></ChatPopUp>
            </div>
        }
    </>


    )

}