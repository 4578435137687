import React, { Component, useEffect, useState } from "react";
import Banner from './Banner';
import Content from './Content';


export default function HelpCenter() {

    return (

        <>
            <Banner />
            <Content />
        
        </>
    )



}
