import React, { Component, useEffect, useState } from "react";
import  Banner from './Banner';
import Blogs from "./Blogs";

export default function Blog() {

    return (
        <>
        <Banner />
        <Blogs />
        </>
    
    )
}