export const logo = "https://broomyy.com/images/Logo.png";

export const search = "https://broomyy.com/images/search.png"

export const clean= "https://broomyy.com/images/clean.png";

export const loc= "https://broomyy.com/images/loc.png";

export const notificom= "https://broomyy.com/images/notification.png";

