import React, { Component, useEffect, useState } from "react";
import Banner from "./Banner";
import Statement from "./Statement";

export default function Privacy() {

    return (
        <>
        <Banner />
        <Statement />
        </>
    )

}
