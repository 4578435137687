import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ContactForm } from "../../../../Reducer/PagesSlice";
import Swal from 'sweetalert2';
import { clearStatus } from "../../../../Reducer/authSlice";

export default function Form() {
    const dispatch = useDispatch();
    const error = useSelector(state => state.pages.error);
    const success = useSelector(state => state.pages.success);
    const [inputField, setInputField] = useState({
        name: '',
        email: '',
        Address: '',
        phone: '',
        message: ''
    })

    const inputsHandler = (e) => {
        setInputField({
            ...inputField,
            [e.target.name]: e.target.value
        });
    }

 

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });

                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong',
                    icon: 'error'
                });
                break;
        }
        dispatch(clearStatus());
    }

    if (success && success.status) {
        switch (success.status) {
            case 403:
                Swal.fire({
                    title: 'Success!',
                    text: success.data.message,
                    icon: 'success'
                });

                break;
            default:
                Swal.fire({
                    title: 'Success!',
                    text: 'Form Submitted succssfully',
                    icon: 'success'
                });
                break;
        }
        dispatch(clearStatus());
    }


    async function submitButton(e){
        e.preventDefault();
        console.log('daaaa', inputField);
        let formData = new FormData();

        formData.append('name', inputField.name);
        formData.append('email', inputField.email);
        formData.append('address', inputField.Address);
        formData.append('phone', inputField.phone);
        formData.append('message', inputField.message);

        let res = await dispatch(ContactForm(formData));

console.log('testt ', res);

        if(res.payload.status == "succsss"){
            Swal.fire({
                title: 'Success!',
                text: res.payload.message,
                icon: 'success'
            });
        }else{
            Swal.fire({
                title: 'Error!',
                text: res.payload.message,
                icon: 'error'
            });
        }


    }

    return (

        <section id="contact_form">
            <div class="container">

                <form>

                    <div class="row">

                        <h2>Contact Us</h2>

                        <div class="col-sm-6">

                            <div class="mb-3">
                                <input type="text" class="form-control" onChange={inputsHandler} name="name" placeholder="Full Name..." required />
                            </div>

                        </div>

                        <div class="col-sm-6">

                            <div class="mb-3">
                                <input type="email" class="form-control" onChange={inputsHandler} name="email" placeholder="Email..." required />
                            </div>

                        </div>

                        <div class="col-sm-6">

                            <div class="mb-3">
                                <input type="text" class="form-control" onChange={inputsHandler} name="Address" placeholder="Address..." required />
                            </div>

                        </div>

                        <div class="col-sm-6">

                            <div class="mb-3">
                                <input type="tel" class="form-control" onChange={inputsHandler} name="phone" placeholder="Phone..." required />
                            </div>

                        </div>

                        <div class="col-sm-12">

                            <div class="mb-3">
                                <textarea class="form-control" onChange={inputsHandler} name="message" rows="5"
                                    placeholder="Message"></textarea>
                            </div>

                        </div>

                        <div class="col-sm-12">

                            <div class="text-center">
                                <button type="submit" onClick={submitButton}>Send</button>
                            </div>

                        </div>


                    </div>

                </form>

            </div>
        </section>
    )



}
