import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { GetBankDetails } from '../../../../Reducer/vendorSlice';
import { useDispatch, useSelector } from 'react-redux';
import { UpdatePaymentDetails } from '../../../../Reducer/vendorSlice';




export default function PaymentDetails() {

    const dispatch = useDispatch();
    const login = useSelector(state => state.auth.auth);
    const [details, serDetails] = useState({});
    const [errors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
  

    useEffect(() => {
        async function getDetails() {
            let uid = { user_id: login.user_id };

            let response = await dispatch(GetBankDetails(uid));
            if (response.payload.status === 200) {
                serDetails(response.payload.data.data);
            }
        }

        getDetails();
    }, [login.user_id]);



    function handleChangeInput(evt) {
        const value = evt.target.value;
        serDetails({
            ...details,
            [evt.target.name]: value
        });
    }

    const validate = (values) => {
        const errors = {};

        // if (!values.card_holder_name) {
        //     errors.card_holder_name = "This field is required!";
        // }
        // if (!values.card_number) {
        //     errors.card_number = "This field is required!";
        // }

        // if (!values.cvc) {
        //     errors.cvc = "This field is required!";
        // }
        // if (!values.expiry_date) {
        //     errors.expiry_date = "This field is required!";
        // }
        if (!values.paypal_id) {
            errors.paypal_id = "This field is required!";
        }


        // if (!values.address_1) {
        //     errors.address_1 = "This field is required!";
        // }
        // if (!values.address_2) {
        //     errors.address_2 = "This field is required!";
        // }
        // if (!values.country) {
        //     errors.country = "This field is required!";
        // }

        // if (!values.state) {
        //     errors.state = "This field is required!";
        // }
        // if (!values.pincode) {
        //     errors.pincode = "This field is required!";
        // }

        return errors;
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setFormErrors(validate(details));
        setIsSubmit(true);

        if (Object.keys(errors).length == 0 && isSubmit === true) {

            let formData = new FormData();
            formData.append('user_id', login.user_id);
            // formData.append('card_holder_name', details.card_holder_name);
            // formData.append('card_number', details.card_number);
            // formData.append('cvc', details.cvc);
            // formData.append('expiry_date', details.expiry_date);
            formData.append('paypal_id', details.paypal_id);
            // formData.append('address_1', details.address_1);
            // formData.append('address_2', details.address_2);
            // formData.append('country', details.country);
            // formData.append('state', details.state);
            // formData.append('pincode', details.pincode);


            let update = await dispatch(UpdatePaymentDetails(formData));
            if (update.payload.status == 200) {
                Swal.fire({
                    title: 'Congratulation',
                    text: update.payload.data.message,
                    icon: 'success'
                });

            } else {
                Swal.fire({
                    title: 'Error!',
                    text: update.payload.data.message,
                    icon: 'error'
                });
            }


        }
    }




    return (


        <>

            <div class="tab-pane fade show active" id="pills-payment" role="tabpanel" aria-labelledby="pills-payment-tab">

                <div class="paypal_tab">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">


                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="pills-paypal-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-paypal" type="button" role="tab" aria-controls="pills-paypal"
                                aria-selected="false">Paypal</button>
                        </li>



                    </ul>

                    <div class="tab-content" id="pills-tabContent">



                        <div class="tab-pane fade show active" id="pills-paypal" role="tabpanel"
                            aria-labelledby="pills-paypal-tab">

                            <div class="strip_tab">
                                <form onSubmit={handleSubmit} >
                                    {/* <div class="form-group">
                                        <label for="exampleInputEmail1" class="form-label">Name on Card</label>
                                        <input type="text" class="form-control" placeholder="Card holder name" name={'card_holder_name'} value={details.card_holder_name} onChange={handleChangeInput} />
                                        <p className={"error__feedback"}>{errors.card_holder_name}</p>

                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1" class="form-label">Card Number</label>
                                        <input type="text" class="form-control" name={'card_number'} placeholder="Card Number" value={details.card_number} onChange={handleChangeInput} />

                                        <p className={"error__feedback"}>{errors.card_number}</p>

                                    </div> */}

                                    {/* <div class="form-group">
                                        <label for="exampleInputEmail1" class="form-label">Expiry Date</label>
                                        <input type="date" class="form-control" name={'expiry_date'} placeholder="paypal id" value={details.expiry_date} onChange={handleChangeInput} />

                                        <p className={"error__feedback"}>{errors.expiry_date}</p>

                                    </div> */}

                                    {/* <div class="form-group">
                                        <label for="exampleInputEmail1" class="form-label">CVC</label>
                                        <input type="text" class="form-control" name={'cvc'} placeholder="CVC" value={details.cvc} onChange={handleChangeInput} />

                                        <p className={"error__feedback"}>{errors.cvc}</p>
 
                                    </div> */}

                                    <div class="form-group">
                                        <label for="exampleInputEmail1" class="form-label">Paypal Id</label>
                                        <input type="text" class="form-control" name={'paypal_id'} placeholder="paypal id" defaultValue={details.paypal_id} onChange={handleChangeInput} />

                                        <p className={"error__feedback"}>{errors.paypal_id}</p>

                                    </div>
                                    {/* <div class="form-group">
                                        <label for="exampleInputEmail1" class="form-label">Country</label>
                                        <input type="text" class="form-control" name={'country'} placeholder="Country" value={details.country} onChange={handleChangeInput} />

                                        <p className={"error__feedback"}>{errors.country}</p>

                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1" class="form-label">Address Line 1</label>
                                        <input type="text" class="form-control" placeholder="Address Line 1" value={details.address_1} onChange={handleChangeInput} name={'address_1'} />

                                        <p className={"error__feedback"}>{errors.address_1}</p>

                                    </div> */}
                                    {/* <div class="form-group">
                                        <label for="exampleInputEmail1" class="form-label">Address Line 2</label>
                                        <input type="text" class="form-control" placeholder="Address Line 2" value={details.address_2} onChange={handleChangeInput} name={'address_2'} />

                                        <p className={"error__feedback"}>{errors.address_2}</p>

                                    </div>

                                    <div class="form-group">
                                        <label for="exampleInputEmail1" class="form-label">State</label>
                                        <input type="text" class="form-control" placeholder="State" onChange={handleChangeInput} value={details.state} name={'state'} />

                                        <p className={"error__feedback"}>{errors.state}</p>

                                    </div> */}

                                    {/* <div class="form-group">
                                        <label for="exampleInputEmail1" class="form-label">Pincode</label>
                                        <input type="text" class="form-control" placeholder="Pincode" onChange={handleChangeInput} value={details.pincode} name={'pincode'} />

                                        <p className={"error__feedback"}>{errors.pincode}</p>

                                    </div> */}




                                    {/* <div class="form-check">
                        <input class="form-check-input" type="checkbox" value=""
                            id="flexCheckDefault" checked/>
                        <label class="form-check-label" for="flexCheckDefault">
                            Set as primary
                        </label>
                    </div> */}
                                    {/* onSubmit={handleSubmit} */}
                                    <button type="submit">Update</button>

                                </form>
                            </div>


                        </div>



                    </div>

                </div>


            </div>
        </>
    );
}
