import React, { Component, useEffect, useState } from "react";
import Banner from './Banner';
import AllComnt from "./AllComnt";


export default function AllComments() {



  
    return (
        <>

        <Banner />
        <AllComnt />
        </>
    )

}