import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import { setToken, setUser } from './Reducer/authSlice';
import moment from 'moment-timezone';
import { GoogleOAuthProvider } from '@react-oauth/google';

import setDefaultTimezone from './timeZone';

const user = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : "";
const token = localStorage.getItem('token') ? localStorage.getItem('token') : "";

if (user && token) {
  store.dispatch(setUser(user));
  store.dispatch(setToken(token));
}
setDefaultTimezone('America/Edmonton');

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
  ,
  document.getElementById('root')
);


