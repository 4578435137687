import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetProfileData, UpdateProfileData, GetVendorGallery, UpdateGallery, GetAvailibilityData, GetServices, AddServices, DelUserService, getEditServiceData,editSevice, getBankDetailsData,updatePaymentDetails,updateAvailibility, cleanerPendingRequests, acceptRejectBooking, getBookingDetails, GetAddsONS, GetTRansa} from './api';
import { clearStatus } from './authSlice';



export const ProfileData = createAsyncThunk('Dashboad/Profile/ProfileData', async (data, { rejectWithValue }) => {

  try {
    const response = await GetProfileData(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


export const UpdateProfile = createAsyncThunk('Dashboard/Profile/UpdateProfile', async (formData, {rejectWithValue }) =>{
  try {
    const response = await UpdateProfileData(formData);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const GalleryData = createAsyncThunk('Dashboad/Gallery/GalleryData', async (data, { rejectWithValue }) => {

  try {
    const response = await GetVendorGallery(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const AddGallery = createAsyncThunk('Dashboard/Gallery/AddGallery', async (formData, {rejectWithValue }) =>{
  try {
    const response = await UpdateGallery(formData);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});



export const AvailibiltyData = createAsyncThunk('Availibility data', async (data, { rejectWithValue }) => {

  try {
    const response = await GetAvailibilityData(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const UpdateAvailibility = createAsyncThunk('Dashboad/Availability/AvailibiltyData', async (data, { rejectWithValue }) => {

  try {
    const response = await updateAvailibility(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


export const ServicesData = createAsyncThunk('Dashboad/Services/ServicesData', async (data, { rejectWithValue }) => {

  try {
    const response = await GetServices(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


export const GetAdds = createAsyncThunk('GetAdds', async (data, { rejectWithValue }) => {

  try {
    const response = await GetAddsONS(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


export const AddUserService = createAsyncThunk('AddUserService', async (data, { rejectWithValue }) => {

  try {
    const response = await AddServices(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const DeleteService = createAsyncThunk('DeleteService', async (data, { rejectWithValue }) => {

  try {
    const response = await DelUserService(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const GetEditData = createAsyncThunk('GetEditData', async (data, { rejectWithValue }) => {

  try {
    const response = await getEditServiceData(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const EditDataSubmit = createAsyncThunk('EditDataSubmit', async (data, { rejectWithValue }) => {

  try {
    const response = await editSevice(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const GetBankDetails = createAsyncThunk('GetBankDetails', async (data, { rejectWithValue }) => {

  try {
    const response = await getBankDetailsData(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const UpdatePaymentDetails = createAsyncThunk('bank detailssss', async (data, { rejectWithValue }) => {

  try {
    const response = await updatePaymentDetails(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const GetPending = createAsyncThunk('GetPending', async (data, { rejectWithValue }) => {

  try {
    const response = await cleanerPendingRequests(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const AcceptRejectBooking = createAsyncThunk('AcceptRejectBooking', async (data, { rejectWithValue }) => {

  try {
    const response = await acceptRejectBooking(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const BookingData = createAsyncThunk('Booking detailsss', async (data, { rejectWithValue }) => {

  try {
    const response = await getBookingDetails(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


export const getTransactions = createAsyncThunk('getTransactions', async (data, { rejectWithValue }) => {

  try {
    const response = await GetTRansa(data);
    return response;
    
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


const vendorSlice = createSlice({
  name: 'vendor',
  initialState: {
    data: null,
    loading: false,
    error: null,
    dataArry : null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ProfileData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ProfileData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(ProfileData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data.data;
      })
      .addCase(getTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(UpdateProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdateProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(UpdateProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(GalleryData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GalleryData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(GalleryData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(AddGallery.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddGallery.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(AddGallery.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(AvailibiltyData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AvailibiltyData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(AvailibiltyData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(ServicesData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ServicesData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(ServicesData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(AddUserService.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddUserService.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(AddUserService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(DeleteService.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(DeleteService.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(DeleteService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(GetEditData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetEditData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(GetEditData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(EditDataSubmit.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(EditDataSubmit.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(EditDataSubmit.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(GetBankDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetBankDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(GetBankDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(UpdatePaymentDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdatePaymentDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(UpdatePaymentDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(UpdateAvailibility.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdateAvailibility.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(UpdateAvailibility.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(GetPending.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetPending.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.dataArry = action.payload.data.dataArry;
      })
      .addCase(GetPending.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(AcceptRejectBooking.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AcceptRejectBooking.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(AcceptRejectBooking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // vendor
      .addCase(BookingData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(BookingData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(BookingData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

       
  },
});

export default vendorSlice.reducer;


