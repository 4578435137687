import React, { useContext,useState } from "react";
import MultiStepFormContext from "./MultiStepFormContext";
import { Input } from "formik-antd";
import { Formik } from "formik";
import { Button,Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery'; 
import { saveTesti } from "../../../Reducer/providerForms";
const { Option } = Select;
export const Testimonials = (props) =>{
    const { emails, setEmails } = useContext(MultiStepFormContext);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const tetstiiii = useSelector(state => state.forms.testi);

    return ( <Formik
        initialValues={tetstiiii}
        onSubmit={(values) => {

            let arr =[];

            arr.push(values.firstEmail);
            arr.push(values.secondEmail);
            arr.push(values.thiredEmail);


            console.log('eail values', arr);


            setEmails(arr);
            dispatch(saveTesti(values));
            console.log(values);
            props.onClick();
        //   next();
        }}
        validate={(values) => {
          const errors = {};
          if (!values.firstEmail) errors.firstEmail = "Email is required";
          if (!values.secondEmail) errors.secondEmail = "Email is required";
          if (!values.thiredEmail) errors.thiredEmail = "Email is required";
          return errors;
        }}
      >
        {({ handleSubmit, errors }) => {
          return (<div class="row">
               <form>
                    <div class="form-bg">
                        <div class="gray_box_family">
                            <p>Ask at least 1 family member, friend, or a former
                                client to write about your cleaning experience
                                and highlight why you’d be a great cleaner. The
                                more testimonials, the better!</p>
                        </div>
                        <h2>You’ve sent 0 requests so far</h2>
                        <div class="col-12">

                        <label for="Service" class="form-label">Add email addresses</label>

                        <div class="mb-3">
                            <Input name={"firstEmail"} className="service-input" type="email" placeholder="example@gmail.com" defaultValue={tetstiiii && tetstiiii.firstEmail} />
                        </div>
                        <p className={"error__feedback"}>{errors.firstEmail}</p>
                        <div class="mb-3">
                            <Input name={"secondEmail"} className="service-input" type="email" placeholder="example@gmail.com" defaultValue={tetstiiii && tetstiiii.secondEmail}/>
                        </div>
                        <p className={"error__feedback"}>{errors.secondEmail}</p>

                        <div class="mb-3">
                            <Input name={"thiredEmail"} className="service-input" type="email" placeholder="example@gmail.com" defaultValue={tetstiiii && tetstiiii.thiredEmail}/>
                        </div>
                        <p className={"error__feedback"}>{errors.thiredEmail}</p>
                        <div class="text-center">
                        {/* <p class="pop_gill">+ Enter more email addresses</p> */}
                        </div>
                        </div>
                            <div class="row">
                                <div class="col-12">
                                <Button type={"primary"} className="btn profilee" onClick={handleSubmit}>Save </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            );
            }}
        </Formik>
            );
          };