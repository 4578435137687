import axios from 'axios';

export const API_BASE_URL = 'https://admin.broomyy.com/api'; // Replace with your API base URL

export const vendorRegisterFormSubmit = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/vendor_signup`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const registerFormSubmit = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/user_signup`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const verifyOtp = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/verify_otp`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const socialLoginWeb = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/socialLoginWeb`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const verifyUserEmail = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/verifyUserEmail`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetProfileData = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/getProfileData`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const UpdateProfileData = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/updateProfileData`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetVendorGallery = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/getVendorGallery`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const UpdateGallery = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/addGallery`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GetAvailibilityData = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/getAvailability`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateAvailibility = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/updateAvailibility`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetServices = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/getAddOnServices`, data);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GetAddsONS = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/GetAdds`, data);
    return response;
  } catch (error) {
    throw error;
  }
}



export const AddServices = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/addUserService`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const DelUserService = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/delUserService`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const getEditServiceData = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/getEditServiceData`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const editSevice = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/editSevice`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const getBankDetailsData = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/getPaymentDetails`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updatePaymentDetails = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/updatePaymentDetails`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const GetAllVendors = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/getAllvendors`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const getSearched = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/getSearched`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const getProfileDetail = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/getProfileView`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const getUser = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/getUserPrfile`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const updateUserProfile = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/updateUserProfile`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getCleanerForBoooking = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/getCleanerForBoooking`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const sendBookingRequest = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/sendBookingRequest`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const cleanerPendingRequests = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/cleanerPendingRequests`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const acceptRejectBooking = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/acceptRejectBooking`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const getUserBookings = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/getUserBookings`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const cancelBooking = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/cancelBooking`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const addReview = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/addReviewandRating`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const checkRatingss = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/checkRatingss`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const getAllBlogs = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getAllBlogs`);
    return response;
  } catch (error) {
    throw error;
  }
}
export const getSingleBlog = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/getSingleBlog`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const getPgeData = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/getPgeData`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const getTestimonials = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getTestimonials`);
    return response;
  } catch (error) {
    throw error;
  }
}
export const getAdditionalServices = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getAdditionalServices`);
    return response;
  } catch (error) {
    throw error;
  }
}
export const getHomeData = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getHomeData`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const getBookingDetails = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/getBookingDetails`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const submitContactQuery = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/submitContactQuery`, data);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GetTRansa = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/GetTRansa`, data);
    return response;
  } catch (error) {
    throw error;
  }
}


export const submitComment = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/submitComment`, data);
    return response;
  } catch (error) {
    throw error;
  }
}


export const getComments = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/getComments`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const BlogSearch = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/BlogSearch`, data);
    return response;
  } catch (error) {
    throw error;
  }
}


export const getSingleBlogSerach = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/getSingleBlogSerach`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const sendCheckApi = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/sendCheckApi`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const getNotifications = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/getNotifications`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export const getNotificationsCount = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/getNotificationsCount`, data);
    return response;
  } catch (error) {
    throw error;
  }
}










